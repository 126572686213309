import { Card, CardContent, Grid, Typography } from '@mui/material';
import { LIST_USERS } from "../../../graphql/admin-requests";
import React, { useState } from 'react';
import { useQuery } from "@apollo/client";
import moment from 'moment';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';



const LastLogin = (props) => {


    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
    const [count7, setCount7] = useState(0);
    const [count30, setCount30] = useState(0);

    useQuery(LIST_USERS, {
        variables: {
            filter: { lastLogin: { greaterThanOrEqualTo: moment().subtract(1, 'days').format("YYYY-MM-DD") } }
        },
        onCompleted: (data) => { setCount1(data.allUsers.totalCount) },
        fetchPolicy: 'cache-and-network'
    });

    useQuery(LIST_USERS, {
        variables: {
            filter: { lastLogin: { greaterThanOrEqualTo: moment().subtract(2, 'days').format("YYYY-MM-DD") } }
        },
        onCompleted: (data) => { setCount2(data.allUsers.totalCount) },
        fetchPolicy: 'cache-and-network'
    });

    useQuery(LIST_USERS, {
        variables: {
            filter: { lastLogin: { greaterThanOrEqualTo: moment().subtract(7, 'days').format("YYYY-MM-DD") } }
        },
        onCompleted: (data) => { setCount7(data.allUsers.totalCount) },
        fetchPolicy: 'cache-and-network'
    });

    useQuery(LIST_USERS, {
        variables: {
            filter: { lastLogin: { greaterThanOrEqualTo: moment().subtract(30, 'days').format("YYYY-MM-DD") } }
        },
        onCompleted: (data) => { setCount30(data.allUsers.totalCount) },
        fetchPolicy: 'cache-and-network'
    });

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardContent>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12}><Typography color="textSecondary" gutterBottom variant="h6">Utilisateurs connectés</Typography></Grid>

                    <Grid item xs={4}><Typography color="textPrimary">Dernier jour</Typography></Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="div" color="text.secondary">{`${count1}`}</Typography>
                        <BorderLinearProgress variant="determinate" value={count1 / count30 * 100} />
                    </Grid>

                    <Grid item xs={4}><Typography color="textPrimary">2 derniers jour</Typography></Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="div" color="text.secondary">{`${count2}`}</Typography>
                        <BorderLinearProgress variant="determinate" value={count2 / count30 * 100} />
                    </Grid>

                    <Grid item xs={4}><Typography color="textPrimary">7 derniers jour</Typography></Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="div" color="text.secondary">{`${count7}`}</Typography>
                        <BorderLinearProgress variant="determinate" value={count7 / count30 * 100} />
                    </Grid>


                    <Grid item xs={4}><Typography color="textPrimary">30 derniers jour</Typography></Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="div" color="text.secondary">{`${count30}`}</Typography>
                        <BorderLinearProgress variant="determinate" value={count30 / count30 * 100} />
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    )
};

export default LastLogin;