import PropTypes from 'prop-types';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const UserByActivityListResults = ({ users, ...rest }) => {

    return (
        <Card {...rest}>
            {/* <PerfectScrollbar> */}
            <Box >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell size="small">Nom</TableCell>
                            <TableCell size="small">Prenom</TableCell>
                            <TableCell size="small">Classe</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow hover key={user.uid}>
                                <TableCell padding="checkbox"><Checkbox /></TableCell>
                                <TableCell size="small">{user.lastName}</TableCell>
                                <TableCell size="small">{user.firstName}</TableCell>
                                <TableCell size="small">{user.classroomByClassroomUid && user.classroomByClassroomUid.name}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {/* </PerfectScrollbar> */}
        </Card>
    );
};

UserByActivityListResults.propTypes = {
    users: PropTypes.array.isRequired
};

export default UserByActivityListResults;