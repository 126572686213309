import { Tooltip, ListItem, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import { Zoom } from '@mui/material';
import { Link } from "react-router-dom";

function CustomMenuItem({ title, onClick, icon, link, open =false }) {
    const item = (
        <ListItem button key={title} disablePadding sx={{ display: 'block' }} component={Link} to={link} onClick={onClick}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}>
                    {icon}
                </ListItemIcon >
                <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }}  />
            </ListItemButton>
        </ListItem >);

    return open ?
        item :
        <Tooltip
            arrow
            placement="right"
            TransitionComponent={Zoom}
            TransitionProps={{
                direction: "right",
                timeout: {
                    enter: 100,
                    exit: 0,
                }
            }}
            title={title} >
            {item}
        </Tooltip >
}


export default CustomMenuItem;