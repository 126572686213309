import React, { useEffect, useState } from 'react';
import { useMutation } from "@apollo/client";
import { Paper } from '@mui/material';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { GET_CALENDAR } from "../../graphql/admin-requests";
import { Scheduler, WeekView, MonthView, Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import { currentDate } from "../../utils/time";
import { Typography } from '@mui/material';
import { activityTypeColor } from '../../utils/colors';
import CustomAppointementContent from '../CommonComponents/CustomAppointmentContent';

function UserCalendar({ period, user }) {

    const [calendar, setCalendar] = useState([]);

    const [getUserCalendar] = useMutation(GET_CALENDAR, {
        onCompleted: (data) => {
            setCalendar(data.getAllAppointmentForUser.appointments);
        },
        onError: (error) => console.error("Error retrieving activities", error)
    });

    const CustomAppointment = ({
        children, style, data, ...restProps
    }) => (
        <Appointments.Appointment data={data} {...restProps} style={{ ...style, backgroundColor: activityTypeColor[data.type], }}>
            {children}
        </Appointments.Appointment >);

    useEffect(() => {
        async function getData() {
            if (user.uid && period.uid) {
                await getUserCalendar({ variables: { puid: period.uid, userUid: user.uid } });
            }
        };
        getData();
    }, [getUserCalendar, period, user]);

    const DayScaleCell = props => (<MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />);

    return (
        <Paper style={{ zoom: 0.8 }}>
            <Typography component="h1" variant="h6" color="inherit" noWrap>{`${user.firstName} ${user.lastName} (${period.name})`}</Typography>
            <Scheduler data={[...calendar]} locale="fr">
                <ViewState currentDate={currentDate} />
                <WeekView
                    excludedDays={[0, 6]}
                    cellDuration={45}
                    startDayHour={8}
                    endDayHour={19}
                    dayScaleCellComponent={DayScaleCell}
                />
                <Appointments
                    appointmentContentComponent={CustomAppointementContent}
                    appointmentComponent={CustomAppointment} />
            </Scheduler>
        </Paper>);
};

export default UserCalendar;