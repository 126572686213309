import { gql } from "@apollo/client";

const LIST_ACTIVITIES = gql`
query GetActivities ($first: Int = 10, $offset: Int = 0, $filter: ActivityFilter!) {
  allActivities (
    filter: $filter, 
    first: $first, 
    offset: $offset,
    orderBy: [NAME_ASC]) {
    nodes {
      uid
      name
      day
      startTime
      endTime
      capacity
      userByOrganizerUid {
        uid
        firstName
        lastName
      }
    }
    totalCount
  }
}`

const LIST_ACTIVITIES_USER = gql`
query ListActivitiesUser($first: Int, $offset: Int = 0, $filter: ActivityUserViewFilter!) {
  allActivityUserViews(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [DAY_ASC, START_TIME_ASC, NAME_ASC]) {
    nodes {
      uid
      name
      day
      startTime
      placeUid
      endTime
      capacity
      remaining
      taken
      placeByPlaceUid {
        name
      }
      activityClassroomsByActivityUid {
        nodes {
          classroomByClassroomUid {
            uid
            name
          }
        }
      }
      userByOrganizerUid {
        uid
        firstName
        lastName
      }
      periodByPeriodUid {
        uid
        name
      }
    }
    totalCount
  }
}`

const REGISTER_ACTIVITY = gql`
mutation RegisterActivity ($activityUid: UUID!) {
  registerActivity(
    input: {activityUid: $activityUid}
  ){
    userActivity{
      userUid
      activityUid
      userByUserUid{
        firstName
      }
      activityByActivityUid{
        name
      }
    }
  }
}
`;

const UNREGISTER_ACTIVITY = gql`
mutation UnregisterActivity ($activityUid: UUID!) {
    unregisterActivity(input:{uid: $activityUid}) {
      clientMutationId
    }
  }  
`;


const GET_CALENDAR = gql`
mutation GET_ALL_APPOINTMENT($pUid: UUID!) {
  getAllAppointment(input: {pUid: $pUid}) {
    appointments {
      day
      endTime
      startTime
      endDate
      startDate
      itemUid
      title
      type
      uidType
      appointmentUid
      place
      organizer
    }
  }
}`;

const GET_CURRENT_PERIOD = gql`
mutation GetCurrentPeriod {
  getCurrentPeriod(input: {}) {
    period {
      uid
      name
      startDate
      endDate
    }
  }
}`;

const GET_USER = gql`query GetUser($userUid: UUID!){
  userByUid(uid: $userUid){
    firstName
    lastName
    uid
    classroomByClassroomUid {
      name
    }
  }
}`;

const LIST_USER_PERIODS = gql`mutation MyMutation {
  getPeriodsAllInfoUser(input: {}) {
    periodInfos {
      uid
      name
      openUserStart
      startDate
      endDate
      openUser
    }
  }
}`;

const LIST_PERIODS = gql`query GetPeriods{
  allPeriods(
    orderBy: [START_DATE_ASC, END_DATE_ASC, NAME_ASC]) {
    nodes {
      uid
      name
      startDate,
      endDate,
      periodGradesByPeriodUid {
        nodes {
          gradeByGradeUid {
            name
            uid
          }
        }
      }
    }
  }
}`;

const CREATE_CALENDAR_PROCESS = gql`mutation CreateCalendarProcess($userUid: UUID!, $periodUid: UUID!, $comment: String) {
  createCalendarProcess(
    input: {calendarProcess: {userUid: $userUid, periodUid: $periodUid, calendarState: WAITTING_ADMIN, comment: $comment}}
  ) {
    calendarProcess {
      comment
      calendarState
    }
  }
}`;

const LIST_CALENDAR_PROCESS = gql`query ListCalendarProcess($periodUid: UUID!) {
  listCalendarProcess(pUid: $periodUid) {
    nodes {
      uid
      comment
      createdAt
      userByCreatedBy {
       firstName
       lastName
      }
      userByUserUid {
       lastName
       firstName
      }
      calendarState
      }
  }
}`


export {
  LIST_ACTIVITIES,
  LIST_ACTIVITIES_USER,
  LIST_PERIODS,
  LIST_USER_PERIODS,
  REGISTER_ACTIVITY,
  UNREGISTER_ACTIVITY,
  GET_CALENDAR,
  GET_CURRENT_PERIOD,
  GET_USER,
  CREATE_CALENDAR_PROCESS,
  LIST_CALENDAR_PROCESS
};