import { Container, Grid, Box } from '@mui/material';
import React from 'react';
import Users from "./dashboarditems/Users";
import LastLogin from "./dashboarditems/LastLogin";
import Activities from "./dashboarditems/Activities";
import ActivitiesByFilling from "./dashboarditems/ActivitiesByFilling";
import Groups from "./dashboarditems/Groups";
import Periods from "./dashboarditems/Periods";
import Filling from "./dashboarditems/Filling";
import Classrooms from "./dashboarditems/Classrooms";
import HeatMap from "./dashboarditems/HeatMap";
import UserActivities from "./dashboarditems/UserActivities";
import ProcessCalendarStats from './dashboarditems/ProcessCalendarStats';

function SuperAdminDashboard({ period }) {
    return (
        <Box>
            <Container component="main" maxWidth="xl">
                <Grid container spacing={1} >
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}><ProcessCalendarStats period={period}/></Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}><Filling period={period} role="horaroj_super_admin" /></Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}><LastLogin /> </Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}><HeatMap period={period} /> </Grid>
                    <Grid item xl={3} lg={2} md={6}  sm={12} xs={12}><Users /></Grid>
                    <Grid item xl={3} lg={2} md={6}  sm={12} xs={12}><Groups /> </Grid>
                    <Grid item xl={3} lg={2} md={6}  sm={12} xs={12}><Periods /></Grid>
                    <Grid item xl={3} lg={2} md={6}  sm={12} xs={12}><Classrooms /></Grid>
                    <Grid item xl={3} lg={2} md={6}  sm={12} xs={12}><Activities period={period} /></Grid>
                    {/* Commented because of an issue : "Cannot convert object to primitive value"
                    Might be better to use the same computing that Filling.jsx */}
                    {/* <Grid item xl={3} lg={2} md={6}  sm={12} xs={12}><UserActivities period={period} /></Grid> */}
                    <Grid item xl={3} lg={2} md={12} sm={12} xs={12}><ActivitiesByFilling period={period} /></Grid>
                </Grid>
            </Container>
        </Box >)

}

export default SuperAdminDashboard;