import { IconButton, Modal, Paper } from '@mui/material';
import { CancelIcon } from '../../Icons';


function UserGroupModal({ open, onFinish, component, ...restProps }) {
    const closeButton = { cursor: 'pointer', float: 'right' };

    return (
        <Modal
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            open={open}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <Paper>
                <IconButton style={closeButton} size="small" onClick={onFinish}><CancelIcon /></IconButton>
                {open ? (component({ onFinish, ...restProps })) : ""}
            </Paper>
        </Modal>)
}

export default UserGroupModal;