import React, { useEffect, useState, useRef } from 'react';
import { useLazyQuery, useMutation } from "@apollo/client";
import { useReactToPrint } from 'react-to-print';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { Toolbar, DateNavigator, ViewSwitcher } from '@devexpress/dx-react-scheduler-material-ui';
import { GET_CALENDAR, UNREGISTER_ACTIVITY, LIST_CALENDAR_PROCESS } from "../../graphql/user-requests";
import {
    Appointments,
    DayView,
    MonthView,
    Scheduler,
    WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';

import { currentDate, showProcess } from "../../utils/time";
import { Typography, IconButton, Box, Chip } from '@mui/material';
import { DeleteIcon, PrintIcon } from '../Icons';
import { userProcessCalendarMapping, canProcess } from '../../utils/process';

import { activityTypeColor } from '../../utils/colors';
import CustomAppointmentContent from '../CommonComponents/CustomAppointmentContent';
import CustomIconButton from '../AdminComponents/subcomponents/CustomIconButton';
import CUModal from '../AdminComponents/modals/CUModal';
import ProcessCalendar from './ProcessCalendar';
import { useSnackbar } from 'notistack';

import useMediaQuery from '@mui/material/useMediaQuery';


function Calendar({ period, user }) {

    const { enqueueSnackbar } = useSnackbar();

    const smallSreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [calendarDate, setCalendarDate] = useState(currentDate);
    const [viewName, setViewName] = useState(smallSreen ? "Day" : "Week");

    const updateCalendarDate = (e) => {
        if (e <= new Date("2018-01-05 00:00:00") && e >= new Date("2018-01-01 00:00:00")) setCalendarDate(e);
    };


    const [calendar, setCalendar] = useState([]);
    const [openValidateModal, setOpenValidateModal] = useState(false);
    const [lastProcess, setLastProcess] = useState({});
    const [processes, setProcesses] = useState([]);
    const [buttonOption, setButtonOption] = useState(userProcessCalendarMapping['DEFAULT']);


    const [unregisterActivity] = useMutation(UNREGISTER_ACTIVITY, {
        onCompleted: (data) => {
            console.log("unregisered", data);
        },
        onError: ({ graphQLErrors, networkError, message, extraInfo }) => {
            const msg = graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message;
            let reason = "";
            if (msg) {
                if (msg.includes('Cannot register processed calendar')) {
                    reason = "Vous avez déjà validé votre emploi du temps";
                }
                if (msg.includes('Period not openned')) {
                    reason = "Les inscriptions pour cette période ne sont pas ouvertes";
                }
            }

            enqueueSnackbar(`Désinscription impossible. ${reason}`, { variant: "warning" })
        },
    })

    const [getCalendar] = useMutation(GET_CALENDAR, {
        onCompleted: (data) => {
            setCalendar(data.getAllAppointment.appointments);
        },
        onError: (error) => console.error("Error retrieving activities", error)
    });

    const [getCalendarProcess] = useLazyQuery(LIST_CALENDAR_PROCESS, {
        onCompleted: (data) => {
            const res = data.listCalendarProcess.nodes;
            if (res && res[0]) {
                setProcesses(res);
                setLastProcess(res[0]);
                const state = res[0].calendarState;
                if (state) setButtonOption(userProcessCalendarMapping[state]);
            } else {
                // Reset values
                setProcesses([]);
                setLastProcess({});
                setButtonOption(userProcessCalendarMapping['DEFAULT']);
            }
        },
        onError: (error) => console.error("Error retrieving activities", error),
        fetchPolicy: 'cache-and-network'

    })

    const componentRef = useRef();


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media all {
            .page-break {
              display: none;
            }
          }

          @media print {
            html, body {
              height: initial !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
            }
          }

          @media print {
            .page-break {
              display: block;
              page-break-before: always;
            }
          }

          @page {
            size: auto;
            margin: 20mm;
          }
        `
    });

    const CustomAppointment = ({
        children, style, data, ...restProps
    }) => {
        return (
            <Appointments.Appointment data={data} {...restProps} style={{ ...style, backgroundColor: activityTypeColor[data.type], }}>
                {data.type === "activity" && period.openUser && canProcess(lastProcess) ?
                    <IconButton style={{ position: "flex", float: "right" }} onClick={handleDelete(data)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton> : ""}
                {children}
            </Appointments.Appointment >)
    };

    const handleDelete = appointmentData => {
        return async event => {
            await unregisterActivity({ variables: { activityUid: appointmentData.appointmentUid } });
            await getCalendar({ variables: { pUid: period.uid } });
        }
    }

    useEffect(() => {
        if (period.uid) {
            async function getData() {
                await getCalendar({ variables: { pUid: period.uid } });
                await getCalendarProcess({ variables: { periodUid: period.uid } });
            };
            getData();
        }
    }, [getCalendar, getCalendarProcess, period]);

    const formatUserClassroom = () => {
        const c = user.classroomByClassroomUid;
        return c ? `(${c.name})` : '';
    }

    const formatTitle = () => {
        return `${period.name} - ${user.firstName} ${user.lastName} ${formatUserClassroom()}`;
    }

    const handleClose = async () => {
        setOpenValidateModal(false);
        await getCalendarProcess({ variables: { periodUid: period.uid } });
    }

    const CustomOpenButton = (props) => {
        const newProps = {
            ...props,
            availableViews: [{ name: "Day", displayName: "Vue jour" }, { name: "Week", displayName: "Vue semaine" }],
            onChange: i => {
                setViewName(i);
                props.onChange(i);
            }
        }
        return <ViewSwitcher.Switcher  {...newProps} />;
    };

    const CustomHideComponent = (props) => null;

    const CustomNavigationButton = props => {
        if (viewName === 'Day') {
            return <DateNavigator.NavigationButton {...props} />
        }
    }

    const DayScaleCell = props => (
        <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
    );

    return (
        <>
            {openValidateModal && <CUModal open={openValidateModal} handleClose={handleClose} component={ProcessCalendar} canProcess={buttonOption.clickable} period={period} user={user} mapping={userProcessCalendarMapping} processes={processes} />}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                {period && showProcess(period) && <Chip
                    icon={buttonOption.icon}
                    clickable={true}
                    style={{ backgroundColor: buttonOption.backgroundColor, color: buttonOption.color, marginRight: '10px' }}
                    onClick={() => setOpenValidateModal(true)}
                    label={buttonOption.title} />}
                <CustomIconButton title="Imprimer" onClick={handlePrint} icon={<PrintIcon />}>Imprimer</CustomIconButton>
            </Box>
            <div ref={componentRef} style={{ height: "calc(100% - 64px)" }}>
                <Typography component="h1" variant="h6" color="inherit" noWrap>{formatTitle()}</Typography>
                <Scheduler data={[...calendar]} locale="fr-FR">
                    <ViewState
                        currentDate={calendarDate}
                        defaultCurrentViewName={viewName}
                        onCurrentDateChange={updateCalendarDate}
                    />
                    <Toolbar />
                    <ViewSwitcher switcherComponent={CustomOpenButton} />
                    <DateNavigator navigationButtonComponent={CustomNavigationButton} openButtonComponent={CustomHideComponent} />
                    <DayView
                        cellDuration={45}
                        startDayHour={8}
                        endDayHour={19}
                        intervalCount={1}
                        dayScaleCellComponent={DayScaleCell}
                    />
                    <WeekView
                        excludedDays={[0, 6]}
                        cellDuration={45}
                        startDayHour={8}
                        endDayHour={19}
                        dayScaleCellComponent={DayScaleCell}/>
                    <Appointments
                        appointmentContentComponent={CustomAppointmentContent}
                        appointmentComponent={CustomAppointment} />
                </Scheduler>
            </div>
        </>);
};

export default Calendar;