import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { ActivityIcon } from '../../Icons';
import { COUNT_USER_ACTIVITES } from "../../../graphql/admin-requests";
import React, { useState } from 'react';
import { useQuery } from "@apollo/client";
import { useTheme } from '@mui/material/styles';

const UserActivities = ({ period, ...props }) => {
    const [count, setCount] = useState(0);

    useQuery(COUNT_USER_ACTIVITES, {
        variables: { period_uid: period.uid },
        onCompleted: (data) => {
            const res = data.allActivities.nodes.reduce((acc, curr) => {
                return acc + curr.userActivitiesByActivityUid.totalCount
            }, 0);
            setCount(res);
        },
        fetchPolicy: 'cache-and-network'
    });

    const theme = useTheme();

    return (
        <Card sx={{ height: '100%' }} {...props} >
            <CardContent>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item>
                        <Typography color="textSecondary" style={{ marginBottom: "-5px" }} variant="h6">INSCRIPTIONS</Typography>
                        <Typography color="textSecondary" style={{ marginBottom: "-10px" }} variant="subtitle2">(nombre d'élèves)</Typography>
                        <Typography color="textPrimary" variant="h3">{count}</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar sx={{ backgroundColor: theme.palette.primary.main, height: 56, width: 56 }}>
                            <ActivityIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default UserActivities;