const computeItemUpdate = (initialUids, entityState) => {
    const toSet = entityState.filter(i => i.checked).map(i => i.uid);
    const toDelete = initialUids.filter(g => !toSet.includes(g));
    const toAdd = toSet.filter(g => !initialUids.includes(g));

    return { toDelete, toAdd }
};

const fillingReducer = (activities) => {

    return activities.reduce((acc, curr) => {
        return {
            capacity: acc.capacity + parseInt(curr.capacity),
            taken: acc.taken + parseInt(curr.taken)
        }

    }, { capacity: 0, taken: 0 })

}

const groupBy = (item, criteria) => item.reduce((acc, value) => {
    if (!acc[value[criteria]]) {
        acc[value[criteria]] = [];
    }
    acc[value[criteria]].push(value);
    return acc;
}, {});

export { computeItemUpdate, groupBy, fillingReducer }