import { Box, Container } from "@mui/material";
import {
  LIST_USERS,
  LIST_ADMINS,
  LIST_SUPER_ADMINS,
  DELETE_USER,
} from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import CUModal from "../modals/CUModal";
import CUUser from "../CreateUpdateComponents/User";
import CUAdmin from "../CreateUpdateComponents/Admin";
import CUSuperAdmin from "../CreateUpdateComponents/SuperAdmin";
import React, { useState, useEffect, useCallback } from "react";
import UserGroupModal from "../modals/UserGroupModal";
import UserGroups from "../UserGroups";
import AdminClassrooms from "../AdminClassrooms";
import UserListResults from "./UserListResults";
import AdminListResults from "./AdminListResults";
import SuperAdminListResults from "./SuperAdminListResults";
import UserListToolbar from "./UserListToolbar";
import AdminListToolbar from "./AdminListToolbar";
import SuperAdminListToolbar from "./SuperAdminListToolbar";
import UserDialog from "./UserDialog";

function UserList({ role = "users", period }) {
  const [users, setUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState("");

  const [openCUUserModal, setOpenCUUserModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openUserGroupsModal, setOpenUserGroupsModal] = useState(false);
  const [openAdminClassroomsModal, setOpenAdminClassroomsModal] =
    useState(false);
  const [userToUpdate, setUserToUpdate] = useState({});
  const [userToDelete, setUserToDelete] = useState({});

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: (data) => console.log("Data from delete", data),
    onError: (error) => console.error("Error deleting an activity", error),
  });

  const handleOpenCUUserModal = () => {
    setOpenCUUserModal(true);
  };

  const handleDeleteUser = (user) => async () => {
    setUserToDelete(user);
    setOpenDeleteUserModal(true);
  };

  const handleDeleteUserConfirm = async () => {
    await deleteUser({ variables: { userUid: userToDelete.uid } });
    setOpenDeleteUserModal(false);
    loadData();
  };

  const handleUpdateUserGroups = (user) => () => {
    setUserToUpdate(user);
    setOpenUserGroupsModal(true);
  };

  const handleUpdateAdminClassrooms = (admin) => () => {
    setUserToUpdate(admin);
    setOpenAdminClassroomsModal(true);
  };

  const handleOpen = () => {
    setOpenCUUserModal(true);
  };

  const handleClose = () => {
    setOpenCUUserModal(false);
    setOpenUserGroupsModal(false);
    setOpenAdminClassroomsModal(false);
    loadData();
  };

  const queryToUse = {
    users: LIST_USERS,
    admins: LIST_ADMINS,
    superadmins: LIST_SUPER_ADMINS,
  }[role];

  const [getUsers] = useLazyQuery(queryToUse, {
    onCompleted: (data) => {
      setUsers(data.allUsers.nodes);
      setTotalCount(data.allUsers.totalCount);
    },
    fetchPolicy: "cache-and-network",
  });

  const updateSearch = (event) => {
    setSearch(event.target.value);
  };

  const loadData = useCallback(() => {
    const searchF = {
      or: [
        { lastName: { includesInsensitive: search } },
        { firstName: { includesInsensitive: search } },
      ],
    };

    const filters = { ...searchF };
    const filter = {
      variables: {
        filter: filters,
        first: limit,
        offset,
      },
    };
    getUsers(filter);
  }, [getUsers, limit, offset, search]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Container component="main" maxWidth={false}>
      <UserDialog
        user={userToDelete}
        onConfirm={handleDeleteUserConfirm}
        onCancel={() => {
          setOpenDeleteUserModal(false);
        }}
        open={openDeleteUserModal}
      />
      {role === "users" && (
        <CUModal
          role={role}
          open={openCUUserModal}
          handleOpen={handleOpen}
          handleClose={handleClose}
          component={CUUser}
        />
      )}
      {role == "admins" && (
        <CUModal
          role={role}
          open={openCUUserModal}
          handleOpen={handleOpen}
          handleClose={handleClose}
          component={CUAdmin}
        />
      )}
      {role == "superadmins" && (
        <CUModal
          role={role}
          open={openCUUserModal}
          handleOpen={handleOpen}
          handleClose={handleClose}
          component={CUSuperAdmin}
        />
      )}
      <UserGroupModal
        open={openUserGroupsModal}
        handleOpen={handleOpen}
        onFinish={handleClose}
        user={userToUpdate}
        component={UserGroups}
      />
      <UserGroupModal
        open={openAdminClassroomsModal}
        handleOpen={handleOpen}
        onFinish={handleClose}
        user={userToUpdate}
        component={AdminClassrooms}
      />
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          {role === "users" && (
            <UserListToolbar
              handleOpenCUUserModal={handleOpenCUUserModal}
              updateSearch={updateSearch}
            />
          )}

          {role === "admins" && (
            <AdminListToolbar
              handleOpenCUUserModal={handleOpenCUUserModal}
              updateSearch={updateSearch}
            />
          )}

          {role === "superadmins" && (
            <SuperAdminListToolbar
              handleOpenCUUserModal={handleOpenCUUserModal}
              updateSearch={updateSearch}
            />
          )}

          <Box sx={{ pt: 3 }}>
            {role === "users" && (
              <UserListResults
                period={period}
                handleUpdateUserGroups={handleUpdateUserGroups}
                handleDeleteUser={handleDeleteUser}
                users={users}
                loadData={loadData}
                totalCount={totalCount}
                setLimit={setLimit}
                limit={limit}
                setOffset={setOffset}
                offset={offset}
              />
            )}
            {role === "admins" && (
              <AdminListResults
                period={period}
                handleUpdateAdminClassrooms={handleUpdateAdminClassrooms}
                handleDeleteUser={handleDeleteUser}
                users={users}
                loadData={loadData}
                totalCount={totalCount}
                setLimit={setLimit}
                limit={limit}
                setOffset={setOffset}
                offset={offset}
              />
            )}
            {role === "superadmins" && (
              <SuperAdminListResults
                period={period}
                handleDeleteUser={handleDeleteUser}
                users={users}
                loadData={loadData}
                totalCount={totalCount}
                setLimit={setLimit}
                limit={limit}
                setOffset={setOffset}
                offset={offset}
              />
            )}
          </Box>
        </Container>
      </Box>
    </Container>
  );
}

export default UserList;
