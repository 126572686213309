import { Box, Button, TextField, InputAdornment, SvgIcon, Grid } from '@mui/material';
import Select from "react-select";
import { SearchIcon } from "../../Icons";
import CUModal from '../modals/CUModal';
import { useState } from 'react';
import CUClassroom from "../CreateUpdateComponents/Classroom";


const ClassroomListToolbar = ({ updateGradeFilter, gradesOptions, updateSearch, loadData, readOnly, ...props }) => {

    const [openCUModal, setOpenCUModal] = useState(false);
    const handleClose = () => {
        setOpenCUModal(false);
        loadData();
    }

    return (
        <>
            <CUModal open={openCUModal} handleClose={handleClose} component={CUClassroom} />
            <Box {...props}>
                {!readOnly && <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color="primary" variant="contained" onClick={() => setOpenCUModal(true)}>Ajouter</Button>
                </Box>}
                <Box pb={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Rechercher une classe"
                                variant="outlined"
                                onChange={updateSearch}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <Select
                                minMenuHeight={1000}
                                placeholder="Niveaux..."
                                isClearable={true}
                                isSearchable={true}
                                name="day"
                                isMulti
                                onChange={updateGradeFilter}
                                options={gradesOptions}
                                defaultValue={[]}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Box >
        </>
    )
};

export default ClassroomListToolbar;