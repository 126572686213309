import React, { useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Checkbox, Container, Divider, Grid, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { CREATE_ADMIN_CLASSROOM, LIST_CLASSROOMS, DELETE_ADMIN_CLASSROOM } from "../../graphql/admin-requests";
import { computeItemUpdate } from "../../utils/reducers"
import { useSnackbar } from 'notistack';



function AdminClassrooms({ onFinish, user }) {

    const { enqueueSnackbar } = useSnackbar();


    let [classroomsState, setClassroomsState] = useState([]);
    let [adminClassroomsUids, setAdminClassroomsUids] = useState([]);


    const [createAdminClassroom, { creatingGroup, errorAdd }] = useMutation(CREATE_ADMIN_CLASSROOM, {
        onCompleted: (data) => { return Promise.resolve("1 classe ajoutée"); },
        onError: (error) => { return Promise.reject("Impossible d'ajouter la classe"); },
    });

    const [deleteUserGroup, { deletingGroup, errorDelete }] = useMutation(DELETE_ADMIN_CLASSROOM, {
        onCompleted: (data) => { return Promise.resolve("1 classe supprimée"); },
        onError: (error) => { return Promise.reject("Impossible de supprimer la classe"); },
    });

    async function handleValidateClassrooms(event) {
        event.preventDefault();
        const { toDelete: classroomsToDelete, toAdd: classroomsToAdd } = computeItemUpdate(adminClassroomsUids, classroomsState);
        const userUid = user.uid;

        await Promise.all(classroomsToDelete.map(async classroomUid =>
            await deleteUserGroup({ variables: { userUid, classroomUid } })));

        await Promise.all(classroomsToAdd.map(async classroomUid =>
            await createAdminClassroom({ variables: { userUid, classroomUid } })));
        enqueueSnackbar("Classes mis à jours", { variant: "success" });
        onFinish();
    }

    const handleCheckBoxes = (event) => {
        const newClassroomState = [...classroomsState];
        newClassroomState.find(g => g.uid === event.target.value).checked = event.target.checked;
        setClassroomsState(newClassroomState);
    }


    // Retrieve all classrooms
    useQuery(LIST_CLASSROOMS, {
        onCompleted: (data) => {
            const initialClassrooms = user.adminClassroomsByUserUid.nodes.map((ug) => ug.classroomByClassroomUid.uid);
            setAdminClassroomsUids(initialClassrooms);
            const classrooms = data.allClassrooms.nodes;
            setClassroomsState(classrooms.map(i => { return { ...i, checked: initialClassrooms.includes(i.uid) } }));
        },
        onError: (error) => console.error("Error requesting list of classrooms", error),
        fetchPolicy: 'cache-and-network'
    })

    return (
        <Container component="main" >
            <Typography variant="h6">Visibilité classes</Typography>
            <Divider />
            <Box component="form" noValidate>
                <Grid container>
                    <FormGroup row>{
                        classroomsState.map(g => {
                            return (
                                <Grid item xs={2} key={g.uid}>
                                    <FormControlLabel
                                        control={<Checkbox checked={g.checked} onChange={handleCheckBoxes} name={g.name} value={g.uid} color="primary" />}
                                        label={g.name}
                                        key={g.uid} />
                                </Grid>);
                        })
                    }</FormGroup>
                </Grid>
            </Box>
            <Button style={{ float: "right", margin: '10px' }} variant="outlined" color="primary" disabled={creatingGroup || deletingGroup} onClick={handleValidateClassrooms}>Valider les classes</Button>
            {errorAdd && <p>{errorAdd.message}</p>}
            {errorDelete && <p>{errorDelete.message}</p>}
        </Container>
    );

}
export default AdminClassrooms;