import React, { useState } from 'react';
import { useMutation } from "@apollo/client";
import { Chip, Container, Divider, Grid, Typography } from '@mui/material';
import { GET_USER_LOGIN } from "../../../graphql/admin-requests";
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { LoginIcon, ClipboardIcon } from '../../Icons';
import CustomIconButton from '../subcomponents/CustomIconButton';



function PasswordUpdate({ toSee = {} }) {
    const { enqueueSnackbar } = useSnackbar();

    let [userLogin, setUserLogin] = useState("");

    const handleError = async (e) => { console.error(e); enqueueSnackbar("Impossible de recupérer le login", { variant: "error" }) }

    const [getLogin, { error }] = useMutation(GET_USER_LOGIN, {
        onCompleted: (data) => {
            setUserLogin(data.getLogin.string);
        },
        onError: (error) => handleError(error)
    });
    useEffect(() => {
        getLogin({ variables: { userUid: toSee.uid } })
    }, [toSee.uid, getLogin])

    const copyToClipBoard = async (e) => {
        try {
            await navigator.clipboard.writeText(userLogin);
            enqueueSnackbar("Login utilisateur copié dans le presse papier", { variant: "success" });
        } catch (ex) {
            enqueueSnackbar("Impossible de copier dans le presse papier", { variant: "error" });
        }
    }

    return (
        <Container component="main" style={{ margin: '10px' }}>
            <Typography style={{ margin: '10px' }} component="h1" variant="h5">Login utilisateur</Typography>
            <Divider style={{ marginBottom: '20px' }} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Chip icon={<LoginIcon />} label={userLogin} />
                    <CustomIconButton
                        size="small"
                        title="Copier dans le presse papier"
                        onClick={copyToClipBoard}
                        icon={<ClipboardIcon />} />
                </Grid>
            </Grid>
            {error && <p>{error.message}</p>}
        </Container >
    );

}
export default PasswordUpdate;