import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Box, Button, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
    CREATE_CLASSROOM_ACTIVITY,
    LIST_PERIODS,
    LIST_ADMINS,
    LIST_PLACES,
    LIST_GRADES_AND_CLASSROOMS_BY_PERIOD,
    UPDATE_CLASSROOM_ACTIVITY
} from "../../../graphql/admin-requests";
import moment from "moment";
import { castPeriodDate } from "../../../utils/time";
import GradesClassroomsSelect from '../GradesClassroomsSelect';
import { useSnackbar } from 'notistack';
import { computeItemUpdate } from "../../../utils/reducers";


function ClassroomActivity({ onFinish, period, toUpdate = {} }) {
    const { enqueueSnackbar } = useSnackbar();


    let [name, setName] = useState("");
    let [startTime, setStartTime] = useState(moment().hour(14).minute(30));
    let [endTime, setEndTime] = useState(moment().hour(16).minute(30));
    let [day, setDay] = useState("MONDAY");
    let [classroomUid, setClassroomUid] = useState('');
    let [classrooms, setClassrooms] = useState([]);
    let [periodUid, setPeriodUid] = useState('');
    let [periods, setPeriods] = useState([]);
    let [places, setPlaces] = useState([]);
    let [placeUid, setPlaceUid] = useState('');
    let [users, setUsers] = useState([]);
    let [organizerUid, setOrganizerUid] = useState('');
    let [classroomOptions, setClassroomOptions] = useState([]);
    let [classroomsState, setClassroomsState] = useState([]);

    let [activityTitle, setActivityTitle] = useState("Nouveau cours");
    let [actionTitle, setActionTitle] = useState("Ajouter");

    let [validationErrors, setValidationErrors] = useState({});

    const handleError = async (e) => {
        console.error(e);
        enqueueSnackbar("Une erreur s'est produite !");
    }

    function validate() {
        const errs = {};

        if (!name || name === '') errs.name = "Nom du cours obligatoire";

        if (toUpdate.uid) {
            // We only update for 1 given classroom (that must be set)
            if (classroomUid === '') errs.groups = "Au moins 1 classe doit être sélectionnée";
        } else {
            // We need to have at leat one group or classroom selected
            const nbClassroomsChecked = classroomsState.filter(c => c.checked).length;
            if (nbClassroomsChecked === 0) errs.classrooms = "Au moins 1 classe doit être sélectionnée";
        }

        setValidationErrors(errs);
        return Object.keys(errs).length === 0;
    }

    const [getClassrooms] = useLazyQuery(LIST_GRADES_AND_CLASSROOMS_BY_PERIOD, {
        onCompleted: (data) => {
            if (toUpdate.uid) {
                let allClassrooms = [];
                data.allPeriodGrades.nodes.forEach(i => {
                    const grade = i.gradeByGradeUid;
                    allClassrooms = allClassrooms.concat(grade.classroomsByGradeUid.nodes)
                });
                setClassrooms(allClassrooms);

            } else {
                const options = data.allPeriodGrades.nodes.map(i => {
                    const grade = i.gradeByGradeUid;
                    return {
                        uid: grade.uid,
                        name: grade.name,
                        choices: grade.classroomsByGradeUid.nodes.map(classroom => {
                            return {
                                uid: classroom.uid,
                                name: classroom.name,
                                gradeUid: grade.uid,
                                checked: false
                            }
                        })
                    }
                });

                const cState = data.allPeriodGrades.nodes
                    .map(({ gradeByGradeUid: { classroomsByGradeUid: { nodes }, uid } }) => {
                        return nodes.map(n => { return { ...n, checked: false, gradeUid: uid } })
                    })
                    .flat();

                setClassroomOptions(options);
                setClassroomsState(cState);
            }
        },
        fetchPolicy: 'cache-and-network'
    });

    const handlePeriodSelect = useCallback((event) => {
        const pUid = event.target.value;
        getClassrooms({ variables: { periodUid: pUid } });
        setPeriodUid(pUid);
    }, [getClassrooms])

    const handlePlaceSelect = event => {
        setPlaceUid(event.target.value);
    }

    const handleOrganizerSelect = event => {
        setOrganizerUid(event.target.value);
    }

    const [updateActivity] = useMutation(UPDATE_CLASSROOM_ACTIVITY, {
        onCompleted: async (data) => { onFinish(); },
        onError: handleError
    });

    const [createActivity, { loadingActivity }] = useMutation(CREATE_CLASSROOM_ACTIVITY, {
        onCompleted: async (data) => { onFinish(); },
        onError: handleError
    });

    // Retrieve all periods
    useQuery(LIST_PERIODS, {
        onCompleted: (data) => {
            setPeriods(data.allPeriods.nodes);
            if (period && period.uid) {
                handlePeriodSelect({ target: { value: period.uid } });
            }
        },
        onError: (error) => console.error("Error requesting list of periods", error),
        fetchPolicy: 'cache-and-network'
    })

    // Retrieve all places
    useQuery(LIST_PLACES, {
        onCompleted: (data) => setPlaces(data.allPlaces.nodes),
        onError: (error) => console.error("Error requesting list of places", error),
        fetchPolicy: 'cache-and-network'
    })

    useQuery(LIST_ADMINS, {
        onCompleted: (data) => setUsers(data.allUsers.nodes),
        onError: (error) => console.error("Error requesting list of users", error),
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (toUpdate.uid) {
            const periodUid = toUpdate.periodByPeriodUid.uid;
            const organizerUid = toUpdate.userByOrganizerUid ? toUpdate.userByOrganizerUid.uid : '';
            handlePeriodSelect({ target: { value: periodUid } });
            setOrganizerUid(organizerUid);
            setPlaceUid(toUpdate.placeUid);
            setClassroomUid(toUpdate.classroomByClassroomUid.uid);
            setDay(toUpdate.day);
            setStartTime(moment(toUpdate.startTime, 'HH:mm:00'));
            setEndTime(moment(toUpdate.endTime, 'HH:mm:00'));
            setActivityTitle(toUpdate.name);
            setName(toUpdate.name);
            setActionTitle("Modifier");
        }
    }, [toUpdate, handlePeriodSelect]);


    async function handleClassroomActivity(event) {
        event.preventDefault();
        let variables = {
            name,
            day,
            organizerUid: (organizerUid !== '') ? organizerUid : null,
            periodUid,
            placeUid: (placeUid !== '' ? placeUid : null),
            startTime: startTime.format("HH:mm:00"),
            endTime: endTime.format("HH:mm:00")
        };

        if (validate()) {
            if (toUpdate.uid) {
                variables.classroomActivityUid = toUpdate.uid;
                variables.classroomUid = classroomUid
                await updateActivity({ variables })
            } else {
                const { toAdd: classRoomsToAdd } = computeItemUpdate([], classroomsState);

                console.log("TO ADD", classRoomsToAdd);
                await Promise.all(classRoomsToAdd.map(async classroomUid =>
                    await createActivity({ variables: { ...variables, classroomUid } })));
            }
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale("fr")}>
            <Container component="main" style={{ maxHeight: "90vh", overflowY: "auto" }}>
                <Typography style={{ margin: '10px' }} component="h1" variant="h4">{activityTitle}</Typography>
                <Divider style={{ marginBottom: '20px' }} />
                <Box component="form" noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl size="small" fullWidth variant="outlined">
                                <InputLabel id="period">Période</InputLabel>
                                <Select
                                    label="Période"
                                    labelid="period"
                                    id="period"
                                    value={periodUid}
                                    onChange={handlePeriodSelect}>
                                    {periods.map(p => <MenuItem key={p.uid} value={p.uid}>{p.name} ({castPeriodDate(p.startDate)} - {castPeriodDate(p.endDate)})</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl size="small" fullWidth variant="outlined">
                                <InputLabel id="organizer">Reponsable</InputLabel>
                                <Select
                                    labelid="organizer"
                                    label="Responsable"
                                    id="organizer"
                                    value={organizerUid}
                                    onChange={handleOrganizerSelect}>
                                    <MenuItem key="empty.organizer" value=''>Aucun</MenuItem>
                                    {users.map(p => <MenuItem key={p.uid} value={p.uid}>{p.lastName} {p.firstName}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl size="small" fullWidth variant="outlined">
                                <InputLabel id="place">Salle</InputLabel>
                                <Select
                                    labelid="place"
                                    label="Salle"
                                    id="place"
                                    value={placeUid}
                                    onChange={handlePlaceSelect}>
                                    {places.map(p => <MenuItem key={p.uid} value={p.uid}>{`${p.name} (${p.capacity} places)`}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                size="small"
                                name="nom"
                                required
                                onChange={(event) => setName(event.target.value)}
                                fullWidth
                                value={name}
                                id="nom"
                                label="Nom"
                                autoFocus
                                error={validationErrors.name != null}
                                helperText={validationErrors.name}
                            />
                        </Grid>
                        <Grid item xs={false} sm={6} />
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="jour">Jour</InputLabel>
                                <Select
                                    labelid="jour"
                                    label="Jour"
                                    id="jour"
                                    value={day}
                                    onChange={(event) => setDay(event.target.value)}
                                >
                                    <MenuItem value={"MONDAY"}>Lundi</MenuItem>
                                    <MenuItem value={"TUESDAY"}>Mardi</MenuItem>
                                    <MenuItem value={"WEDNESDAY"}>Mercredi</MenuItem>
                                    <MenuItem value={"THURSDAY"}>Jeudi</MenuItem>
                                    <MenuItem value={"FRIDAY"}>Vendredi</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TimePicker
                                renderInput={(props) => <TextField {...props} size="small" fullWidth required error={validationErrors.startTime != null} helperText={validationErrors.startTime} />}
                                inputVariant="outlined"
                                size="small"
                                label="Heure de début"
                                value={startTime}
                                ampm={false}
                                onChange={(newValue) => { setStartTime(newValue) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TimePicker
                                renderInput={(props) => <TextField {...props} size="small" fullWidth required error={validationErrors.endTime != null} helperText={validationErrors.endTime} />}
                                inputVariant="outlined"
                                size="small"
                                label="Heure de fin"
                                minutesStep={5}
                                value={endTime}
                                ampm={false}
                                onChange={(newValue) => { setEndTime(newValue) }}
                            />
                        </Grid>
                        {toUpdate.uid ?
                            <Grid item xs={12} sm={6}>
                                <FormControl size="small" fullWidth variant="outlined">
                                    <InputLabel id="group">Classe</InputLabel>
                                    <Select
                                        size="small"
                                        fullWidth={true}
                                        label="Classe"
                                        labelid="classroom"
                                        id="classroom"
                                        value={classroomUid}
                                        onChange={e => { setClassroomUid(e.target.value); }}>
                                        {classrooms.map(c => <MenuItem key={c.uid} value={c.uid}>{c.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <GradesClassroomsSelect
                                    classroomOptions={classroomOptions}
                                    classroomsState={classroomsState}
                                    setClassroomsState={setClassroomsState}
                                />
                            </Grid>
                        }
                    </Grid>
                </Box>
                <Button
                    style={{ float: "right", margin: '10px' }}
                    variant="outlined"
                    color="primary"
                    disabled={loadingActivity}
                    onClick={handleClassroomActivity}>{actionTitle}</Button>


            </Container >
        </LocalizationProvider >
    );

}
export default ClassroomActivity;