import { Box, CircularProgress, Container } from '@mui/material';
import { LIST_ACTIVITIES_FOR_USER, REGISTER_ACTIVITY_FOR_USER, GET_CALENDAR, UNREGISTER_ACTIVITY_FOR_USER } from "../../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import ActivityListResults from './ActivityListResults';
import ActivityListToolbar from './ActivityListToolbar';
import moment from "moment";
import { useSnackbar } from 'notistack';
import { useParams } from "react-router-dom";



function ActivityList({ period }) {
    const { userUid } = useParams();

    const { enqueueSnackbar } = useSnackbar();


    const [activities, setActivities] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(0);

    let [dayFilter, setDayFilter] = useState("");
    let [nameFilter, setNameFilter] = useState("");
    let [startTime, setStartTime] = useState(null);
    let [endTime, setEndTime] = useState(null);
    const [calendar, setCalendar] = useState([]);

    const [getActivities, { loading: loadingActivities }] = useLazyQuery(LIST_ACTIVITIES_FOR_USER, {
        onCompleted: (data) => {
            setActivities(data.listActivitiesForUser.nodes);
            setTotalCount(data.listActivitiesForUser.totalCount);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [getCalendar] = useMutation(GET_CALENDAR, {
        onCompleted: (data) => {
            setCalendar(data.getAllAppointmentForUser.appointments);
        },
        onError: (error) => console.error("Error retrieving activities", error)
    });

    const [registerActivity] = useMutation(REGISTER_ACTIVITY_FOR_USER, {
        onError: e => { enqueueSnackbar(`Impossible de s'inscrire à l'activité`, { variant: "warning" }) },
        onCompleted: d => { enqueueSnackbar(`Inscription à l'activité validée`, { variant: "success" }) }
    })

    const [unRegisterActivity] = useMutation(UNREGISTER_ACTIVITY_FOR_USER, {
        onError: e => { enqueueSnackbar(`Impossible de se désinscrire de l'activité`, { variant: "warning" }) },
        onCompleted: d => { enqueueSnackbar(`Désinscription de l'activité validée`, { variant: "success" }) }
    })

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const handleUnregisterActivity = (activityUid) => {
        return async () => {
            const res = await unRegisterActivity({ variables: { userUid, activityUid } })
            loadData();
            return res;
        }
    }

    const handleRegisterActivity = (activityUid) => {
        return async () => {
            const res = await registerActivity({ variables: { userUid, activityUid } })
            loadData();
            return res;
        }
    }

    const loadData = useCallback(async () => {
        const dayF = dayFilter !== "" ? { day: { in: dayFilter } } : {};
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const startF = startTime ? { startTime: { greaterThanOrEqualTo: moment(startTime).format("HH:mm:00") } } : {};
        const endF = endTime ? { endTime: { lessThanOrEqualTo: moment(endTime).format("HH:mm:00") } } : {};
        const periodF = { periodUid: { equalTo: period.uid } };
        const filters = { ...dayF, ...nameF, ...startF, ...endF, ...periodF };
        const vars = { variables: { userUid, filter: filters, first: limit, offset } };
        getActivities(vars);
        await getCalendar({ variables: { userUid, puid: period.uid } });
    }, [dayFilter, nameFilter, startTime, userUid, endTime, getActivities, limit, offset, period, getCalendar])

    useEffect(() => {
        loadData();
    }, [loadData])



    return (
        <Container component="main">
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <ActivityListToolbar
                        dayFilter={dayFilter}
                        startTime={startTime}
                        endTime={endTime}
                        updateSearch={updateSearch}
                        setDayFilter={setDayFilter}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime} />
                    <Box sx={{ pt: 3 }}>
                        {loadingActivities ?
                            <CircularProgress size={200} thickness={0.5} /> :
                            <ActivityListResults
                                activities={activities}
                                calendar={calendar}
                                user={{ uid: userUid }}
                                limit={limit}
                                offset={offset}
                                page={page}
                                totalCount={totalCount}
                                setLimit={setLimit}
                                setOffset={setOffset}
                                setPage={setPage}
                                handleRegisterActivity={handleRegisterActivity}
                                handleUnregisterActivity={handleUnregisterActivity} />}
                    </Box>
                </Container>
            </Box>
        </Container>
    )
};

export default ActivityList;