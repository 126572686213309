import React, { useState } from 'react';
import { useMutation } from "@apollo/client";
import { Box, Button, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { CREATE_PLACE } from "../../../graphql/admin-requests";
import { useSnackbar } from 'notistack';



function Place({ onFinish }) {
    const { enqueueSnackbar } = useSnackbar();


    let [name, setName] = useState("");
    let [capacity, setCapacity] = useState(0);


    const [createPlace, { loadingPlace, error }] = useMutation(CREATE_PLACE, {
        onCompleted: (data) => {
            onFinish();
        },
        onError: (error) => enqueueSnackbar("Impossible de créer une salle", { variant: "error" })
    });

    async function handlePlace(event) {
        event.preventDefault();
        await createPlace({ variables: { name, capacity } });
    }

    return (
        <Container component="main">
            <Typography style={{ margin: '10px' }} component="h1" variant="h5">Nouvelle salle</Typography>
            <Divider style={{ marginBottom: '20px' }} />

            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            name="place"
                            required
                            onChange={(event) => setName(event.target.value)}
                            fullWidth
                            value={name}
                            id="place"
                            label="Nom de la salle"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            name="capacity"
                            required
                            onChange={(event) => setCapacity(parseInt(event.target.value, 10))}
                            fullWidth
                            value={capacity}
                            id="capacity"
                            label="Capacité de la salle"
                            autoFocus
                        />
                    </Grid>
                </Grid>
            </Box>
            <Button
                style={{ float: "right", margin: '10px' }}
                variant="outlined"
                color="primary"
                disabled={loadingPlace}
                onClick={handlePlace}>Ajouter une salle</Button>
            {error && <p>{error.message}</p>}
        </Container>
    );

}
export default Place;