import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import ClassroomListConfirmDelete from './ClassroomListConfirmDelete';
import { DeleteIcon, StudentIcon, CalendarIcon, CalendarCheckIcon } from "../../Icons";
import CUModal from '../modals/CUModal';
import UserByClassroomList from '../UserByClassroomList/UserByClassroomList';
import CustomIconButton from "../subcomponents/CustomIconButton";



const ClassroomListResults = ({ classrooms, totalCount, setLimit, limit, setOffset, offset, viewClassroomCalendar, handleDeleteClassroom, readOnly, ...rest }) => {

    const [selectedClassroomIds, setSelectedClassroomIds] = useState([]);
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [toDelete, setToDelete] = useState({});

    const [openUserListModal, setOpenUserListModal] = useState(false);
    const [classroomUid, setClassroomUid] = useState("");


    const handleSelectAll = (event) => {
        let newSelectedClassroomIds;

        if (event.target.checked) {
            newSelectedClassroomIds = classrooms.map((classroom) => classroom.uid);
        } else {
            newSelectedClassroomIds = [];
        }

        setSelectedClassroomIds(newSelectedClassroomIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedClassroomIds.indexOf(id);
        let newSelectedClassroomIds = [];

        if (selectedIndex === -1) {
            newSelectedClassroomIds = newSelectedClassroomIds.concat(selectedClassroomIds, id);
        } else if (selectedIndex === 0) {
            newSelectedClassroomIds = newSelectedClassroomIds.concat(selectedClassroomIds.slice(1));
        } else if (selectedIndex === selectedClassroomIds.length - 1) {
            newSelectedClassroomIds = newSelectedClassroomIds.concat(selectedClassroomIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedClassroomIds = newSelectedClassroomIds.concat(
                selectedClassroomIds.slice(0, selectedIndex),
                selectedClassroomIds.slice(selectedIndex + 1)
            );
        }

        setSelectedClassroomIds(newSelectedClassroomIds);
    };


    const showUserListModal = (uid) => {
        setClassroomUid(uid);
        setOpenUserListModal(true);
    }

    const handleUserListModalClose = () => {
        setOpenUserListModal(false);
    }

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const openConfirmDelete = (classroom) => {
        setToDelete(classroom);
        setOpenDialog(true);
    }

    const closeDialog = () => setOpenDialog(false);

    const handlePageChange = async (event, newPage) => {
        await setOffset(newPage * limit);
        await setPage(newPage);
    };

    return (
        <>
            <CUModal open={openUserListModal} classroomUid={classroomUid} handleClose={handleUserListModalClose} component={UserByClassroomList} />
            <ClassroomListConfirmDelete
                open={openDialog}
                classroom={toDelete}
                onClose={closeDialog}
                onConfirm={() => handleDeleteClassroom(toDelete.uid)}
            />
            <Card {...rest}>
                {/* <PerfectScrollbar> */}
                <Box>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedClassroomIds.length === classrooms.length}
                                        color="primary"
                                        indeterminate={
                                            selectedClassroomIds.length > 0
                                            && selectedClassroomIds.length < classrooms.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell size="small">Nom</TableCell>
                                <TableCell size="small">Niveau</TableCell>
                                <TableCell size="small">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {classrooms.slice(0, limit).map((classroom) => (
                                <TableRow hover key={classroom.uid} selected={selectedClassroomIds.indexOf(classroom.uid) !== -1}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedClassroomIds.indexOf(classroom.uid) !== -1}
                                            onChange={(event) => handleSelectOne(event, classroom.uid)}
                                            value="true"
                                        />
                                    </TableCell>
                                    <TableCell size="small">{classroom.name}</TableCell>
                                    <TableCell size="small">{classroom.gradeByGradeUid.name}</TableCell>
                                    <TableCell size="small">
                                        <CustomIconButton
                                            title="Elèves"
                                            button={<Button
                                                onClick={() => showUserListModal(classroom.uid)}
                                                size="small"
                                                variant="outlined"
                                                color="default"
                                                endIcon={<StudentIcon />}
                                            >{classroom.usersByClassroomUid.totalCount}</Button>}
                                        />
                                        <CustomIconButton
                                            title="Voir emplois du temps"
                                            link={readOnly ? `/admins/calendars/classroom/${classroom.uid}` : `/superadmins/calendars/classroom/${classroom.uid}`}
                                            icon={<CalendarIcon />} />
                                        <CustomIconButton
                                            title="Validations d'emplois du temps"
                                            link={readOnly ? `/admins/classrooms/${classroom.uid}/process` : `/superadmins/classrooms/${classroom.uid}/process`}
                                            icon={<CalendarCheckIcon />} />
                                        <CustomIconButton
                                            hide={classroom.usersByClassroomUid.totalCount > 0}
                                            onClick={() => openConfirmDelete(classroom)}
                                            title="Supprimer"
                                            icon={<DeleteIcon />} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                {/* </PerfectScrollbar> */}
                <TablePagination
                    component="div"
                    count={totalCount}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </Card >
        </ >
    );
};

ClassroomListResults.propTypes = {
    classrooms: PropTypes.array.isRequired
};

export default ClassroomListResults;