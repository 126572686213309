import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import PeriodListConfirmDelete from './PeriodListConfirmDelete';
import { DeleteIcon, EditIcon } from '../../Icons';
import GroupLabel from '../subcomponents/GroupLabel';
import CustomIconButton from "../subcomponents/CustomIconButton";
import CUModal from '../modals/CUModal';
import CUPeriod from "../CreateUpdateComponents/Period";
import moment from "moment";
import { dbToLocale } from '../../../utils/time';


const PeriodListResults = ({ periods, totalCount, setLimit, limit, loadData, setOffset, offset, handleDeletePeriod, ...rest }) => {

    const [selectedPeriodIds, setSelectedPeriodIds] = useState([]);
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [toDelete, setToDelete] = useState({});

    const [openEditModal, setOpenEditModal] = useState(false);
    const [periodToUpdate, setPeriodToUpdate] = useState({});

    const handleSelectAll = (event) => {
        let newSelectedPeriodIds;

        if (event.target.checked) {
            newSelectedPeriodIds = periods.map((period) => period.uid);
        } else {
            newSelectedPeriodIds = [];
        }

        setSelectedPeriodIds(newSelectedPeriodIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPeriodIds.indexOf(id);
        let newSelectedPeriodIds = [];

        if (selectedIndex === -1) {
            newSelectedPeriodIds = newSelectedPeriodIds.concat(selectedPeriodIds, id);
        } else if (selectedIndex === 0) {
            newSelectedPeriodIds = newSelectedPeriodIds.concat(selectedPeriodIds.slice(1));
        } else if (selectedIndex === selectedPeriodIds.length - 1) {
            newSelectedPeriodIds = newSelectedPeriodIds.concat(selectedPeriodIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedPeriodIds = newSelectedPeriodIds.concat(
                selectedPeriodIds.slice(0, selectedIndex),
                selectedPeriodIds.slice(selectedIndex + 1)
            );
        }

        setSelectedPeriodIds(newSelectedPeriodIds);
    };

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const openConfirmDelete = (period) => {
        setToDelete(period);
        setOpenDialog(true);
    }

    const closeDialog = () => setOpenDialog(false);

    const handlePageChange = async (event, newPage) => {
        await setOffset(newPage * limit);
        await setPage(newPage);
    };

    return (
        <>
            <PeriodListConfirmDelete
                open={openDialog}
                period={toDelete}
                onClose={closeDialog}
                onConfirm={() => handleDeletePeriod(toDelete.uid)}
            />
            <Card {...rest}>
                {/* <PerfectScrollbar> */}
                <CUModal open={openEditModal} component={CUPeriod} handleClose={() => { setOpenEditModal(false); loadData() }} toUpdate={periodToUpdate} />

                <Box>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedPeriodIds.length === periods.length}
                                        color="primary"
                                        indeterminate={
                                            selectedPeriodIds.length > 0
                                            && selectedPeriodIds.length < periods.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell>Nom</TableCell>
                                <TableCell>Début / Fin</TableCell>
                                <TableCell>Saisie</TableCell>
                                <TableCell>Inscriptions</TableCell>
                                <TableCell>Niveaux</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {periods.slice(0, limit).map((period) => (
                                <TableRow hover key={period.uid} selected={selectedPeriodIds.indexOf(period.uid) !== -1}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedPeriodIds.indexOf(period.uid) !== -1}
                                            onChange={(event) => handleSelectOne(event, period.uid)}
                                            value="true"
                                        />
                                    </TableCell>
                                    <TableCell>{period.name}</TableCell>
                                    <TableCell>{moment(period.startDate).format("dddd DD MMMM yyyy")} <br /> {moment(period.endDate).format("dddd DD MMMM yyyy")}</TableCell>
                                    <TableCell>{period.openAdminStart && dbToLocale(period.openAdminStart).format("DD MMMM YYYY - HH:mm")} <br /> {period.openAdminEnd && dbToLocale(period.openAdminEnd).format("DD MMMM YYYY - HH:mm")}</TableCell>
                                    <TableCell>{period.openUserStart && dbToLocale(period.openUserStart).format("DD MMMM YYYY -  HH:mm")} <br /> {period.openUserEnd && dbToLocale(period.openUserEnd).format("DD MMMM YYYY - HH:mm")}</TableCell>
                                    <TableCell>{period.periodGradesByPeriodUid.nodes.map(({ gradeByGradeUid }) =>
                                        <GroupLabel key={gradeByGradeUid.uid} name={gradeByGradeUid.name} />)}
                                    </TableCell>
                                    <TableCell>
                                        <CustomIconButton
                                            onClick={() => {
                                                setPeriodToUpdate(period);
                                                setOpenEditModal(true);
                                            }}
                                            title="Modifier"
                                            icon={<EditIcon />} />
                                        <CustomIconButton
                                            onClick={() => openConfirmDelete(period)}
                                            title="Supprimer"
                                            icon={<DeleteIcon />} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                {/* </PerfectScrollbar> */}
                <TablePagination
                    component="div"
                    count={totalCount}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </Card >
        </ >
    );
};

PeriodListResults.propTypes = {
    periods: PropTypes.array.isRequired
};

export default PeriodListResults;