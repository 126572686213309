import { Box, Container, CircularProgress } from '@mui/material';
import {
    DELETE_ACTIVITY,
    MAX_TAKEN_ACTIVITY,
    MIN_TAKEN_ACTIVITY,
    MIN_REMAINING_ACTIVITY,
    MAX_REMAINING_ACTIVITY,
    MAX_TAKEN_ACTIVITY_ADMIN,
    MIN_TAKEN_ACTIVITY_ADMIN,
    MIN_REMAINING_ACTIVITY_ADMIN,
    MAX_REMAINING_ACTIVITY_ADMIN,
    LIST_GRADES_AND_CLASSROOMS_BY_PERIOD,
    LIST_ACTIVITIES_FOR_CLASSROOMS,
    LIST_ACTIVITIES_SUPER_ADMIN,
    LIST_ACTIVITIES_ADMIN
} from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import ActivityListResults from './ActivityListResults';
import ActivityListToolbar from './ActivityListToolbar';
import moment from "moment";
import { useSnackbar } from 'notistack';



function ActivityList({ user, adminView, period }) {

    const { enqueueSnackbar } = useSnackbar();


    const daysOptions = [
        { value: "MONDAY", label: "Lundi" },
        { value: "TUESDAY", label: "Mardi" },
        { value: "WEDNESDAY", label: "Mercredi" },
        { value: "THURSDAY", label: "Jeudi" },
        { value: "FRIDAY", label: "Vendredi" }
    ]

    const [activities, setActivities] = useState([]);
    const [grades, setGrades] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [page, setPage] = useState(0);
    const [maxTaken, setMaxTaken] = useState(0);
    const [minTaken, setMinTaken] = useState(0);
    const [takenFilter, setTakenFilter] = useState([0, 100000]);
    const [maxRemaining, setMaxRemaining] = useState(0);
    const [minRemaining, setMinRemaining] = useState(0);
    const [remainingFilter, setRemainingFilter] = useState([0, 100000]);

    const [gradesOptions, setGradesOptions] = useState([]);
    const [classroomFilter, setClassroomFilter] = useState([]);


    let [dayFilter, setDayFilter] = useState(daysOptions.map(i => i.value));
    let [adminFilter, setAdminFilter] = useState([]);
    let [nameFilter, setNameFilter] = useState("");
    let [startTime, setStartTime] = useState(null);
    let [endTime, setEndTime] = useState(null);

    const getListRequest = () => {
        if (adminView) return LIST_ACTIVITIES_ADMIN;
        if (classroomFilter && classroomFilter.length > 0) return LIST_ACTIVITIES_FOR_CLASSROOMS;
        return LIST_ACTIVITIES_SUPER_ADMIN
    }

    const getListRequestResultName = () => {
        if (adminView) return "allActivityAdminViews";
        if (classroomFilter && classroomFilter.length > 0) return "listActivitiesForClassrooms";
        return "allActivitySuperAdminViews"
    }


    const [getActivities, { loading: loadingActivities }] = useLazyQuery(getListRequest(), {
        onCompleted: (data) => {
            setActivities(data[getListRequestResultName()].nodes);
            setTotalCount(data[getListRequestResultName()].totalCount);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [deleteActivity] = useMutation(DELETE_ACTIVITY, {
        onError: e => enqueueSnackbar("Impossible de supprimer l'activité"),
        onCompleted: d => enqueueSnackbar("Activité supprimée")
    })

    const [getMaxTaken] = useLazyQuery(adminView ? MAX_TAKEN_ACTIVITY_ADMIN : MAX_TAKEN_ACTIVITY, {
        variables: { filter: { periodUid: { equalTo: period.uid } } },
        onCompleted: (data) => {
            const res = data[adminView ? "allActivityAdminViews" : "allActivitySuperAdminViews"].nodes[0];
            if (res) {
                setMaxTaken(parseInt(res.taken))
            }
        },
        fetchPolicy: 'cache-and-network'
    });

    const [getMaxRemaining] = useLazyQuery(adminView ? MAX_REMAINING_ACTIVITY_ADMIN : MAX_REMAINING_ACTIVITY, {
        variables: { filter: { periodUid: { equalTo: period.uid } } },
        onCompleted: (data) => {
            const res = data[adminView ? "allActivityAdminViews" : "allActivitySuperAdminViews"].nodes[0];
            if (res) {
                setMaxRemaining(parseInt(res.remaining))
            }
        },
        fetchPolicy: 'cache-and-network'
    });

    const [getMinTaken] = useLazyQuery(adminView ? MIN_TAKEN_ACTIVITY_ADMIN : MIN_TAKEN_ACTIVITY, {
        variables: { filter: { periodUid: { equalTo: period.uid } } },
        onCompleted: (data) => {
            const res = data[adminView ? "allActivityAdminViews" : "allActivitySuperAdminViews"].nodes[0];
            if (res) {
                setMinTaken(parseInt(res.taken))
            }
        },
        fetchPolicy: 'cache-and-network'
    });

    const [getMinRemaining] = useLazyQuery(adminView ? MIN_REMAINING_ACTIVITY_ADMIN : MIN_REMAINING_ACTIVITY, {
        variables: { filter: { periodUid: { equalTo: period.uid } } },
        onCompleted: (data) => {
            const res = data[adminView ? "allActivityAdminViews" : "allActivitySuperAdminViews"].nodes[0];
            if (res) {
                setMinRemaining(parseInt(res.remaining))
            }
        },
        fetchPolicy: 'cache-and-network'
    });


    const [getGrades] = useLazyQuery(LIST_GRADES_AND_CLASSROOMS_BY_PERIOD, {
        onCompleted: data => {
            setGrades(data.allPeriodGrades.nodes.map(g => g.gradeByGradeUid))
            setGradesOptions(data.allPeriodGrades.nodes.map(g => { return { value: g.gradeByGradeUid.uid, label: g.gradeByGradeUid.name } }));
        },
        fetchPolicy: 'cache-and-network'
    });

    const updateDayFilter = (days) => {
        setDayFilter(days.map(i => i.value));
    }

    const updateAdminFilter = (admins) => {
        setAdminFilter(admins.map(i => i.value));
    }

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const loadGrades = useCallback(async (periodUid) => {
        await getGrades({ variables: { periodUid } });
    }, [getGrades]);

    const loadData = useCallback(async () => {
        const adminF = adminFilter.length > 0 ? { organizerUid: { in: adminFilter } } : {};
        const dayF = dayFilter.length > 0 ? { day: { in: dayFilter } } : {};
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const startF = startTime ? { startTime: { greaterThanOrEqualTo: moment(startTime).format("HH:mm:00") } } : {};
        const endF = endTime ? { endTime: { lessThanOrEqualTo: moment(endTime).format("HH:mm:00") } } : {};
        const periodF = { periodUid: { equalTo: period.uid } };
        const takenF = takenFilter ? { taken: { lessThanOrEqualTo: takenFilter[1], greaterThanOrEqualTo: takenFilter[0] } } : {};
        const remainingF = remainingFilter ? { remaining: { lessThanOrEqualTo: remainingFilter[1], greaterThanOrEqualTo: remainingFilter[0] } } : {};
        const filters = { ...dayF, ...nameF, ...startF, ...endF, ...remainingF, ...periodF, ...adminF, ...takenF };
        const filter = {
            variables: {
                classroomUids: classroomFilter,
                filter: filters,
                first: limit,
                offset
            }
        }
        await getActivities(filter);
    }, [dayFilter, adminFilter, nameFilter, takenFilter, remainingFilter, startTime, endTime, getActivities, limit, offset, period, classroomFilter]);

    const updateGradeFilter = (g) => {
        const gradeUids = g.map(i => i.value);
        const grade = grades.filter(i => gradeUids.includes(i.uid));
        const res = grade.reduce((acc, curr) => acc.concat(curr.classroomsByGradeUid.nodes.map(i => i.uid)), []);
        setClassroomFilter(res);
    }

    const handleDeleteActivity = useCallback(async (activityUid) => {
        await deleteActivity({
            variables: { activityUid }
        });
        loadData();
    }, [deleteActivity, loadData]);

    useEffect(() => {
        if (period.uid) {
            loadGrades(period.uid);
            getMaxTaken();
            getMinTaken();
            getMaxRemaining();
            getMinRemaining();
        }
    }, [loadGrades, period, getMaxRemaining, getMaxTaken, getMinRemaining, getMinTaken])

    useEffect(() => {
        loadData();
    }, [loadData])



    return (
        <Container component="main" maxWidth={false}>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <ActivityListToolbar
                        user={user}
                        period={period}
                        updateGradeFilter={updateGradeFilter}
                        gradesOptions={gradesOptions}
                        minTaken={minTaken}
                        maxTaken={maxTaken}
                        setTakenFilter={setTakenFilter}
                        minRemaining={minRemaining}
                        maxRemaining={maxRemaining}
                        setRemainingFilter={setRemainingFilter}
                        adminView={adminView}
                        loadData={loadData}
                        startTime={startTime}
                        endTime={endTime}
                        daysOptions={daysOptions}
                        dayFilter={dayFilter}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                        updateSearch={updateSearch}
                        updateDayFilter={updateDayFilter}
                        updateAdminFilter={updateAdminFilter} />
                    <Box sx={{ pt: 3 }}>
                        {loadingActivities ?
                            <CircularProgress size={200} thickness={0.5} />
                            : <ActivityListResults
                                user={user}
                                adminView={adminView}
                                loadData={loadData}
                                activities={activities}
                                grades={grades}
                                limit={limit}
                                offset={offset}
                                page={page}
                                totalCount={totalCount}
                                setLimit={setLimit}
                                setOffset={setOffset}
                                setPage={setPage}
                                handleDeleteActivity={handleDeleteActivity} />}
                    </Box>
                </Container>
            </Box>
        </Container>
    )
};

export default ActivityList;