import { Box, Container, Table, TableHead, TableRow, TableCell, TableBody, Typography, Divider } from '@mui/material';
import { GET_CLASSROOM, LIST_PROCESS_CALENDAR } from "../../../graphql/admin-requests";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { groupBy } from "../../../utils/reducers"
import { CalendarIcon, EyeIcon } from '../../Icons';
import ProcessCalendarDetails from '../../CommonComponents/ProcessCalendarDetails';
import CUModal from '../modals/CUModal';
import CustomIconButton from '../subcomponents/CustomIconButton';
import UserCalendarValidation from './UserCalendarValidation';
import { stateChip } from '../../../utils/process'

function ProcessCalendarList({ period, mapping, role }) {

    const { enqueueSnackbar } = useSnackbar();
    const { classroomUid } = useParams();



    const [classroom, setClassroom] = useState([])
    const [users, setUsers] = useState([]);
    const [processCalendarByUser, setProcessCalendarByUser] = useState({});
    const [processes, setProcesses] = useState([]);

    const [userToView, setUserToView] = useState({});
    const [openCalendarModal, setOpenCalendarModal] = useState(false);

    const [listProcessCalendar] = useLazyQuery(LIST_PROCESS_CALENDAR, {
        onCompleted: (data) => {
            if (data.allCalendarProcesses && data.allCalendarProcesses.nodes) {
                const res = data.allCalendarProcesses.nodes;
                setProcessCalendarByUser(groupBy(res, "userUid"))
            }
        },
        onError: (error) => enqueueSnackbar("Impossible de recuperer la liste des validations", { variant: "error" }),
        fetchPolicy: "cache-and-network"
    })

    const [getClassroomUsers] = useLazyQuery(GET_CLASSROOM, {
        onCompleted: (data) => {
            if (data) setClassroom(data.classroomByUid);
            if (data && data.classroomByUid && data.classroomByUid.usersByClassroomUid) setUsers(data.classroomByUid.usersByClassroomUid.nodes);
        },
        onError: (error) => enqueueSnackbar("Impossible de recuperer la liste des eleves", { variant: "error" })
    })



    useEffect(() => {
        if (classroomUid) {
            getClassroomUsers({ variables: { classroomUid } })
        }
        if (users.length > 1) {
            listProcessCalendar({ variables: { periodUid: period.uid, userUids: users.map(u => u.uid) } })
        }
    }, [classroomUid, getClassroomUsers, period, users, listProcessCalendar]);


    const lastStateChip = (i, onClick) => {
        if (i) {
            const lastState = i[0].calendarState;
            return stateChip(lastState, mapping, onClick);
        } else {
            return stateChip('DEFAULT', mapping, onClick);
        }
    }

    const resetProcesses = () => {
        setProcesses([]);
    }

    return (
        <Container component="main" maxWidth={false}>
            {processes.length > 0 &&
                <CUModal
                    open={processes.length > 0}
                    component={ProcessCalendarDetails}
                    processes={processes}
                    handleClose={resetProcesses}
                    role={role}
                    mapping={mapping} />}
            {openCalendarModal && <CUModal open={openCalendarModal} disableScrollLock={true} component={UserCalendarValidation} mapping={mapping} role={role} handleClose={() => { setOpenCalendarModal(false) }} period={period} user={userToView} />}
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Typography variant="h4" >Liste des validations pour {classroom.name}</Typography>
                <Divider style={{ margin: "20px" }} />
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell size="small">Eleve</TableCell>
                            <TableCell size="small">Validations</TableCell>
                            <TableCell size="small">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(u => {
                            return (
                                <TableRow hover key={u.uid}>
                                    <TableCell size="small">{u.firstName} {u.lastName}</TableCell>
                                    <TableCell size="small">{lastStateChip(processCalendarByUser[u.uid], () => setProcesses(processCalendarByUser[u.uid] || []))}</TableCell>
                                    <TableCell size="small">
                                        <CustomIconButton
                                            onClick={() => { setUserToView(u); setOpenCalendarModal(true); }}
                                            title="Emploi du temps"
                                            icon={<CalendarIcon />} />
                                        {role && role === "horaroj_super_admin" && <CustomIconButton
                                            link={`/userApp/${u.uid}/calendar`}
                                            blank={true}
                                            title="Accès eleve"
                                            icon={<EyeIcon />} />}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>

                </Table>

            </Box>
        </Container >
    )
};

export default ProcessCalendarList;