import React, { Fragment, useState } from 'react';
import { useMutation } from "@apollo/client";
import { Box, Button, Container, Divider, Grid, Typography, TextField, IconButton } from '@mui/material';
import { CREATE_CALENDAR_PROCESS } from "../../graphql/user-requests";
import ProcessCalendarDetails from '../CommonComponents/ProcessCalendarDetails';
import { Collapse } from '@mui/material';
import { ExpandMoreIcon } from '../Icons'


function ProcessCalendar({ onFinish, period, user, processes, mapping, canProcess = false }) {

    const [comment, setComment] = useState("");
    const [expandDetails, setExpandDetails] = useState(true);

    const [processCalendar] = useMutation(CREATE_CALENDAR_PROCESS, {
        onCompleted: (data) => {
            console.log("C'est OK", data)
        },
        onError: (error) => console.error("Error processing calendar", error)
    })

    const handleProcessCalendar = async () => {
        await processCalendar({ variables: { userUid: user.uid, periodUid: period.uid, comment } });
        onFinish();
    }


    return (
        <Container component="main" style={{ maxHeight: "90vh", overflowY: "auto" }}>
            {canProcess &&
                <Fragment>
                    <Typography variant="h6">Valider mon emploi du temps</Typography>
                    <Divider style={{ marginBottom: '20px', }} />
                    <Typography variant="subtitle2" style={{ marginBottom: '20px' }}>⚠️ Une fois votre emploi du temps validé, vous ne pourrez plus le modifier</Typography>
                    <Box component="form" noValidate>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                label="Ajouter un commentaire (facultatif)"
                                multiline
                                value={comment}
                                onChange={(event) => setComment(event.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button style={{ margin: '10px' }} variant="outlined" color="primary" onClick={handleProcessCalendar}>Valider</Button>
                    </Box>
                </Fragment>}
            {processes && processes.length > 0 &&
                <Fragment>
                    <IconButton
                        onClick={() => setExpandDetails(!expandDetails)}
                        aria-expanded={expandDetails}
                        aria-label="show more"
                        title="Voir l'historique"
                    >
                        <ExpandMoreIcon />
                    </IconButton>

                    <Collapse in={expandDetails}>
                        <ProcessCalendarDetails processes={processes} mapping={mapping} />
                    </Collapse>
                </Fragment>
            }
        </Container >
    );

}
export default ProcessCalendar;