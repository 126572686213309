import moment from "moment";

const showProcess = period => {
    const now = moment();
    const openUserStart = period.openUserStart;
    const startDate = period.startDate;
    if ((period && period.openUser) || // Period is currently openned
        (openUserStart && moment(openUserStart).isSameOrBefore(now)) || // Period has already been openned
        (startDate && moment(startDate).isSameOrBefore(now)) // Period has already been started (may be finished)
    ) { return true; }
    return false;
}

const isOutside = (day1, start1, end1, day2, start2, end2) => {
    if (day1 !== day2) return true;

    const s1 = moment(start1, "hh:mm:ss"); //14:30
    const s2 = moment(start2, "hh:mm:ss"); // 09:30
    const e1 = moment(end1, "hh:mm:ss"); // 16:30
    const e2 = moment(end2, "hh:mm:ss"); // 11:45

    // console.log({ start1, start2, end1, end2, res: (s1.isSameOrAfter(e2) || e1.isSameOrBefore(s2)) })

    // Begin after the end of the other
    // or Finish before the start of the other
    return (s1.isSameOrAfter(e2) || e1.isSameOrBefore(s2));
}

const alreadyRegister = (activity, calendar) => {
    const res = calendar
        .filter(i => i.appointmentUid === activity.uid);
    return res.length > 0;
}

const inConflict = (activity, calendar) => {
    const day = activity.day;
    const start = activity.startTime;
    const end = activity.endTime;

    const res = calendar
        .filter(i => i.appointmentUid !== activity.uid)
        .filter(i => (!isOutside(day, start, end, i.day, i.startTime, i.endTime)));
    return res;
}

//2018-11-01T09:45
const toDate = (day, time) => {
    const mapDay = {
        "MONDAY": "2018-01-01T",
        "TUESDAY": "2018-01-02T",
        "WEDNESDAY": "2018-01-03T",
        "THURSDAY": "2018-01-04T",
        "FRIDAY": "2018-01-05T",
        "SATURDAY": "2018-01-06T",
        "SUNDAY": "2018-01-07T"
    }

    return `${mapDay[day]}${time}`

}

const currentDate = '2018-01-01';

const formatActivityTime = activity => {
    return `${moment(activity.startTime, "HH:mm:00").format("HH:mm")} - ${moment(activity.endTime, "HH:mm:00").format("HH:mm")}`
}

const castPeriodDate = date => {
    return moment(date).format("DD-MM-YYYY");
}

const dbToLocale = (utcDate) => {
    return moment.utc(utcDate).local();
}

const localToDb = (localDate) => {
    return moment(localDate).utc();
}

export { toDate, currentDate, castPeriodDate, inConflict, alreadyRegister, formatActivityTime, showProcess, dbToLocale, localToDb };