import { Box, Button, Grid, TextField, InputAdornment, SvgIcon } from '@mui/material';
import { SearchIcon } from '../../Icons';

const UserListToolbar = ({ handleOpenCUUserModal, updateSearch, ...props }) => (
    <Box {...props}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button color="primary" variant="contained" onClick={handleOpenCUUserModal}>Ajouter</Button>
        </Box>
        <Box pb={4}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <TextField
                        size="small"
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                                </InputAdornment>
                            )
                        }}
                        placeholder="Rechercher un élève"
                        variant="outlined"
                        onChange={updateSearch}
                    />
                </Grid>
            </Grid>
        </Box>
    </Box>
);

export default UserListToolbar;