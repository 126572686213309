import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import GroupLabel from '../subcomponents/GroupLabel';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { CalendarIcon, DeleteIcon, EditIcon, EyeIcon, LoginIcon, GroupIcon, PasswordIcon } from '../../Icons';
import CUUser from "../CreateUpdateComponents/User";
import PasswordUpdate from "../CreateUpdateComponents/PasswordUpdate";
import UserLogin from "../CreateUpdateComponents/UserLogin";
import CUModal from '../modals/CUModal';
import UserCalendar from "../UserCalendar";
import CustomIconButton from "../subcomponents/CustomIconButton"

const UserListResults = ({ period, handleUpdateUserGroups, handleDeleteUser, users, totalCount, setLimit, limit, setOffset, offset, loadData, ...rest }) => {

    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [page, setPage] = useState(0);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openEditPasswordModal, setOpenEditPasswordModal] = useState(false);
    const [openGetEmailModal, setOpenGetEmailModal] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState({});

    const [openCalendarModal, setOpenCalendarModal] = useState(false);
    const [userToView, setUserToView] = useState({});

    const handleSelectAll = (event) => {
        let newSelectedUserIds;

        if (event.target.checked) {
            newSelectedUserIds = users.map((user) => user.uid);
        } else {
            newSelectedUserIds = [];
        }

        setSelectedUserIds(newSelectedUserIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedUserIds.indexOf(id);
        let newSelectedUserIds = [];

        if (selectedIndex === -1) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds, id);
        } else if (selectedIndex === 0) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(1));
        } else if (selectedIndex === selectedUserIds.length - 1) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedUserIds = newSelectedUserIds.concat(
                selectedUserIds.slice(0, selectedIndex),
                selectedUserIds.slice(selectedIndex + 1)
            );
        }

        setSelectedUserIds(newSelectedUserIds);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setOffset(newPage * limit);
        setPage(newPage);
    };

    return (
        <Card {...rest}>
            {/* <PerfectScrollbar> */}
            <CUModal open={openEditModal} component={CUUser} handleClose={() => { setOpenEditModal(false); loadData() }} toUpdate={userToUpdate} />
            <CUModal open={openEditPasswordModal} component={PasswordUpdate} handleClose={() => { setOpenEditPasswordModal(false); loadData() }} toUpdate={userToUpdate} />
            {openGetEmailModal && <CUModal open={openGetEmailModal} component={UserLogin} handleClose={() => { setOpenGetEmailModal(false); loadData() }} toSee={userToUpdate} />}
            <CUModal open={openCalendarModal} disableScrollLock={true} component={UserCalendar} handleClose={() => { setOpenCalendarModal(false) }} period={period} user={userToView} />
            <Box sx={{ minWidth: 1050 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedUserIds.length === users.length}
                                    color="primary"
                                    indeterminate={
                                        selectedUserIds.length > 0
                                        && selectedUserIds.length < users.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell size="small">Nom</TableCell>
                            <TableCell size="small">Prenom</TableCell>
                            <TableCell size="small">Classe</TableCell>
                            <TableCell size="small">Groupes</TableCell>
                            <TableCell size="small">Date de création</TableCell>
                            <TableCell size="small">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.slice(0, limit).map((user) => (
                            <TableRow hover key={user.uid} selected={selectedUserIds.indexOf(user.uid) !== -1}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedUserIds.indexOf(user.uid) !== -1}
                                        onChange={(event) => handleSelectOne(event, user.uid)}
                                        value="true"
                                    />
                                </TableCell>
                                <TableCell size="small">{user.lastName}</TableCell>
                                <TableCell size="small">{user.firstName}</TableCell>
                                <TableCell size="small">{user.classroomByClassroomUid && user.classroomByClassroomUid.name}</TableCell>
                                <TableCell size="small">{user.userGroupsByUserUid.nodes.map(({ groupByGroupUid }) =>
                                    <GroupLabel key={groupByGroupUid.uid} name={groupByGroupUid.name} />)}</TableCell>
                                <TableCell size="small">{moment(user.createdAt).format('DD/MM/YYYY')}</TableCell>
                                <TableCell size="small">
                                    <CustomIconButton
                                        onClick={() => {
                                            setUserToView(user);
                                            setOpenCalendarModal(true);
                                        }}
                                        title="Emploi du temps"
                                        icon={<CalendarIcon />} />

                                    <CustomIconButton
                                        onClick={() => {
                                            setUserToUpdate(user);
                                            setOpenGetEmailModal(true);
                                        }}
                                        title="Voir login"
                                        icon={<LoginIcon />} />

                                    <CustomIconButton
                                        onClick={() => {
                                            setUserToUpdate(user);
                                            setOpenEditModal(true);
                                        }}
                                        title="Modifier"
                                        icon={<EditIcon />} />

                                    <CustomIconButton
                                        onClick={() => {
                                            setUserToUpdate(user);
                                            setOpenEditPasswordModal(true);
                                        }}
                                        title="Modifier mot de passe"
                                        icon={<PasswordIcon />} />

                                    <CustomIconButton
                                        link={`/userApp/${user.uid}/${period.uid}/calendar`}
                                        blank={true}
                                        title="Accès eleve"
                                        icon={<EyeIcon />} />

                                    <CustomIconButton
                                        onClick={handleUpdateUserGroups(user)}
                                        title="Groupes"
                                        icon={<GroupIcon />} />

                                    <CustomIconButton
                                        onClick={handleDeleteUser(user)}
                                        title="Supprimer"
                                        icon={<DeleteIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {/* </PerfectScrollbar> */}
            <TablePagination
                component="div"
                count={totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
        </Card >
    );
};

UserListResults.propTypes = {
    users: PropTypes.array.isRequired
};

export default UserListResults;