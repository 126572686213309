import React, { useState, useEffect } from 'react';
import { useMutation } from "@apollo/client";
import { Box, Button, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';



function SimpleItem({ onFinish, createRequest, updateRequest, itemName, toUpdate = {} }) {

    const { enqueueSnackbar } = useSnackbar();

    let [name, setName] = useState("");
    let [actionTitle, setActionTitle] = useState("Ajouter");

    const [createItem, { creating, creationError }] = useMutation(createRequest, {
        onCompleted: onFinish,
        onError: (error) => enqueueSnackbar(`Impossible de créer un ${itemName}`)
    });

    const [updateItem, { updating, updateError }] = useMutation(updateRequest, {
        onCompleted: onFinish,
        onError: (error) => enqueueSnackbar(`Impossible de créer un ${itemName}`)
    });

    async function handleSimpleItem(event) {
        event.preventDefault();
        if (toUpdate.uid) {

            await updateItem({ variables: { name, uid: toUpdate.uid } });
        } else {
            await createItem({ variables: { name } });
        }
    }

    const handleKeyPressed = async (event) => {
        if (event.key === 'Enter') {
            await handleSimpleItem(event);
        }
    }

    useEffect(() => {
        if (toUpdate.uid) {
            setName(toUpdate.name)
            setActionTitle("Modifier");
        }
    }, [actionTitle, toUpdate]);

    const labelValue = `Nom du ${itemName}`;

    return (
        <Container component="main">
            <Typography component="h1" variant="h5">{actionTitle} {itemName}</Typography>
            <Divider />
            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item >
                        <TextField
                            name={itemName}
                            required
                            onKeyPress={handleKeyPressed}
                            onChange={(event) => setName(event.target.value)}
                            fullWidth
                            value={name}
                            id={itemName}
                            label={labelValue}
                            autoFocus
                        />
                    </Grid>
                </Grid>
            </Box>
            <Button
                style={{ float: "right", margin: '10px' }}
                variant="outlined"
                color="primary"
                disabled={updating || creating}
                onClick={handleSimpleItem}>{actionTitle}</Button>
            {creationError && <p>{creationError.message}</p>}
            {updateError && <p>{updateError.message}</p>}
        </Container>
    );

}
export default SimpleItem;