import { Box, Button, TextField, InputAdornment, SvgIcon, Grid } from '@mui/material';
import { SearchIcon } from '../../Icons';
import { useState } from 'react';
import CUModal from '../modals/CUModal';
import CUPlace from "../CreateUpdateComponents/Place";

const PlaceListToolbar = ({ updateSearch, loadData, ...props }) => {

    const [openCUModal, setOpenCUModal] = useState(false);
    const handleClose = () => { setOpenCUModal(false); loadData(); }

    return (
        <>
            <CUModal open={openCUModal} handleClose={handleClose} component={CUPlace} />
            <Box {...props}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color="primary" variant="contained" onClick={() => setOpenCUModal(true)}>Ajouter</Button>
                </Box>
                <Box pb={4}>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Rechercher une salle"
                                variant="outlined"
                                onChange={updateSearch}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box >
        </>
    )
};

export default PlaceListToolbar;