import { Box, Container } from '@mui/material';
import { DELETE_GROUP, LIST_GROUPS } from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import GroupListResults from './GroupListResults';
import GroupListToolbar from './GroupListToolbar';
import { useSnackbar } from 'notistack';
import GroupCalendar from '../CalendarList/GroupCalendar';


function GroupList({ period }) {

    const { enqueueSnackbar } = useSnackbar();

    const [groups, setGroups] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [nameFilter, setNameFilter] = useState("");
    const [group, setGroup] = useState("");

    const [getGroups] = useLazyQuery(LIST_GROUPS, {
        onCompleted: (data) => {
            setGroups(data.allGroups.nodes);
            setTotalCount(data.allGroups.totalCount);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [deleteGroup] = useMutation(DELETE_GROUP, {
        onError: e => enqueueSnackbar("Impossible de supprimée le groupe"),
        onCompleted: d => enqueueSnackbar("Groupe supprimé")
    })

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const loadData = useCallback(() => {
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const filters = { ...nameF };
        const filter = {
            variables: {
                filter: filters, first: limit, offset
            }
        }
        getGroups(filter);
    }, [nameFilter, getGroups, limit, offset])

    const handleDeleteGroup = useCallback(async (groupUid) => {
        await deleteGroup({
            variables: { groupUid }
        });
        loadData();
    }, [deleteGroup, loadData]);

    useEffect(() => {
        loadData();
    }, [loadData])

    return (group.uid) ?
        <GroupCalendar group={group} handleClose={() => setGroup({})} period={period} /> : (
            <Container component="main">
                <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                    <Container maxWidth={false}>
                        <GroupListToolbar
                            loadData={loadData}
                            updateSearch={updateSearch}
                        />
                        <Box sx={{ pt: 3 }}>
                            <GroupListResults
                                viewGroupCalendar={setGroup}
                                groups={groups}
                                loadData={loadData}
                                limit={limit}
                                offset={offset}
                                totalCount={totalCount}
                                setLimit={setLimit}
                                setOffset={setOffset}
                                handleDeleteGroup={handleDeleteGroup} />
                        </Box>
                    </Container>
                </Box>
            </Container >
        )
};

export default GroupList;