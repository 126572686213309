import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { day_translate } from "../../../utils/translate";
import { inConflict, alreadyRegister } from '../../../utils/time'
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import moment from "moment";
import CustomIconButton from "../../AdminComponents/subcomponents/CustomIconButton"
import { ConflictIcon, HowToRegIcon, CancelIcon, MultipleCheckIcon} from "../../Icons"


const ActivityListResults = ({ onSubmit, onPaginate, activities, canRegister, calendar, totalCount, setLimit, limit, setOffset, offset, setPage, page, isSearchEnabled, handleRegisterActivity, handleUnregisterActivity, user, ...rest }) => {

    const [selectedActivityIds, setSelectedActivityIds] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [progress, setProgress] = useState(50);

    const handleSelectAll = (event) => {
        let newSelectedActivityIds;

        if (event.target.checked) {
            newSelectedActivityIds = activities.map((activity) => activity.uid);
        } else {
            newSelectedActivityIds = [];
        }

        setSelectedActivityIds(newSelectedActivityIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedActivityIds.indexOf(id);
        let newSelectedActivityIds = [];

        if (selectedIndex === -1) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds, id);
        } else if (selectedIndex === 0) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds.slice(1));
        } else if (selectedIndex === selectedActivityIds.length - 1) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedActivityIds = newSelectedActivityIds.concat(
                selectedActivityIds.slice(0, selectedIndex),
                selectedActivityIds.slice(selectedIndex + 1)
            );
        }

        setSelectedActivityIds(newSelectedActivityIds);
    };

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const registerAll = () => {
        setOpenDialog(true);
    }

    const confirmRegisterAll = async () => {
        setConfirmed(true);

        let i = 1;
        const tasks = selectedActivityIds.map(uid => [i++, () =>
            new Promise((res) => {
                setTimeout(async () => {
                    await handleRegisterActivity(uid)();
                    res("ok");
                }, 1000);
            })]);

        for (let [k, task] of tasks) {
            setProgress(100 / tasks.length * k)
            await task();
        };

        closeDialog();

    }

    const closeDialog = () => setOpenDialog(false);

    const handlePageChange = async (event, newPage) => {
       //setOffset(newPage * limit);
       // setPage(newPage);
        onPaginate(newPage, limit);
    };

    return (
        <div style={{ marginTop: "10px" }}>
            <Dialog
                open={openDialog}
                keepMounted
                onClose={closeDialog}
            >
                <DialogTitle>S'inscrire à plusieurs activités</DialogTitle>
                <DialogContent>
                    {confirmed ? <LinearProgress variant="determinate" value={progress} /> : <DialogContentText>Vous êtes sur le point de vous <strong>inscrire à {selectedActivityIds.length} activités</strong>.<br /> Etes vous sûr ?</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">Annuler</Button>
                    <Button onClick={confirmRegisterAll} color="primary">Confirmer</Button>
                </DialogActions>
            </Dialog>
            <Card {...rest}>
                {!isSearchEnabled && selectedActivityIds.length >= 2 && <Button startIcon={<MultipleCheckIcon />} onClick={registerAll}>S'inscrire pour tous</Button>}

                {/* <PerfectScrollbar> */}

                {(totalCount === 0) && <Box sx={{ p: 1, m: 1 }}><Typography>Il n'y a pas d'activité à afficher.<br />Veuillez lancer une nouvelle recherche.</Typography></Box>}

                {(isSearchEnabled || (totalCount === 0)) && <Box sx={{ p: 1, m: 1 }}><Button
                    onClick={onSubmit}
                    color="primary"
                    variant="contained"
                >Lancer la recherche</Button></Box>}

                {!isSearchEnabled && (totalCount > 0) && <Box sx={{ display: { xs: 'none', lg: 'block' } }} >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedActivityIds.length === activities.length}
                                        color="primary"
                                        indeterminate={
                                            selectedActivityIds.length > 0
                                            && selectedActivityIds.length < activities.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell size="small">Nom</TableCell>
                                <TableCell size="small">Jour</TableCell>
                                <TableCell size="small">Heures</TableCell>
                                <TableCell size="small">Intervenant</TableCell>
                                <TableCell size="small">Places</TableCell>
                                <TableCell size="small">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activities.slice(0, limit).map((activity) => {
                                const registered = alreadyRegister(activity, calendar);
                                const conflicts = inConflict(activity, calendar);
                                const conflictText = <div><strong>En conflit</strong><br />{conflicts.map(a => <p key={a.appointmentUid}>{a.title} - {a.startTime}:{a.endTime}</p>)}</div>;
                                return (
                                    <TableRow hover key={activity.uid} selected={selectedActivityIds.indexOf(activity.uid) !== -1}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedActivityIds.indexOf(activity.uid) !== -1}
                                                onChange={(event) => handleSelectOne(event, activity.uid)}
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell size="small">{activity.name}</TableCell>
                                        <TableCell size="small">{day_translate.FR[activity.day]}</TableCell>
                                        <TableCell size="small">{moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}</TableCell>
                                        <TableCell size="small">{activity.userByOrganizerUid && activity.userByOrganizerUid.firstName} {activity.userByOrganizerUid && activity.userByOrganizerUid.lastName}</TableCell>
                                        <TableCell size="small">{activity.remaining}/{activity.capacity}</TableCell>
                                        <TableCell size="small">
                                            {registered ?
                                                <Button size="small" startIcon={<CancelIcon />} disabled={!canRegister} onClick={handleUnregisterActivity(activity.uid)}>Se désinscrire</Button>
                                                :
                                                conflicts.length === 0 ?
                                                    <Button size="small"  startIcon={<HowToRegIcon />} disabled={!canRegister || activity?.remaining === '0'} onClick={handleRegisterActivity(activity.uid)}>S'inscrire</Button>
                                                    :
                                                    <CustomIconButton
                                                        title={conflictText}
                                                        icon={<ConflictIcon />} />
                                            }</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Box>
                }

                {!isSearchEnabled && (totalCount > 0) &&
                    <div>
                        <Box sx={{ display: { xs: 'block', lg: 'none' } }} >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedActivityIds.length === activities.length}
                                                color="primary"
                                                indeterminate={
                                                    selectedActivityIds.length > 0
                                                    && selectedActivityIds.length < activities.length
                                                }
                                                onChange={handleSelectAll}
                                            />
                                        </TableCell>
                                        <TableCell>Activité</TableCell>
                                        <TableCell size="small">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activities.slice(0, limit).map((activity) => {
                                        const registered = alreadyRegister(activity, calendar);
                                        const conflicts = inConflict(activity, calendar);
                                        const conflictText = <div><strong>En conflit</strong><br />{conflicts.map(a => <p key={a.appointmentUid}>{a.title} - {a.startTime}:{a.endTime}</p>)}</div>;
                                        return (
                                            <TableRow hover key={activity.uid} selected={selectedActivityIds.indexOf(activity.uid) !== -1}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={selectedActivityIds.indexOf(activity.uid) !== -1}
                                                        onChange={(event) => handleSelectOne(event, activity.uid)}
                                                        value="true"
                                                    />
                                                </TableCell>
                                                <TableCell size="small">
                                                    <strong>{activity.name}</strong><br />
                                                    {day_translate.FR[activity.day]}<br />
                                                    {moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}
                                                </TableCell>
                                                <TableCell size="small">
                                                    {registered ?
                                                        <Button sx={{whiteSpace: "nowrap"}} size="small" startIcon={<CancelIcon />} disabled={!canRegister} onClick={handleUnregisterActivity(activity.uid)}>Se désinscrire</Button>
                                                        :
                                                        conflicts.length === 0 ?
                                                            <Button size="small" startIcon={<HowToRegIcon />} disabled={!canRegister || activity?.remaining === '0'} onClick={handleRegisterActivity(activity.uid)}>S'inscrire</Button>
                                                            :
                                                            <CustomIconButton
                                                                title={conflictText}
                                                                icon={<ConflictIcon />} />
                                                    }</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                        <TablePagination
                            component="div"
                            count={totalCount}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleLimitChange}
                            page={page}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        />
                    </div>
                }
            </Card>
        </div >
    );
};

ActivityListResults.propTypes = {
    activities: PropTypes.array.isRequired
};

export default ActivityListResults;