import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { ClassroomIcon } from '../../Icons'
import { LIST_CLASSROOMS } from "../../../graphql/admin-requests";
import React, { useState } from 'react';
import { useQuery } from "@apollo/client";
import { useTheme } from '@mui/material/styles';

const Groups = (props) => {
    const [count, setCount] = useState(0);

    useQuery(LIST_CLASSROOMS, {
        onCompleted: (data) => { setCount(data.allClassrooms.totalCount) },
        fetchPolicy: 'cache-and-network'
    });

    const theme = useTheme();

    return (
        <Card sx={{ height: '100%' }} {...props} >
            <CardContent>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h6">CLASSES</Typography>
                        <Typography color="textPrimary" variant="h3">{count}</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar sx={{ backgroundColor: theme.palette.primary.main, height: 56, width: 56 }}>
                            <ClassroomIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default Groups;