import { Checkbox, FormControlLabel, Grid, Paper, Typography } from "@mui/material";


function GradesClassroomsSelect({ classroomOptions, classroomsState, setClassroomsState }) {

    const handleGradeCheckBoxes = (g) => (event) => {
        const newClassroomsState = [...classroomsState];
        const classroomsToCheck = newClassroomsState
            .filter(i => i.gradeUid === g.uid);
        classroomsToCheck.forEach(o => { o.checked = event.target.checked });
        setClassroomsState(newClassroomsState);
    }

    const handleClassroomCheckBoxes = c => event => {
        const newClassroomsState = [...classroomsState];
        const classroomToCheck = newClassroomsState
            .find(i => (i.uid === c.uid));

        classroomToCheck.checked = event.target.checked;
        setClassroomsState(newClassroomsState);
    }


    return classroomOptions.length > 0 && (<Paper variant="outlined">
        <Typography variant="subtitle2">Classes</Typography>
        {classroomOptions.filter(g => g.choices.length > 0).map(g =>
            <Grid container key={g.uid}>
                <Grid item xs={2}>
                    <FormControlLabel
                        key={g.uid}
                        control={
                            < Checkbox
                                checked={classroomsState.filter(c => c.gradeUid === g.uid).every(i => i.checked)}
                                onChange={handleGradeCheckBoxes(g)}
                                name={g.name}
                                value={g.uid}
                                color="primary"
                            />
                        }
                        style={{ fontWeight: 'bold' }}
                        label={(<strong>{g.name}</strong>)}
                    />
                </Grid>
                {g.choices.map(c =>
                (<Grid item xs={1} key={c.uid}>
                    <FormControlLabel
                        key={c.uid}
                        control={
                            < Checkbox
                                checked={classroomsState.find(i => i.uid === c.uid) && classroomsState.find(i => i.uid === c.uid).checked}
                                onChange={handleClassroomCheckBoxes(c)}
                                name={c.name}
                                value={c.uid}
                                color="secondary"
                            />
                        }
                        label={<Typography style={{ fontSize: "10px" }}>{c.name}</Typography>}
                    />
                </Grid>))}
            </Grid>)
        }
    </Paper>)
}

export default GradesClassroomsSelect;