import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import PlaceListConfirmDelete from './PlaceListConfirmDelete';
import { DeleteIcon } from '../../Icons';
import CustomIconButton from "../subcomponents/CustomIconButton";


const PlaceListResults = ({ places, totalCount, setLimit, limit, setOffset, offset, handleDeletePlace, ...rest }) => {

    const [selectedPlaceIds, setSelectedPlaceIds] = useState([]);
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [toDelete, setToDelete] = useState({});

    const handleSelectAll = (event) => {
        let newSelectedPlaceIds;

        if (event.target.checked) {
            newSelectedPlaceIds = places.map((activity) => activity.uid);
        } else {
            newSelectedPlaceIds = [];
        }

        setSelectedPlaceIds(newSelectedPlaceIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedPlaceIds.indexOf(id);
        let newSelectedPlaceIds = [];

        if (selectedIndex === -1) {
            newSelectedPlaceIds = newSelectedPlaceIds.concat(selectedPlaceIds, id);
        } else if (selectedIndex === 0) {
            newSelectedPlaceIds = newSelectedPlaceIds.concat(selectedPlaceIds.slice(1));
        } else if (selectedIndex === selectedPlaceIds.length - 1) {
            newSelectedPlaceIds = newSelectedPlaceIds.concat(selectedPlaceIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedPlaceIds = newSelectedPlaceIds.concat(
                selectedPlaceIds.slice(0, selectedIndex),
                selectedPlaceIds.slice(selectedIndex + 1)
            );
        }

        setSelectedPlaceIds(newSelectedPlaceIds);
    };

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const openConfirmDelete = (activity) => {
        setToDelete(activity);
        setOpenDialog(true);
    }

    const closeDialog = () => setOpenDialog(false);

    const handlePageChange = async (event, newPage) => {
        await setOffset(newPage * limit);
        await setPage(newPage);
    };

    return (
        <div>
            <PlaceListConfirmDelete
                open={openDialog}
                place={toDelete}
                onClose={closeDialog}
                onConfirm={() => handleDeletePlace(toDelete.uid)}
            />
            <Card {...rest}>
                {/* <PerfectScrollbar> */}
                <Box>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedPlaceIds.length === places.length}
                                        color="primary"
                                        indeterminate={
                                            selectedPlaceIds.length > 0
                                            && selectedPlaceIds.length < places.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell size="small">Nom</TableCell>
                                <TableCell size="small">Places</TableCell>
                                <TableCell size="small">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {places.slice(0, limit).map((activity) => (
                                <TableRow hover key={activity.uid} selected={selectedPlaceIds.indexOf(activity.uid) !== -1}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedPlaceIds.indexOf(activity.uid) !== -1}
                                            onChange={(event) => handleSelectOne(event, activity.uid)}
                                            value="true"
                                        />
                                    </TableCell>
                                    <TableCell size="small">{activity.name}</TableCell>
                                    <TableCell size="small">{activity.capacity}</TableCell>
                                    <TableCell size="small">
                                        <CustomIconButton
                                            onClick={() => openConfirmDelete(activity)}
                                            title="Supprimer"
                                            icon={<DeleteIcon />} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                {/* </PerfectScrollbar> */}
                <TablePagination
                    component="div"
                    count={totalCount}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </Card >
        </div >
    );
};

PlaceListResults.propTypes = {
    places: PropTypes.array.isRequired
};

export default PlaceListResults;