import React, { useState } from 'react';
import { Button, Box, Container, Divider, Grid, Typography } from '@mui/material';
import PasswordUpdate from "../AdminComponents/CreateUpdateComponents/PasswordUpdate";
import CUModal from './modals/CUModal';




function Profile({ user }) {

    const [openEditPasswordModal, setOpenEditPasswordModal] = useState(false);

    return (
        <Container component="main">
            <CUModal open={openEditPasswordModal} component={PasswordUpdate} handleClose={() => { setOpenEditPasswordModal(false); }} />

            <Typography component="h1" variant="h3">Mon profil</Typography>
            <Divider style={{ marginBottom: '20px' }} />
            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography component="h1" variant="h6">Nom</Typography>
                        {user.lastName}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography component="h1" variant="h6">Prénom</Typography>
                        {user.firstName}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button variant="outlined" onClick={() => { setOpenEditPasswordModal(true) }}>Modifier mon mot de passe</Button>
                    </Grid>

                </Grid>
            </Box>
        </Container >
    );

}
export default Profile;