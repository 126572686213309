import { Box, Button, Container, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { GET_CONCURENT_ACTIVITY_FOR_ACTIVITY } from "../../../graphql/admin-requests";
import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { formatActivityTime } from '../../../utils/time';
import ActivityConfirmUnregister from './ActivityConfirmUnregister';
import { useLazyQuery } from '@apollo/client';
import { DeleteIcon } from '../../Icons';

function ActivityConflicts({ activityToCheck }) {

    const [conflicts, setConflicts] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [toDelete, setToDelete] = useState({});

    const [getConflicts] = useLazyQuery(GET_CONCURENT_ACTIVITY_FOR_ACTIVITY, {
        onCompleted: (data) => {
            const res = data.getConcurentActivityForActivity.nodes;
            setConflicts(res);
        },
        fetchPolicy: "cache-and-network"
    });

    const loadData = useCallback(() => {
        const vars = { variables: { activityUid: activityToCheck.uid } };
        getConflicts(vars);
    }, [activityToCheck, getConflicts]);

    const formatActivity = act => {
        if (act) {
            return `${act.name} : ${formatActivityTime(act)}`
        } else {
            return `Activité introuvable...`;
        }
    }

    const handleSingleUnregister = (user, activity) => () => {
        setToDelete({ user, activity })
        setOpenConfirmDialog(true);
        console.log("On veut drop", user, activity);
    }

    useEffect(() => {
        if (activityToCheck.uid) {
            loadData();
        }
    }, [loadData, activityToCheck])

    return (
        <Container component="main" style={{ maxHeight: "90vh", overflowY: "auto" }}>
            {openConfirmDialog && <ActivityConfirmUnregister
                open={openConfirmDialog}
                user={toDelete.user}
                activity={toDelete.activity}
                onClose={async () => { await setOpenConfirmDialog(false); loadData(); }}
            />}
            <Typography component="h1" variant="h5">Activités en conflit avec {activityToCheck.name}</Typography>
            <Divider style={{ marginTop: '5px' }} />
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box >
                        {conflicts.length === 0 ?
                            "Pas d'activités en conflit pour cette activité" :
                            (<div>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small">Eleve</TableCell>
                                            <TableCell size="small">Activités en conflit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {conflicts.map((c, index) => (
                                            <Fragment key={c.userByUserUid.uid}>
                                                <TableRow hover style={index % 2 ? { background: "#DDDDDD" } : { background: "white" }} >
                                                    <TableCell size="small" rowSpan={2}>{c.userByUserUid && c.userByUserUid.lastName} {c.userByUserUid && c.userByUserUid.firstName} </TableCell>
                                                    <TableCell size="small">
                                                        <Button
                                                            size="small"
                                                            startIcon={<DeleteIcon />}
                                                            color="secondary"
                                                            variant="outlined"
                                                            onClick={handleSingleUnregister(c.userByUserUid, activityToCheck)}>
                                                            {formatActivity(activityToCheck)}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow hover style={index % 2 ? { background: "#DDDDDD" } : { background: "white" }} >
                                                    <TableCell size="small">
                                                        <Button
                                                            size="small"
                                                            color="secondary"
                                                            variant="outlined"
                                                            startIcon={<DeleteIcon />}
                                                            onClick={handleSingleUnregister(c.userByUserUid, c.activityByActivityUid)}>
                                                            {formatActivity(c.activityByActivityUid)}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            </Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>)}
                    </Box>
                </Container>
            </Box >
        </Container >
    )
};

export default ActivityConflicts;