import PropTypes from 'prop-types';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CustomIconButton from "../subcomponents/CustomIconButton";
import { DeleteIcon } from '../../Icons';



const UserByClassroomActivityListResults = ({ readOnly, onDelete, users, ...rest }) => {

    return (
        <Card {...rest}>
            {/* <PerfectScrollbar> */}
            <Box sx={{ minWidth: 1050 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell size="small">Nom</TableCell>
                            <TableCell size="small">Prenom</TableCell>
                            <TableCell size="small">Classe</TableCell>
                            {!readOnly && <TableCell size="small">Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow hover key={user.uid}>
                                <TableCell padding="checkbox"><Checkbox /></TableCell>
                                <TableCell size="small">{user.lastName}</TableCell>
                                <TableCell size="small">{user.firstName}</TableCell>
                                <TableCell size="small">{user.classroomByClassroomUid && user.classroomByClassroomUid.name}</TableCell>
                                {!readOnly &&
                                    <TableCell size="small">
                                        <CustomIconButton
                                            onClick={onDelete(user.uid)}
                                            title="Supprimer"
                                            icon={<DeleteIcon />} />
                                    </TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {/* </PerfectScrollbar> */}
        </Card>
    );
};

UserByClassroomActivityListResults.propTypes = {
    users: PropTypes.array.isRequired
};

export default UserByClassroomActivityListResults;