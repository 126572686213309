import {
    Box,
    Toolbar,
    Select,
    MenuItem,
    IconButton,
    Button,
    Divider,
    List,
    CssBaseline,
    Typography
} from '@mui/material';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';

import { Routes, Route, useNavigate, createSearchParams, useLocation } from "react-router-dom";


import CustomMenuItem from './subcomponents/CustomMenuItem';

import { AccountCircleIcon, AdminIcon, ClassroomIcon, ClassroomActivityIcon, ChevronLeftIcon, ChevronRightIcon, StageIcon, GroupIcon, PeriodIcon, StudentIcon, TeacherIcon, PlaceIcon, ActivityIcon, MenuIcon, DashboardIcon, GroupActivityIcon } from "../Icons"

import { useState, useEffect } from 'react';
import { useLazyQuery } from "@apollo/client";
import { GET_USER, LIST_PERIODS } from "../../graphql/admin-requests";
import jwt_decode from "jwt-decode";
import SuperAdminDashboard from './SuperAdminDashboard';
import UserList from "./UserList/UserList";
import ActivityList from './ActivityList/ActivityList';
import ClassroomActivityList from './ClassroomActivityList/ClassroomActivityList';
import GroupActivityList from './GroupActivityList/GroupActivityList';
import GroupList from './GroupList/GroupList';
import GradeList from './GradeList/GradeList';
import PlaceList from './PlaceList/PlaceList';
import PeriodList from './PeriodList/PeriodList';
import ClassroomList from './ClassroomList/ClassroomList';
import GroupCalendar from './CalendarList/GroupCalendar';
import ClassroomCalendar from './CalendarList/ClassroomCalendar';
import React from 'react';
import Profile from './Profile';
import PrintableActivityList from './ActivityList/PrintableActivityList';
import ProcessCalendarList from './ProcessCalendarList/ProcessCalendarList';
import { superAdminProcessCalendarMapping } from '../../utils/process';
import { periodAdminSort } from '../../utils/sort';
import { AppBar, Drawer, DrawerHeader } from '../../utils/commons';

function LoggedApp(props) {
    const urlSeachParams = new URLSearchParams(useLocation().search)
    const navigate = useNavigate();
    const [period, setPeriod] = useState("");
    let [open, setOpen] = useState(true);
    let [user, setUser] = useState({});
    let [periods, setPeriods] = useState([]);

    function handleLogout() {
        localStorage.removeItem("HorarojJWTToken");
        setUser(null);
        props.setLogged(false);
        navigate("/");
    }

    function switchOpen() {
        setOpen(!open);
    }

    function switchOpenIfMobile() {
        ['xs', 'sm', 'md'].includes(props.width) && switchOpen();
    }

    const [getUser] = useLazyQuery(GET_USER, {
        onCompleted: (data) => { setUser(data.userByUid); }
    });

    const updatePeriod = newPeriod => {
        navigate({
            pathname: ".",
            search: createSearchParams({
                periodUid: newPeriod.uid
            }).toString(),
        }, {
            relative: "path"
        })
        selectPeriod(periods, newPeriod.uid);
    }

    const selectPeriod = (periods, periodUid) => {
        if (periodUid) {
            let p = periods.find(p => p.uid === periodUid);
            if (p) {
                setPeriod(p);
            }
        } else {
            const firstPeriod = [...periods].sort(periodAdminSort)[0];
            if (firstPeriod) { setPeriod(firstPeriod) }
        }
    }

    const [getPeriods] = useLazyQuery(LIST_PERIODS, {
        onCompleted: (data) => {
            setPeriods(data.allPeriods.nodes);
            selectPeriod(data.allPeriods.nodes, urlSeachParams.get("periodUid"));
        },
        onError: (err) => { console.log("ERROR", err) }
    });

    const theme = createTheme(useTheme(), {
        palette: {
            primary: {
                main: "#4059AD",
                contrastText: "#FFFFFF",
                activityFree: "#DE6E4B",
                activityRegister: "#66A182",
                validationUser: "#c1d9cd",
                validationAdmin: "#a3c6b4",
                validationSuperAdmin: "#84b39b"
            }
        },
    });


    useEffect(() => {
        const token = localStorage.getItem("HorarojJWTToken");
        if (token) {
            const decodedToken = jwt_decode(token);
            getPeriods({});
            getUser({ variables: { userUid: decodedToken.user_uid } });
        }
    }, [getUser, getPeriods]);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open} color="primary">
                    <Toolbar>
                        <IconButton
                            onClick={switchOpen}
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="div" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <img src="/assets/img/saas-c/logo/logo-wiiik.png" alt="Logo Wiiik" /> {user && user.firstName} {user && user.lastName}
                        </Typography>

                        <Button onClick={handleLogout} color="inherit">Se déconnecter</Button>
                    </Toolbar>
                </AppBar>
                <Drawer variant={['xs', 'sm', 'md'].includes(props.width) ? "temporary" : "permanent"} open={open}>
                    <DrawerHeader>
                        <IconButton onClick={switchOpen}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <Select size="small" onChange={event => updatePeriod(event.target.value)} value={period} >
                        {periods.map(p => (<MenuItem key={p.uid} value={p}>{p.name}</MenuItem>))}
                    </Select>
                    <List>
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Tableau de bord" link={{ pathname: "/superadmins/dashboard", search: useLocation().search }} open={open} icon={<DashboardIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Activités" link={{ pathname: "/superadmins/activities", search: useLocation().search }} open={open} icon={<ActivityIcon />} />
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Cours" link={{ pathname: "/superadmins/classroomActivities", search: useLocation().search }} open={open} icon={<ClassroomActivityIcon />} />
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Options" link={{ pathname: "/superadmins/groupActivities", search: useLocation().search }} open={open} icon={<GroupActivityIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Groupes" link={{ pathname: "/superadmins/groups", search: useLocation().search }} open={open} icon={<GroupIcon />} />
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Niveaux" link={{ pathname: "/superadmins/grades", search: useLocation().search }} open={open} icon={<StageIcon />} />
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Classes" link={{ pathname: "/superadmins/classrooms", search: useLocation().search }} open={open} icon={<ClassroomIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Périodes" link={{ pathname: "/superadmins/periods", search: useLocation().search }} open={open} icon={<PeriodIcon />} />
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Salles" link={{ pathname: "/superadmins/places", search: useLocation().search }} open={open} icon={<PlaceIcon />} />
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Eleves" link={{ pathname: "/superadmins/users", search: useLocation().search }} open={open} icon={<StudentIcon />} />
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Intervenants" link={{ pathname: "/superadmins/admins", search: useLocation().search }} open={open} icon={<TeacherIcon />} />
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Administrateurs" link={{ pathname: "/superadmins/superadmins", search: useLocation().search }} open={open} icon={<AdminIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <CustomMenuItem onClick={switchOpenIfMobile} title="Mon profil" link={{ pathname: "/superadmins/profile", search: useLocation().search }} open={open} icon={<AccountCircleIcon />} />
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <Routes>
                        <Route path="/" exact element={<SuperAdminDashboard period={period} />} />
                        <Route path="/superadmins/dashboard" element={<SuperAdminDashboard period={period} />} />
                        <Route path="/superadmins/activities" element={<ActivityList period={period} />} />
                        <Route path="/superadmins/profile" element={<Profile user={user} />} />
                        <Route path="/superadmins/places" element={<PlaceList />} />
                        <Route path="/superadmins/grades" element={<GradeList />} />
                        <Route path="/superadmins/classrooms" exact element={<ClassroomList period={period} />} />
                        <Route path="/superadmins/users" element={<UserList role="users" period={period} />} />
                        <Route path="/superadmins/admins" element={<UserList role="admins" />} />
                        <Route path="/superadmins/superadmins" element={<UserList role="superadmins" />} />
                        <Route path="/superadmins/periods" element={<PeriodList />} />
                        <Route path="/superadmins/groups" element={<GroupList period={period} />} />
                        <Route path="/superadmins/calendars/group/:groupUid" element={<GroupCalendar period={period} />} />
                        <Route path="/superadmins/groupActivities" element={<GroupActivityList period={period} />} />
                        <Route path="/superadmins/classroomActivities" element={<ClassroomActivityList period={period} />} />
                        <Route path="/superadmins/calendars/classroom/:classroomUid" element={<ClassroomCalendar period={period} />} />
                        <Route path="/superadmins/classrooms/:classroomUid/process" element={<ProcessCalendarList period={period} mapping={superAdminProcessCalendarMapping} role="horaroj_super_admin" />} />
                        <Route path="/superadmins/print-activities" element={<PrintableActivityList period={period} />} />
                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default LoggedApp;