import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';

const CustomAppointmentContent = (props) => {
    const data = props.data;
    return <Appointments.AppointmentContent {...props}>
        <div>
            <div style={{
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>{data.title} - {props.formatDate(data.startDate, { hour: 'numeric', minute: 'numeric' })} - {props.formatDate(data.endDate, { hour: 'numeric', minute: 'numeric' })}</div>
            <div style={{
                lineHeight: 1,
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}>
                {data.place && <div>Salle: {data.place}</div>}
                {data.organizer && data.organizer !== ' ' && <div>{data.organizer}</div>}
            </div>
        </div>
    </Appointments.AppointmentContent>
}

export default CustomAppointmentContent;