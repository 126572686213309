import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { PeriodIcon } from '../../Icons'
import { LIST_PERIODS } from "../../../graphql/admin-requests";
import React, { useState } from 'react';
import { useQuery } from "@apollo/client";
import { useTheme } from '@mui/material/styles';

const Periods = (props) => {

    const [count, setCount] = useState(0);
    const theme = useTheme();

    useQuery(LIST_PERIODS, {
        onCompleted: (data) => { setCount(data.allPeriods.totalCount) },
        fetchPolicy: 'cache-and-network'
    });
    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardContent>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h6">PERIODES</Typography>
                        <Typography color="textPrimary" variant="h3">{count}</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar sx={{ backgroundColor: theme.palette.primary.main, height: 56, width: 56 }}>
                            <PeriodIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default Periods;