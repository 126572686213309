import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from "@apollo/client";
import { day_translate } from "../../../utils/translate";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Box, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import ClassroomActivityListConfirmDelete from './ClassroomActivityListConfirmDelete';
import { DeleteIcon, EditIcon, StudentIcon } from "../../Icons";
import { COPY_ALL_CLASSROOM_ACTIVITIES, LIST_PERIODS } from '../../../graphql/admin-requests';
import CUClassroomActivity from "../CreateUpdateComponents/ClassroomActivity";
import UserByClassroomActivityList from '../UserByClassroomActivity/UserByClassroomActivityList';
import CUModal from '../modals/CUModal';
import moment from "moment";
import CustomIconButton from "../subcomponents/CustomIconButton";
import { useSnackbar } from 'notistack';
import { castPeriodDate } from "../../../utils/time";


const ClassroomActivityListResults = ({ readOnly, period, activities, totalCount, setLimit, limit, setOffset, offset, handleDeleteActivity, loadData, ...rest }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [selectedActivityIds, setSelectedActivityIds] = useState([]);
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [openCopyDialog, setOpenCopyDialog] = useState(false);
    const [toDelete, setToDelete] = useState({});

    const [confirmed, setConfirmed] = useState(false);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [activityToUpdate, setActivityToUpdate] = useState({});

    const [openUserListModal, setOpenUserListModal] = useState(false);
    const [classroomUid, setClassroomUid] = useState("");
    const [classroomActivityUid, setClassroomActivityUid] = useState("");

    const [periods, setPeriods] = useState([]);
    const [periodToCopy, setPeriodToCopy] = useState("");

    const handleSelectAll = (event) => {
        let newSelectedActivityIds;

        if (event.target.checked) {
            newSelectedActivityIds = activities.map((activity) => activity.uid);
        } else {
            newSelectedActivityIds = [];
        }

        setSelectedActivityIds(newSelectedActivityIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedActivityIds.indexOf(id);
        let newSelectedActivityIds = [];

        if (selectedIndex === -1) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds, id);
        } else if (selectedIndex === 0) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds.slice(1));
        } else if (selectedIndex === selectedActivityIds.length - 1) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedActivityIds = newSelectedActivityIds.concat(
                selectedActivityIds.slice(0, selectedIndex),
                selectedActivityIds.slice(selectedIndex + 1)
            );
        }

        setSelectedActivityIds(newSelectedActivityIds);
    };

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const openConfirmDelete = (activity) => {
        setToDelete(activity);
        setOpenDialog(true);
    }

    const closeDialog = () => setOpenDialog(false);
    const closeCopyDialog = () => setOpenCopyDialog(false);

    const showUserListModal = (classroomUid, classroomActivityUid) => {
        setClassroomUid(classroomUid);
        setClassroomActivityUid(classroomActivityUid);
        setOpenUserListModal(true);
    }

    const handleUserListModalClose = () => {
        setOpenUserListModal(false);
    }

    const handlePageChange = async (event, newPage) => {
        await setOffset(newPage * limit);
        await setPage(newPage);
    };

    const [copyAllClassromActivities] = useMutation(COPY_ALL_CLASSROOM_ACTIVITIES, {
        onCompleted: (data) => {
            const nb = data.copyAllClassroomActivities.integer;
            enqueueSnackbar(`${nb} cours copiés`, { variant: "success" });
            return Promise.resolve("Cours copiés");
        },
        onError: (error) => {
            enqueueSnackbar("Impossible de copier les cours", { variant: "error" })
            return Promise.reject("Impossible de copier les cours");
        },
    });

    const confirmCopyAll = async () => {
        setConfirmed(true);
        await copyAllClassromActivities({ variables: { sourcePeriodUid: period.uid, targetPeriodUid: periodToCopy } });
        loadData();
        closeCopyDialog();
        setConfirmed(false);
    }

    const [listPeriods] = useLazyQuery(LIST_PERIODS, {
        onCompleted: (data) => setPeriods(data.allPeriods.nodes),
        onError: (error) => console.error("Error requesting list of periods", error),
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        // Retrieve all periods
        if (openCopyDialog) { listPeriods(); }
    }, [openCopyDialog, listPeriods]);

    return (
        <div>
            <ClassroomActivityListConfirmDelete
                open={openDialog}
                activity={toDelete}
                onClose={closeDialog}
                onConfirm={() => handleDeleteActivity(toDelete.uid)}
            />
            <CUModal open={openUserListModal} classroomUid={classroomUid} readOnly={readOnly} classroomActivityUid={classroomActivityUid} handleClose={handleUserListModalClose} component={UserByClassroomActivityList} />
            <CUModal open={openEditModal} component={CUClassroomActivity} handleClose={() => { setOpenEditModal(false); loadData() }} toUpdate={activityToUpdate} />
            {openCopyDialog &&
                <Dialog
                    open={openCopyDialog}
                    keepMounted
                    onClose={closeCopyDialog}
                >
                    <DialogTitle>Copier les cours</DialogTitle>
                    <DialogContent>
                        {confirmed ?
                            <LinearProgress variant="indeterminate" /> :
                            <>
                                <DialogContentText>
                                    Selectionnez la période dans laquelle vous souhaitez <strong>copier tous les cours</strong>.<br />
                                </DialogContentText>

                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="period">Periode</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelid="period"
                                        label="Période"
                                        id="period"
                                        value={periodToCopy}
                                        onChange={(event) => setPeriodToCopy(event.target.value)}
                                    >
                                        {periods.map(p => <MenuItem key={p.uid} value={p.uid}>{p.name} ({castPeriodDate(p.startDate)} - {castPeriodDate(p.endDate)})</MenuItem>)}

                                    </Select>
                                </FormControl>
                            </>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeCopyDialog} color="primary">Annuler</Button>
                        <Button onClick={confirmCopyAll} color="primary">Confirmer</Button>
                    </DialogActions>
                </Dialog>}
            <Card sx={{ display: { xs: 'none', lg: 'block' } }} {...rest}>
                {!readOnly && selectedActivityIds.length > 0 && (selectedActivityIds.length === limit || selectedActivityIds.length === totalCount) && <Button onClick={() => setOpenCopyDialog(true)}>Copier tous les cours</Button>}
                <PerfectScrollbar>
                    <Box>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedActivityIds.length > 0 && selectedActivityIds.length === activities.length}
                                            color="primary"
                                            indeterminate={
                                                selectedActivityIds.length > 0
                                                && selectedActivityIds.length < activities.length
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell size="small">Nom</TableCell>
                                    <TableCell size="small">Jour</TableCell>
                                    <TableCell size="small">Heures</TableCell>
                                    <TableCell size="small">Classe</TableCell>
                                    <TableCell size="small">Organisateur</TableCell>
                                    <TableCell size="small">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {activities.slice(0, limit).map((activity) => (
                                    <TableRow hover key={activity.uid} selected={selectedActivityIds.indexOf(activity.uid) !== -1}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedActivityIds.indexOf(activity.uid) !== -1}
                                                disabled
                                                onChange={(event) => handleSelectOne(event, activity.uid)}
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell size="small">{activity.name}</TableCell>
                                        <TableCell size="small">{day_translate.FR[activity.day]}</TableCell>
                                        <TableCell size="small">{moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}</TableCell>
                                        <TableCell size="small">{activity.classroomByClassroomUid.name}</TableCell>
                                        <TableCell size="small">{activity.userByOrganizerUid ? (`${activity.userByOrganizerUid.firstName} ${activity.userByOrganizerUid.lastName}`) : ''}</TableCell>
                                        <TableCell size="small">
                                            <CustomIconButton
                                                title="Elèves"
                                                button={<Button
                                                    onClick={() => showUserListModal(activity.classroomByClassroomUid.uid, activity.uid)}
                                                    size="small"
                                                    variant="outlined"
                                                    color="default"
                                                    endIcon={<StudentIcon />}
                                                >{activity.classroomByClassroomUid?.usersByClassroomUid?.totalCount}</Button>} />
                                            {!readOnly && <CustomIconButton
                                                onClick={() => { setActivityToUpdate(activity); setOpenEditModal(true); }}
                                                title="Modifier"
                                                icon={<EditIcon />} />}
                                            {!readOnly && <CustomIconButton
                                                onClick={() => openConfirmDelete(activity)}
                                                title="Supprimer"
                                                icon={<DeleteIcon />} />}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </PerfectScrollbar>
                <TablePagination
                    component="div"
                    count={totalCount}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </Card >
            <Card sx={{ display: { xs: 'block', lg: 'none' } }}>
                <Table size="small">
                    <TableBody>
                        {activities.slice(0, limit).map((activity) => (
                            <TableRow hover key={activity.uid} selected={selectedActivityIds.indexOf(activity.uid) !== -1}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedActivityIds.indexOf(activity.uid) !== -1}
                                        onChange={(event) => handleSelectOne(event, activity.uid)}
                                        value="true"
                                    />
                                </TableCell>
                                <TableCell>
                                    <strong>{activity.name}</strong><br />
                                    {day_translate.FR[activity.day]} : {moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}<br />
                                    Places : {activity.taken} / {activity.capacity}<br />
                                    {activity.userByOrganizerUid ? (`Responsable : ${activity.userByOrganizerUid.firstName} ${activity.userByOrganizerUid.lastName}`) : ''}<br />
                                    Classe : {activity.classroomByClassroomUid.name}
                                </TableCell>
                                <TableCell>
                                    <CustomIconButton
                                        title="Elèves"
                                        button={<Button
                                            onClick={() => showUserListModal(activity.classroomByClassroomUid.uid, activity.uid)}
                                            size="small"
                                            variant="outlined"
                                            color="default"
                                            endIcon={<StudentIcon />}
                                        >{activity.classroomByClassroomUid?.usersByClassroomUid?.totalCount}</Button>} />
                                    <CustomIconButton
                                        onClick={() => { setActivityToUpdate(activity); setOpenEditModal(true); }}
                                        title="Modifier"
                                        icon={<EditIcon />} />
                                    <CustomIconButton
                                        onClick={() => openConfirmDelete(activity)}
                                        title="Supprimer"
                                        icon={<DeleteIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
        </div >
    );
};

ClassroomActivityListResults.propTypes = {
    activities: PropTypes.array.isRequired
};

export default ClassroomActivityListResults;