import { Box, Button, TextField, InputAdornment, SvgIcon, Grid } from '@mui/material';
import { SearchIcon } from "../../Icons"
import Select from "react-select";
import CUModal from '../modals/CUModal';
import { useState } from 'react';
import CUGroupActivity from "../CreateUpdateComponents/GroupActivity";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment }from '@mui/x-date-pickers/AdapterMoment';

import moment from "moment";



const GroupActivityListToolbar = ({
    readOnly,
    period,
    loadData,
    daysOptions,
    groupsOptions,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    updateGroupFilter,
    updateDayFilter,
    updateSearch,
    ...props }) => {

    const [openCUModal, setOpenCUModal] = useState(false);
    const handleClose = () => { setOpenCUModal(false); loadData(); }


    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale("fr")}>

            <CUModal open={openCUModal} handleClose={handleClose} period={period} component={CUGroupActivity} />
            <Box {...props}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!readOnly && <Button color="primary" variant="contained" onClick={() => setOpenCUModal(true)}>Ajouter</Button>}
                </Box>
                <Box pb={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                size="small"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Rechercher une option"
                                variant="outlined"
                                onChange={updateSearch}
                            />
                        </Grid>
                        <Grid item xs={false} md={false} lg={6} />
                        <Grid item xs={12} md={12} lg={3}>
                            <TimePicker
                                fullWidth
                                invalidDateMessage="Format d'heure invalide"
                                label="Démarre après"
                                inputVariant="outlined"
                                minutesStep={5}
                                size="small"
                                value={startTime}
                                ampm={false}
                                renderInput={(props) => <TextField {...props} size="small" fullWidth />}
                                onChange={setStartTime}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={3}>
                            <TimePicker
                                fullWidth
                                invalidDateMessage="Format d'heure invalide"
                                minutesStep={5}
                                label="Termine avant"
                                inputVariant="outlined"
                                size="small"
                                value={endTime}
                                ampm={false}
                                renderInput={(props) => <TextField {...props} size="small" fullWidth />}
                                onChange={setEndTime}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} />
                        {groupsOptions.length === 0 ? <Grid item xs={12} md={12} lg={6} /> : (
                            <Grid item xs={12} md={12} lg={3}>
                                <Select
                                    minMenuHeight={1000}
                                    placeholder="Groupes..."
                                    noOptionsMessage={() => "Pas d'autres groupes disponibles"}
                                    isClearable={true}
                                    isSearchable={true}
                                    defaultValue={[]}
                                    name="day"
                                    isMulti
                                    onChange={updateGroupFilter}
                                    options={groupsOptions}
                                />
                            </Grid>)}

                        <Grid item xs={12} md={12} lg={3}>
                            <Select
                                minMenuHeight={1000}
                                placeholder="Jours..."
                                noOptionsMessage={() => "Pas d'autres jours disponibles"}
                                isClearable={true}
                                isSearchable={true}
                                defaultValue={[]}
                                name="day"
                                isMulti
                                onChange={updateDayFilter}
                                options={daysOptions}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box >
        </LocalizationProvider>
    )
};

export default GroupActivityListToolbar;