import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context'
import LoginForm from './components/NotLoggedComponents/LoginForm';
import SuperAdminApp from './components/AdminComponents/SuperAdminApp';
import AdminApp from './components/AdminComponents/AdminApp';
import UserApp from './components/UserComponents/UserApp';
import jwt_decode from "jwt-decode";
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import SuperAdminUserApp from './components/AdminComponents/SuperAdminUserApp';
import Home from './components/NotLoggedComponents/Home';


const postgraphileUrl = process.env.REACT_APP_POSTGRAPHILE_ENDPOINT;
const httpLink = createHttpLink({ uri: postgraphileUrl });


const isTokenValid = () => {
  const token = localStorage.getItem("HorarojJWTToken");
  if (token) {
    const decodedToken = jwt_decode(token);
    if (new Date(decodedToken?.exp * 1000) > new Date()) {
      return true;
    } else {
      localStorage.removeItem("HorarojJWTToken");
    }
  }
  return false;
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('HorarojJWTToken');
  return isTokenValid() ? {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    }
  } : headers
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  fetchOptions: {
    mode: 'no-cors'
  }
});

function LoggedApp({ setLogged }) {
  if(!isTokenValid()){
    return <Home />;
  }
  const token = localStorage.getItem("HorarojJWTToken");
  const decodedToken = jwt_decode(token);

  switch (decodedToken.role) {
    case "horaroj_admin":
      return <AdminApp setLogged={setLogged} />;
    case "horaroj_super_admin":
      return <SuperAdminApp setLogged={setLogged} />;
    case "horaroj_user":
      return <UserApp setLogged={setLogged} />;
    default:
      return "ERROR";
  }
}

function AdminUserApp({ setLogged }) {
  if(!isTokenValid()){
    return <Home />;
  }
  const token = localStorage.getItem("HorarojJWTToken");
  const decodedToken = jwt_decode(token);
  switch (decodedToken.role) {
    case "horaroj_super_admin":
      return <SuperAdminUserApp />;
    default:
      return "ERROR";
  }
}

const theme = createTheme({
  palette: {
    primary: { main: "#2196f3" },
    secondary: { main: "#f50057" },
    default: { main: "#444444" },
    superadmin: { main: "#f50057", contrastText: "#FFFFFF" },
    admin: { main: "#55BB55", contrastText: "#FFFFFF" },
    user: { main: "#2196f3", contrastText: "#FFFFFF" }
  },
}, frFR);



function App() {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    if (isTokenValid()) {
      setLogged(true);
    } else {
      setLogged(false);
    }
  }, [logged]);

  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={10}>
          <Router>
            <Routes>
              <Route path="/userApp/:userUid/:periodUid/*" element={<AdminUserApp />} />
              <Route path="/*" element={<LoggedApp setLogged={setLogged} />} />
              <Route path="/login" element={<LoginForm setLogged={setLogged} />} />
            </Routes>
          </Router>
        </SnackbarProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;