import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Grid, Paper, Typography } from '@mui/material';

import {
    Scheduler,
    WeekView,
    Appointments
} from '@devexpress/dx-react-scheduler-material-ui';
import { currentDate } from "../../../utils/time";
import CustomAppointment from '../subcomponents/CustomAppointment';
import { ViewState } from '@devexpress/dx-react-scheduler';
import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import CustomIconButton from '../subcomponents/CustomIconButton';
import { PrintIcon, HighlightIcon } from '../../Icons';
import Select from "react-select";
import CustomAppointmentContent from '../../CommonComponents/CustomAppointmentContent';



const appointmentComponent = CustomAppointment(/*(data) => () => console.log("DELETE", data)*/);

const PrintableCalendarList = ({ users, loadingItem, item, loadingAppointments, appointments, handleClose }) => {

    const [openDialog, setOpenDialog] = useState(false);
    const [appointmentNames, setAppointmentNames] = useState([]);
    const [toHighlight, setToHighlight] = useState([]);
    const [appointmentsToShow, setAppointmentsToShow] = useState([]);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media all {
            .page-break {
              display: none;
            }
          }
          
          @media print {
            html, body {
              height: initial !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
            }
          }
          
          @media print {
            .page-break {
              display: block;
              page-break-before: always;
            }
          }
          
          @page {
            size: auto;
            margin: 20mm;
          }
        `
    });

    const handleOpenDialog = () => {
        const names = appointments.map(i => i.title);
        const uniqueNames = names.filter((v, i, a) => a.indexOf(v) === i).map(i => { return { label: i, value: i } });
        setAppointmentNames(uniqueNames);
        setOpenDialog(true);
    }

    const handleHighlight = () => {
        const nameH = toHighlight.map(i => i.label);
        setOpenDialog(false);
        const res = appointments.map(i => {
            if (nameH.find(n => n === i.title)) { return { ...i, customColor: "#ad2e24" } }
            else { return { ...i } }
        });
        setAppointmentsToShow(res);
    }

    useEffect(() => {
        setAppointmentsToShow(appointments);
    }, [appointments])

    return (
        <>
            <Dialog
                open={openDialog}
                keepMounted
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Mettre en surbrillance</DialogTitle>
                <DialogContent style={{ minHeight: "50vh" }}>
                    <DialogContentText>Selectionner les noms à mettre en surbrillance</DialogContentText>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <Select
                                minMenuHeight={1000}
                                placeholder="Noms..."
                                isClearable={true}
                                isSearchable={true}
                                defaultValue={[]}
                                name="day"
                                isMulti
                                onChange={setToHighlight}
                                options={appointmentNames}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">Annuler</Button>
                    <Button onClick={handleHighlight} color="primary">Confirmer</Button>
                </DialogActions>
            </Dialog>
            <Container component="main">
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomIconButton title="Mettre en surbrillance" onClick={handleOpenDialog} icon={<HighlightIcon />}>Mot</CustomIconButton>
                    <CustomIconButton title="Imprimer les emplois du temps" onClick={handlePrint} icon={<PrintIcon />}>Imprimer</CustomIconButton>
                </Box>

                {loadingItem ? <CircularProgress /> : <Typography component="h1" variant="h3" color="inherit" noWrap>{`Emplois du temps - ${item.name}`}</Typography>}

                <div ref={componentRef}>
                    {loadingAppointments && <CircularProgress />}
                    {
                        !loadingAppointments && users.length > 0 && users.map(u => {
                            const userGroups = u.userGroupsByUserUid.nodes.map(g => g.groupByGroupUid.uid);
                            const classroomActivities = appointmentsToShow.filter(a => (a.type === "classroomActivity" && a.itemUid === u.classroomUid))
                            const userClassroomActivities = appointmentsToShow.filter(a => (a.type === "userClassroomActivity" && a.itemUid === u.uid))
                            const groupActivities = appointmentsToShow.filter(a => (a.type === "groupActivity" && userGroups.includes(a.itemUid)));
                            const activities = appointmentsToShow.filter(a => (a.type === "activity" && a.itemUid === u.uid));
                            return (
                                <Paper className="page-break" key={u.uid} style={{ margin: "10px", height: `calc(100% - 10px)` }}>
                                    <Typography style={{ padding: 10 }} component="h1" variant="h6" color="inherit" noWrap>{`${u.lastName} ${u.firstName}`}</Typography>
                                    <Scheduler data={[...classroomActivities, ...userClassroomActivities, ...groupActivities, ...activities]} locale="fr">
                                        <ViewState currentDate={currentDate} />
                                        <WeekView
                                            excludedDays={[0, 6]}
                                            cellDuration={45}
                                            startDayHour={8}
                                            endDayHour={19} />
                                        <Appointments
                                            appointmentContentComponent={CustomAppointmentContent}
                                            appointmentComponent={appointmentComponent} />
                                    </Scheduler>
                                </Paper>
                            )
                        })
                    }
                </div>
            </Container >
        </>
    );
}

export default PrintableCalendarList;