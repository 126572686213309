import React, { useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CREATE_CLASSROOM, LIST_GRADES } from "../../../graphql/admin-requests";
import { useSnackbar } from 'notistack';



function Classroom({ onFinish }) {
    const { enqueueSnackbar } = useSnackbar();


    let [name, setName] = useState("");
    let [grades, setGrades] = useState([]);
    let [selectedGrade, setSelectedGrade] = useState("");


    const [createClassroom, { loadingClassroom, error }] = useMutation(CREATE_CLASSROOM, {
        onCompleted: (data) => {
            onFinish();
        },
        onError: (error) => enqueueSnackbar("Impossible de créer la classe")
    });

    async function handleClassroom(event) {
        event.preventDefault();
        await createClassroom({ variables: { name, gradeUid: selectedGrade.uid } });
    }

    useQuery(LIST_GRADES, {
        onCompleted: (data) => { setGrades(data.allGrades.nodes) },
        onError: (error) => console.error("Error requesting list of users", error),
        fetchPolicy: 'cache-and-network'
    })

    return (
        <Container component="main">
            <Typography component="h1" variant="h5">Nouvelle classe</Typography>
            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="grade">Niveau</InputLabel>
                            <Select
                                fullWidth={true}
                                label="Niveau"
                                labelid="grade"
                                id="grade"
                                value={selectedGrade}
                                onChange={e => setSelectedGrade(e.target.value)}>
                                {grades.map(g => <MenuItem key={g.uid} value={g}>{g.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="classroom"
                            required
                            onChange={(event) => setName(event.target.value)}
                            fullWidth
                            value={name}
                            id="classroom"
                            label="Nom de la classe"
                            autoFocus
                        />
                    </Grid>
                </Grid>
            </Box>
            <Button disabled={loadingClassroom} onClick={handleClassroom}>Ajouter une classe</Button>
            {error && <p>{error.message}</p>}
        </Container>
    );

}
export default Classroom;