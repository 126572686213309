import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { StudentIcon } from '../../Icons';
import { LIST_USERS } from "../../../graphql/admin-requests";
import React, { useState } from 'react';
import { useQuery } from "@apollo/client";
import { useTheme } from '@mui/material/styles';


const Users = (props) => {

    const [count, setCount] = useState(0);
    const theme = useTheme();

    useQuery(LIST_USERS, {
        onCompleted: (data) => { setCount(data.allUsers.totalCount) },
        fetchPolicy: 'cache-and-network'
    });

    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardContent>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h6">ELEVES</Typography>
                        <Typography color="textPrimary" variant="h3">{count}</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar sx={{ backgroundColor: theme.palette.primary.main, height: 56, width: 56 }}>
                            <StudentIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default Users;