import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Box, Card, CardContent, CardHeader, Divider, Grid, colors } from '@mui/material';
import { STATS_PROCESS_CALENDAR, LIST_GRADES_CLASSROOMS_USERS_BY_PERIOD } from "../../../graphql/admin-requests";
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from "@apollo/client";
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
//import { HourGlassIcon, AdminIcon, StudentIcon, TeacherIcon, CheckIcon } from '../../Icons';

ChartJS.register(ArcElement, Tooltip, Legend);

const ProcessCalendarStats = ({ period, ...props }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [classrooms, setClassrooms] = useState([])
    const [users, setUsers] = useState([]);

    const [waitSuperAdmin, setWaitSuperAdmin] = useState(0);
    const [waitAdmin, setWaitAdmin] = useState(0);
    const [waitUser, setWaitUser] = useState(0);
    const [waiting, setWaiting] = useState(0);
    const [approved, setApproved] = useState(0);
    //const [total, setTotal] = useState(0);

    const theme = useTheme();

    const data = {
        datasets: [{
            label: "Nombre de parcours",
            data: [waitSuperAdmin, waitAdmin, waitUser, approved, waiting],
            backgroundColor: [theme.palette.primary.validationSuperAdmin, theme.palette.primary.validationAdmin, theme.palette.primary.validationUser, theme.palette.primary.activityRegister, theme.palette.primary.activityFree],
            borderWidth: 3,
            borderColor: colors.common.white,
            hoverBorderColor: colors.common.white
        }],
        labels: ['Administrateur', 'Intervenant', 'Eleve', 'Validé', 'Non validé']
    };

    const options = {
        plugins: {
            legend: { display: true },
            tooltip: {
                backgroundColor: theme.palette.background.paper,
                bodyColor: "black",
                borderColor: theme.palette.divider,
                borderWidth: 1,
                enabled: true
            }
        },
        animation: { duration: 1000 },
        maintainAspectRatio: false,
        responsive: true,
    };


    // Fixme ARE : Commented because no way to group result by user.id
    // Meaning : We can have multiple ProcessCalendar for a single userId/state
    // Meaning : Computing the number of "un-processed" ProcessCalendar is difficult

    // const statuses = [
    //     { title: 'Administrateur', value: (waitSuperAdmin / total * 100).toFixed(2), icon: AdminIcon, color: theme.palette.primary.activityRegister },
    //     { title: 'Intervenant', value: (waitAdmin / total * 100).toFixed(2), icon: TeacherIcon, color: theme.palette.primary.activityRegister },
    //     { title: 'Eleve', value: (waitUser / total * 100).toFixed(2), icon: StudentIcon, color: theme.palette.primary.activityRegister },
    //     { title: 'Validé', value: (waitUser / total * 100).toFixed(2), icon: CheckIcon, color: theme.palette.primary.activityFree },
    //     { title: 'Non validé', value: (waiting / total * 100).toFixed(2), icon: HourGlassIcon, color: theme.palette.primary.activityFree }];


    const [getClassrooms] = useLazyQuery(LIST_GRADES_CLASSROOMS_USERS_BY_PERIOD, {
        onCompleted: (data) => {
            const classrooms = data.allPeriodGrades.nodes.map(g => {
                const grade = g.gradeByGradeUid;
                return grade.classroomsByGradeUid.nodes.map(classroom => {
                    return {
                        uid: classroom.uid,
                        users: classroom.usersByClassroomUid.nodes
                    }
                })
            }).flat();

            const users = classrooms.map(c => {
                return c.users;
            }).flat();

            setClassrooms(classrooms)
            setUsers(users);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [listProcessCalendar] = useLazyQuery(STATS_PROCESS_CALENDAR, {
        onCompleted: (data) => {
            setWaitSuperAdmin(data.wait_super_admin.totalCount);
            setWaitAdmin(data.wait_admin.totalCount);
            setWaitUser(data.wait_user.totalCount);
            setApproved(data.approved.totalCount);
            const inProgressCount = data.wait_super_admin.totalCount + data.wait_admin.totalCount + data.wait_user.totalCount + data.approved.totalCount;
            setWaiting(data.total.totalCount - inProgressCount);
            //setTotal(data.total.totalCount)
        },
        onError: (error) => enqueueSnackbar("Impossible de recuperer la liste des validations", { variant: "error" }),
        fetchPolicy: "cache-and-network"
    });

    useEffect(() => {
        if (period?.uid) getClassrooms({ variables: { periodUid: period.uid } });
    }, [period, getClassrooms])

    useEffect(() => {
        if (period?.uid && classrooms?.length > 0)
            listProcessCalendar({ variables: { periodUid: period.uid, userUids: users.map(u => u.uid), classroomUids: classrooms.map(u => u.uid) } });
    }, [period, users, classrooms, listProcessCalendar]);

    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardHeader title="VALIDATION DES PARCOURS" />
            <Divider />
            <CardContent>
                <Box sx={{ height: 200, position: 'relative' }}>
                    <Doughnut data={data} options={options} />
                </Box>
                <Grid container>
                {/* {statuses.map(({ color, icon: Icon, title, value }) => (
                    <Grid item xs={4}>
                        <Box x key={title} sx={{ p: 2, textAlign: 'center' }}>
                            <Icon color="action" />
                            <Typography color="textPrimary" variant="body1">{title}</Typography>
                            <Typography color="textPrimary" variant="h4">{value}%</Typography>
                        </Box>
                    </Grid>
                ))} */}
                </Grid>
            </CardContent>
        </Card>
    )
};

export default ProcessCalendarStats;