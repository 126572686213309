import { Box, Container } from '@mui/material';
import { DELETE_PLACE, LIST_PLACES } from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import PlaceListResults from './PlaceListResults';
import PlaceListToolbar from './PlaceListToolbar';
import { useSnackbar } from 'notistack';



function PlaceList() {
    const { enqueueSnackbar } = useSnackbar();


    const [places, setPlaces] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);

    let [nameFilter, setNameFilter] = useState("");


    const [getPlaces] = useLazyQuery(LIST_PLACES, {
        onCompleted: (data) => {
            setPlaces(data.allPlaces.nodes);
            setTotalCount(data.allPlaces.totalCount);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [deletePlace] = useMutation(DELETE_PLACE, {
        onError: e => enqueueSnackbar("Impossible de supprimer la salle", { variant: "error" }),
        onCompleted: d => enqueueSnackbar("Salle supprimée")
    })

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const loadData = useCallback(() => {
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const filters = { ...nameF };
        const filter = {
            variables: {
                filter: filters, first: limit,
                offset
            }
        }
        getPlaces(filter);
    }, [nameFilter, getPlaces, limit, offset])

    const handleDeletePlace = useCallback(async (placeUid) => {
        await deletePlace({
            variables: { placeUid }
        });
        loadData();
    }, [deletePlace, loadData]);

    useEffect(() => {
        loadData();
    }, [loadData])



    return (
        <Container component="main" maxWidth={false}>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <PlaceListToolbar
                        loadData={loadData}
                        updateSearch={updateSearch}
                    />
                    <Box sx={{ pt: 3 }}>
                        <PlaceListResults
                            places={places}
                            limit={limit}
                            offset={offset}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            handleDeletePlace={handleDeletePlace} />
                    </Box>
                </Container>
            </Box>
        </Container >
    )
};

export default PlaceList;