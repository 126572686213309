import { useLazyQuery } from "@apollo/client";
import { Box, Chip } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { LIST_PROCESS_CALENDAR } from "../../../graphql/admin-requests";
import { CalendarCheckIcon } from "../../Icons";
import CUModal from "../modals/CUModal";
import UserCalendar from "../UserCalendar";
import ProcessCalendar from "./ProcessCalendar";

function UserCalendarValidation({ period, user, role, mapping }) {

    const [processes, setProcesses] = useState([]);
    const [openValidateModal, setOpenValidateModal] = useState(false);
    const [buttonOption, setButtonOption] = useState(mapping['DEFAULT']);


    const [getCalendarProcess] = useLazyQuery(LIST_PROCESS_CALENDAR, {
        onCompleted: (data) => {

            const res = data.allCalendarProcesses.nodes;
            if (res && res[0]) {
                setProcesses(res);
                const state = res[0].calendarState;
                if (state) setButtonOption(mapping[state]);
            }
        },
        onError: (error) => console.error("Error retrieving activities", error),
        fetchPolicy: 'cache-and-network'

    })

    useEffect(() => {
        if (user && user.uid && period && period.uid)
            getCalendarProcess({ variables: { userUids: [user.uid], periodUid: period.uid } });
    }, [user, period, getCalendarProcess]);

    const handleClose = async () => {
        setOpenValidateModal(false);
        await getCalendarProcess({ variables: { userUids: [user.uid], periodUid: period.uid } });
    }

    return (
        <>
            {openValidateModal && <CUModal open={openValidateModal} handleClose={handleClose} component={ProcessCalendar} processes={processes} period={period} role={role} user={user} mapping={mapping} />}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }}>
                <Chip
                    icon={<CalendarCheckIcon style={{ color: buttonOption.color }} />}
                    clickable={buttonOption.clickable}
                    style={{ backgroundColor: buttonOption.backgroundColor, color: buttonOption.color, marginRight: '10px' }}
                    onClick={buttonOption.clickable ? () => { setOpenValidateModal(true) } : null}
                    label={buttonOption.title} />
            </Box>
            <UserCalendar period={period} user={user} />
        </>
    )
}

export default UserCalendarValidation;