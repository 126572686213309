import React, { Fragment, useState } from 'react';
import { useMutation } from "@apollo/client";
import { Box, Button, Container, Divider, Grid, Typography, TextField, IconButton, Collapse } from '@mui/material';
import { CREATE_CALENDAR_PROCESS } from '../../../graphql/admin-requests';
import { ExpandMoreIcon } from '../../Icons';
import ProcessCalendarDetails from '../../CommonComponents/ProcessCalendarDetails';




function ProcessCalendar({ onFinish, period, user, role, mapping, processes }) {

    const [comment, setComment] = useState("");
    const [expandDetails, setExpandDetails] = useState(false);


    const [processCalendar] = useMutation(CREATE_CALENDAR_PROCESS, {
        onCompleted: (data) => {
            console.log("C'est OK", data)
        },
        onError: (error) => console.error("Error processing calendar", error)
    })

    const handleProcessCalendar = (calendarState) => async () => {
        await processCalendar({ variables: { calendarState, userUid: user.uid, periodUid: period.uid, comment } });
        onFinish();
    }


    return (
        <Container component="main" style={{ maxHeight: "90vh", overflowY: "auto" }}>
            <Fragment>
                <Typography variant="h6">Valider l'emploi du temps</Typography>
                <Divider style={{ marginBottom: '20px' }} />

                <Box component="form" noValidate>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            label="Ajouter un commentaire (facultatif)"
                            multiline
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button style={{ margin: '10px' }} variant="outlined" color="primary" onClick={handleProcessCalendar(role === 'horaroj_super_admin' ? 'APPROVED' : 'WAITTING_SUPER_ADMIN')}>Valider</Button>
                    <Button style={{ margin: '10px' }} variant="outlined" color="secondary" onClick={handleProcessCalendar('WAITTING_USER')}>Refuser</Button>
                </Box>
            </Fragment>
            {processes && processes.length > 0 &&
                <Fragment>
                    <IconButton
                        onClick={() => setExpandDetails(!expandDetails)}
                        aria-expanded={expandDetails}
                        aria-label="show more"
                        title="Voir l'historique"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                    <Collapse in={expandDetails}>
                        <ProcessCalendarDetails processes={processes} mapping={mapping} />
                    </Collapse>
                </Fragment>
            }
        </Container>
    );

}
export default ProcessCalendar;