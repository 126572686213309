import React, { useEffect, useState, useRef } from 'react';
import { day_translate } from "../../../utils/translate";
import { Box, Divider, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import moment from "moment";
import { LIST_ACTIVITIES_FOR_CLASSROOMS, LIST_ACTIVITIES_SUPER_ADMIN, LIST_GRADES_AND_CLASSROOMS_BY_PERIOD } from '../../../graphql/admin-requests';
import { useLazyQuery, useQuery } from "@apollo/client";
import { activityTimeSort } from '../../../utils/sort';
import CustomIconButton from '../subcomponents/CustomIconButton';
import { PrintIcon } from '../../Icons';
import { useReactToPrint } from 'react-to-print';
import ClassroomGradeList from '../subcomponents/ClassroomGradeList';
import Select from "react-select";
import { dbToLocale } from '../../../utils/time';



const PrintableActivityList = ({ period }) => {


    const [activities, setActivities] = useState({});
    const [grades, setGrades] = useState([]);

    const [gradesOptions, setGradesOptions] = useState([]);
    const [classroomUids, setClassroomUids] = useState([]);

    const groupByDay = (item) => item.reduce((acc, value) => {
        if (!acc[value.day]) {
            acc[value.day] = [];
        }
        acc[value.day].push(value);
        return acc;
    }, {});

    const getListRequest = () => {
        if (classroomUids && classroomUids.length > 0) return LIST_ACTIVITIES_FOR_CLASSROOMS;
        return LIST_ACTIVITIES_SUPER_ADMIN
    }

    const getListRequestResultName = () => {
        if (classroomUids && classroomUids.length > 0) return "listActivitiesForClassrooms";
        return "allActivitySuperAdminViews";
    }

    const [listActivities] = useLazyQuery(getListRequest(), {
        onCompleted: (data) => {
            const byDay = groupByDay(data[getListRequestResultName()].nodes);
            setActivities(byDay);
        },
        fetchPolicy: 'cache-and-network'
    });

    useQuery(LIST_GRADES_AND_CLASSROOMS_BY_PERIOD, {
        variables: { periodUid: period.uid },
        onCompleted: data => {
            setGrades(data.allPeriodGrades.nodes.map(g => g.gradeByGradeUid));
            setGradesOptions(data.allPeriodGrades.nodes.map(g => { return { value: g.gradeByGradeUid.uid, label: g.gradeByGradeUid.name } }));
        }
    });

    useEffect(() => {
        if (period && period.uid) {
            listActivities({
                variables: {
                    classroomUids,
                    filter: {
                        periodUid: { equalTo: period.uid }
                    }
                }
            });
        }
    }, [listActivities, period, classroomUids]);

    const updateGradeFilter = (g) => {
        const gradeUids = g.map(i => i.value);
        const grade = grades.filter(i => gradeUids.includes(i.uid));
        const res = grade.reduce((acc, curr) => acc.concat(curr.classroomsByGradeUid.nodes.map(i => i.uid)), []);
        setClassroomUids(res);
    }

    const toPrintRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => toPrintRef.current,
        pageStyle: `
        @media all {
            .page-break {
              display: none;
            }
          }
          
          @media print {
            html, body {
              height: initial !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
            }
          }
          
          @media print {
            .page-break {
              display: block;
              page-break-after: always;
            }
          }
          
          @page {
            size: auto;
          }
        `
    });

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item xs={2}>
                    <Select
                        minMenuHeight={1000}
                        placeholder="Niveaux..."
                        isClearable={true}
                        isSearchable={true}
                        name="grades"
                        isMulti
                        onChange={updateGradeFilter}
                        options={gradesOptions}
                        defaultValue={[]} />
                </Grid>
                <CustomIconButton color="primary" variant="contained" onClick={handlePrint} icon={<PrintIcon />} title="Imprimer" />
            </Box>
            <div ref={toPrintRef}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography type="h1" variant="h4">Propositions pour la période : {period.name}</Typography>
                        <Typography>{moment(period.startDate).format("dddd DD MMMM yyyy")} - {moment(period.endDate).format("dddd DD MMMM yyyy")}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {period.openUserStart && <Typography variant="h5">Inscriptions</Typography>}
                        {period.openUserStart && <Typography>Ouverture : {dbToLocale(period.openUserStart).format("dddd DD MMMM yyyy à HH:mm:SS")}</Typography>}
                        {period.openUserEnd && <Typography>Fermeture : {dbToLocale(period.openUserEnd).format("dddd DD MMMM yyyy à HH:mm:SS")}</Typography>}
                    </Grid>
                </Grid>
                <Divider style={{ marginBottom: "25px" }} />
                {activities && ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'].map(day => {
                    return <Paper className="page-break" key={day}>
                        <Typography style={{ marginTop: "10px" }} type="h1" variant="h6">{day_translate['FR'][day]}</Typography>
                        <Box>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: "10px" }} size="small">Heures</TableCell>
                                        <TableCell style={{ fontSize: "10px" }} size="small">Nom</TableCell>
                                        <TableCell style={{ fontSize: "10px" }} size="small">Classes</TableCell>
                                        <TableCell style={{ fontSize: "10px" }} size="small">Salle</TableCell>
                                        <TableCell style={{ fontSize: "10px" }} size="small">Intervenant</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activities[day] && activities[day].sort(activityTimeSort).map((activity) => (
                                        <TableRow hover key={activity.uid}>
                                            <TableCell style={{ fontSize: "10px", width: 100 }} size="small">{moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}</TableCell>
                                            <TableCell style={{ fontSize: "10px" }} size="small">{activity.name}</TableCell>
                                            <TableCell style={{ fontSize: "10px" }} size="small" >
                                                <ClassroomGradeList
                                                    classrooms={activity.activityClassroomsByActivityUid.nodes
                                                        .map(({ classroomByClassroomUid }) => classroomByClassroomUid)}
                                                    grades={grades}
                                                    chips={false} />
                                            </TableCell>
                                            <TableCell style={{ fontSize: "10px" }} size="small">{activity.placeByPlaceUid && activity.placeByPlaceUid.name}</TableCell>
                                            <TableCell style={{ fontSize: "10px" }} size="small">{activity.userByOrganizerUid ? (`${activity.userByOrganizerUid.firstName} ${activity.userByOrganizerUid.lastName}`) : ''}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Paper>;
                })}
            </div>
        </div>);
};


export default PrintableActivityList;