
import Group from '@mui/icons-material/Group';
import Dashboard from '@mui/icons-material/Dashboard';
import ToBeDefined from '@mui/icons-material/HelpOutline';
import Class from '@mui/icons-material/Class';
import Event from '@mui/icons-material/Event';
import DateRange from '@mui/icons-material/DateRange';
import School from '@mui/icons-material/School';
import Sort from '@mui/icons-material/Sort';
import Person from '@mui/icons-material/Person';
import SupervisorAccount from '@mui/icons-material/SupervisorAccount';
import MeetingRoom from '@mui/icons-material/MeetingRoom';
import Menu from '@mui/icons-material/Menu';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Search from "@mui/icons-material/Search";
import Check from '@mui/icons-material/Check';
import LockOutlined from '@mui/icons-material/LockOutlined';
import LibraryAdd from '@mui/icons-material/LibraryAdd';
import DirectionsRun from '@mui/icons-material/DirectionsRun';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Cancel from '@mui/icons-material/Cancel';
import PersonAdd from '@mui/icons-material/PersonAdd';
import EventBusy from '@mui/icons-material/EventBusy';
import Print from '@mui/icons-material/Print';
import Visibility from '@mui/icons-material/Visibility';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VpnKey from '@mui/icons-material/VpnKey';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Flare from '@mui/icons-material/Flare';
import Highlight from '@mui/icons-material/Highlight';
import EventAvailable from '@mui/icons-material/EventAvailable';
import Done from '@mui/icons-material/Done';
import HourGlass from '@mui/icons-material/HourglassEmpty';
import Lock from '@mui/icons-material/Lock';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Face from '@mui/icons-material/Face';
import FileCopy from '@mui/icons-material/FileCopy'
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import HowToReg from '@mui/icons-material/HowToReg';
import LibraryAddCheck from '@mui/icons-material/LibraryAddCheck';

export const GroupIcon = Group;
export const StageIcon = Sort;
export const ClassroomIcon = Class;
export const ActivityIcon = DirectionsRun;
export const EventIcon = Event;
export const PeriodIcon = DateRange;
export const PlaceIcon = MeetingRoom;
export const StudentIcon = Person;
export const TeacherIcon = SupervisorAccount;
export const DashboardIcon = Dashboard;
export const MenuIcon = Menu;
export const ChevronLeftIcon = ChevronLeft;
export const ChevronRightIcon = ChevronRight;
export const DeleteIcon = Delete;
export const EditIcon = Edit;
export const SearchIcon = Search;
export const CheckIcon = Check;
export const LockOutlinedIcon = LockOutlined;
export const ToBeDefinedIcon = ToBeDefined;
export const ClassroomActivityIcon = School;
export const GroupActivityIcon = LibraryAdd;
export const CalendarIcon = CalendarToday;
export const CancelIcon = Cancel;
export const AddUserIcon = PersonAdd;
export const ConflictIcon = EventBusy;
export const PrintIcon = Print;
export const EyeIcon = Visibility;
export const FillIcon = PlaylistAdd;
export const VisibilityOffIcon = VisibilityOff;
export const PasswordIcon = VpnKey;
export const AccountCircleIcon = AccountCircle;
export const FlareIcon = Flare;
export const HighlightIcon = Highlight;
export const CalendarCheckIcon = EventAvailable;
export const DoneIcon = Done;
export const HourGlassIcon = HourGlass;
export const LockIcon = Lock;
export const ExpandMoreIcon = ExpandMore;
export const LoginIcon = Face;
export const ClipboardIcon = FileCopy;
export const AdminIcon = AdminPanelSettings;
export const HowToRegIcon = HowToReg;
export const MultipleCheckIcon = LibraryAddCheck;