import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from "@apollo/client";

import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import { LIST_GRADES_AND_CLASSROOMS_BY_PERIOD, FILL_ACTIVITY_FOR_CLASSROOMS } from "../../../graphql/admin-requests";
import GradesClassroomsSelect from '../GradesClassroomsSelect';
import { useSnackbar } from 'notistack';


function FillActivity({ onFinish, activity }) {

    const { enqueueSnackbar } = useSnackbar();


    let [classroomOptions, setClassroomOptions] = useState([]);
    let [classroomsState, setClassroomsState] = useState([]);


    const [getClassrooms] = useLazyQuery(LIST_GRADES_AND_CLASSROOMS_BY_PERIOD, {
        onCompleted: (data) => {
            const options = data.allPeriodGrades.nodes.map(i => {
                const grade = i.gradeByGradeUid;
                return {
                    uid: grade.uid,
                    name: grade.name,
                    choices: grade.classroomsByGradeUid.nodes.map(classroom => {
                        return {
                            uid: classroom.uid,
                            name: classroom.name,
                            gradeUid: grade.uid,
                        }
                    })
                }
            });

            const cState = data.allPeriodGrades.nodes
                .map(({ gradeByGradeUid: { classroomsByGradeUid: { nodes }, uid } }) => {
                    return nodes.map(n => { return { ...n, checked: false, gradeUid: uid } })
                })
                .flat();

            setClassroomOptions(options);
            setClassroomsState(cState);

        },
        fetchPolicy: 'cache-and-network'
    });

    const [updateActivity] = useMutation(FILL_ACTIVITY_FOR_CLASSROOMS, {
        onCompleted: async (data) => {
            const nb = data.fillActivityForClassrooms.integer;
            enqueueSnackbar(`Activité ${activity.name} ajoutée à ${nb} élèves`, { variant: "success" });
            onFinish();
        },
        onError: async (e) => {
            enqueueSnackbar("Une erreur s'est produite !", { variant: "error" });
        }
    });

    const handleValidate = async () => {
        const classroomUids = classroomsState.filter(i => i.checked).map(i => i.uid);
        await updateActivity({ variables: { classroomUids, activityUid: activity.uid } })
    }

    useEffect(() => {
        if (activity.periodByPeriodUid && activity.periodByPeriodUid.uid) {
            getClassrooms({ variables: { periodUid: activity.periodByPeriodUid.uid } });
        }
    }, [activity, getClassrooms]);


    return (
        <Container component="main" >
            <Typography variant="h6">Ajouter l'activité {activity.name}</Typography>
            <Divider />
            <Box component="form" width='800px' noValidate>
                <Grid item xs={12}>
                    <GradesClassroomsSelect
                        classroomOptions={classroomOptions}
                        classroomsState={classroomsState}
                        setClassroomsState={setClassroomsState}
                    />
                </Grid>
            </Box>
            <Button style={{ float: "right", margin: '10px' }} variant="outlined" color="primary" disabled={false} onClick={handleValidate}>Valider les classes</Button>
            {/* 
            {errorAdd && <p>{errorAdd.message}</p>}
            {errorDelete && <p>{errorDelete.message}</p>} */}
        </Container>
    );
}

export default FillActivity;