const naturalSort = ((a, b) => a.name > b.name);

const classroomSort = (a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
}

const periodSort = (a, b) => {

    const sameOpenSort = (a, b) => {
        if (a.startDate < b.startDate) return -1;
        if (a.startDate > b.startDate) return 1;
        return 0;
    }

    if (a.openUser) {
        if (b.openUser) {
            return sameOpenSort(a, b);
        } else { return -1; }
    } else {
        if (b.openUser) {
            return 1;
        } else {
            return sameOpenSort(a, b);
        }
    }
}

const periodAdminSort = (a, b) => {

    const sameOpenSort = (a, b) => {
        if (a.startDate < b.startDate) return 1;
        if (a.startDate > b.startDate) return -1;
        return 0;
    }

    // TODO ARE : need PeriodInfo to handle openAdmin field (and not Period)
    if (a.openAdmin) {
        if (b.openAdmin) {
            return sameOpenSort(a, b);
        } else { return -1; }
    } else {
        if (b.openAdmin) {
            return 1;
        } else {
            return sameOpenSort(a, b);
        }
    }
}

const userSort = (a, b) => {
    if (a.lastName < b.lastName)
        return -1;
    if (a.lastName > b.lastName)
        return 1;
    if (a.firstName < b.firstName)
        return -1
    if (a.firstName > b.firstName)
        return 1
    return 0;
}

const activityTimeSort = (a, b) => {
    if (a.startTime < b.startTime)
        return -1;
    if (a.startTime > b.startTime)
        return 1;
    if (a.endTime < b.endTime)
        return -1
    if (a.endTime > b.endTime)
        return 1
    return 0;
}

export { naturalSort, classroomSort, userSort, activityTimeSort, periodSort, periodAdminSort }