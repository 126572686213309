import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, gql } from "@apollo/client";
import { Box, Button, CssBaseline, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Typography, Container, Paper, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { EyeIcon, VisibilityOffIcon } from '../Icons'



const AUTH = gql`mutation AUth ($email: String!, $password: String!) {
    authenticate(input: {email: $email, password: $password}) {
      clientMutationId
      jwt
    }
  }`;

function LoginForm(props) {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [showPassword, setShowPassword] = useState(false);


  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();


  const [login] = useMutation(AUTH, {
    onCompleted: (data) => {
      const jwtToken = data.authenticate.jwt;
      if (jwtToken) {
        localStorage.setItem("HorarojJWTToken", jwtToken);
        props.setLogged(true);
        navigate("/");
      } else {
        enqueueSnackbar("Identifiants non reconnus. Veuillez réessayer ou contactez un administrateur", { variant: "warning" });
      }
    },
    onError: (error) => {
      enqueueSnackbar("Erreur lors de la connexion", { variant: "error" });
    },
  });


  function handleLogin(event) {
    event.preventDefault();
    // the mutate function also doesn't return a promise
    login({
      variables: { email, password }
    });
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Se connecter
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleLogin}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  onChange={event => setEmail(event.target.value)}
                  fullWidth
                  id="email"
                  label="Login"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />

                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="password" required>Mot de passe</InputLabel>
                  <OutlinedInput
                    id="password"
                    onChange={event => setPassword(event.target.value)}
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <EyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Mot de passe *"
                  />
                </FormControl>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Se connecter
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );

}
export default LoginForm;