import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';
import { UNREGISTER_ACTIVITY_FOR_USER } from '../../../graphql/admin-requests';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';


const GroupActivityConfirmDelete = ({ user, activity, onClose, open, ...rest }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [confirmed, setConfirmed] = useState(false);

    const [unRegisterActivity] = useMutation(UNREGISTER_ACTIVITY_FOR_USER, {
        onError: e => { enqueueSnackbar(`Impossible de se désinscrire de l'activité`, { variant: "warning" }) },
        onCompleted: d => { enqueueSnackbar(`Désinscription de l'activité validée`, { variant: "success" }) }
    })

    const handleConfirm = async () => {
        setConfirmed(true);
        await unRegisterActivity({ variables: { userUid: user.uid, activityUid: activity.uid } })
        onClose();
        setConfirmed(false);
    }

    return (
        <div>
            <Dialog keepMounted open={open} onClose={onClose}>
                <DialogTitle>Désinscrire élèves</DialogTitle>

                {confirmed ?
                    <DialogContent>
                        <LinearProgress />
                    </DialogContent> :
                    <div>
                        <DialogContent>
                            <DialogContentText>
                                Vous êtes sur le point de <strong>supprimer</strong> l'activité<br />
                                <strong>{activity.name}</strong> à l'élève <strong>{user.firstName} {user.lastName}</strong>.<br />
                                Etes vous sûr ?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">Annuler</Button>
                            <Button onClick={handleConfirm} color="primary">Confirmer</Button>
                        </DialogActions>
                    </div>}


            </Dialog >
        </div >);
};


export default GroupActivityConfirmDelete;