import React, { useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Checkbox, Container, Divider, Grid, FormGroup, FormControlLabel, Typography } from '@mui/material';
import { CREATE_USER_GROUP, LIST_GROUPS, DELETE_USER_GROUP } from "../../graphql/admin-requests";
import { computeItemUpdate } from "../../utils/reducers"
import { useSnackbar } from 'notistack';



function UserGroups({ onFinish, user }) {

    const { enqueueSnackbar } = useSnackbar();


    let [groupsState, setGroupsState] = useState([]);
    let [userGroupsUids, setUserGroupsUids] = useState([]);


    const [createUserGroup, { creatingGroup, errorAdd }] = useMutation(CREATE_USER_GROUP, {
        onCompleted: (data) => {
            console.log("1 group ajouté", data);
            return Promise.resolve("1 groupe ajouté");
        },
        onError: (error) => {
            enqueueSnackbar("Impossible d'ajouter le groupe")
            return Promise.reject("Impossible d'ajouter le groupe");
        },
    });

    const [deleteUserGroup, { deletingGroup, errorDelete }] = useMutation(DELETE_USER_GROUP, {
        onCompleted: (data) => {
            console.log("1 group supprimé", data);
            return Promise.resolve("1 groupe supprimé");
        },
        onError: (error) => {
            enqueueSnackbar("Impossible de supprimer le groupe")
            return Promise.reject("Impossible de supprimer le groupe");
        },
    });

    async function handleValidateGroup(event) {
        event.preventDefault();
        const { toDelete: groupsToDelete, toAdd: groupsToAdd } = computeItemUpdate(userGroupsUids, groupsState);
        const userUid = user.uid;

        await Promise.all(groupsToDelete.map(async groupUid =>
            await deleteUserGroup({ variables: { userUid, groupUid } })));

        await Promise.all(groupsToAdd.map(async groupUid =>
            await createUserGroup({ variables: { userUid, groupUid } })));
        enqueueSnackbar("Groupes mis à jours");
        onFinish();
    }

    const handleCheckBoxes = (event) => {
        const newGroupState = [...groupsState];
        newGroupState.find(g => g.uid === event.target.value).checked = event.target.checked;
        setGroupsState(newGroupState);
    }


    // Retrieve all groups
    useQuery(LIST_GROUPS, {
        onCompleted: (data) => {
            const initialGroups = user.userGroupsByUserUid.nodes.map((ug) => ug.groupByGroupUid.uid);
            setUserGroupsUids(initialGroups);
            const groups = data.allGroups.nodes;
            setGroupsState(groups.map(i => { return { ...i, checked: initialGroups.includes(i.uid) } }));
        },
        onError: (error) => console.error("Error requesting list of groups", error),
        fetchPolicy: 'cache-and-network'
    })

    return (
        <Container component="main" >
            <Typography variant="h6">Groupes</Typography>
            <Divider />
            <Box component="form" noValidate>
                <Grid item xs={12} sm={6}>
                    <FormGroup row>{
                        groupsState.map(g => {
                            return (<FormControlLabel
                                control={<Checkbox checked={g.checked} onChange={handleCheckBoxes} name={g.name} value={g.uid} color="primary" />}
                                label={g.name}
                                key={g.uid} />);
                        })
                    }</FormGroup>
                </Grid>
            </Box>
            <Button style={{ float: "right", margin: '10px' }} variant="outlined" color="primary" disabled={creatingGroup || deletingGroup} onClick={handleValidateGroup}>Valider les groupes</Button>
            {errorAdd && <p>{errorAdd.message}</p>}
            {errorDelete && <p>{errorDelete.message}</p>}
        </Container>
    );

}
export default UserGroups;