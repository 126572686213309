import { gql } from "@apollo/client";


const CREATE_ACTIVITY = gql`mutation CreateActivity(
  $day: Day!, 
  $name: String!, 
  $capacity: Int!,
  $startTime: Time!, 
  $endTime: Time!,
  $organizerUid: UUID,
  $placeUid: UUID,
  $periodUid: UUID!) {
createActivity(
input: {
    activity: {
    startTime: $startTime,
    endTime: $endTime,
    name: $name,
    placeUid: $placeUid,
    periodUid: $periodUid,
    organizerUid: $organizerUid,
    capacity: $capacity,
    day: $day}}
) {
activity {
    uid
    createdBy
    organizationUid
    name
    day
}
}
}`;

const CREATE_CLASSROOM_ACTIVITY = gql`mutation CreateClassroomActivity(
  $day: Day!, 
  $name: String!, 
  $startTime: Time!, 
  $endTime: Time!,
  $organizerUid: UUID,
  $placeUid: UUID,
  $classroomUid: UUID!,
  $periodUid: UUID!) {
createClassroomActivity(
input: {
    classroomActivity: {
    startTime: $startTime,
    endTime: $endTime,
    name: $name,
    placeUid: $placeUid,
    classroomUid: $classroomUid,
    periodUid: $periodUid,
    organizerUid: $organizerUid,
    day: $day}}
) {
classroomActivity {
    uid
    createdBy
    organizationUid
    name
    day
}
}
}`;

const CREATE_GROUP_ACTIVITY = gql`
mutation CreateGroupActivity(
  $day: Day!, 
  $name: String!, 
  $startTime: Time!, 
  $endTime: Time!,
  $organizerUid: UUID,
  $placeUid: UUID,
  $groupUid: UUID!,
  $periodUid: UUID!) {
      createGroupActivity(
          input: {
              groupActivity: {
                  startTime: $startTime,
                  endTime: $endTime,
                  name: $name,
                  placeUid: $placeUid,
                  groupUid: $groupUid,
                  periodUid: $periodUid,
                  organizerUid: $organizerUid,
                  day: $day}
              }
      ){
          groupActivity {
              uid
              createdBy
              organizationUid
              name
              day
          }
      }
  }`;

const UPDATE_GROUP = gql`mutation UpdateGroup($name: String!, $uid: UUID!) {
    updateGroupByUid(input: { groupPatch: { name: $name }, uid: $uid }) {
      group {
        uid
        createdBy
        organizationUid
        name
      }
    }
  } `;

const UPDATE_GRADE = gql`mutation UpdateGrade($name: String!, $uid: UUID!) {
    updateGradeByUid(input: { gradePatch: { name: $name }, uid: $uid }) {
      grade {
        uid
        createdBy
        organizationUid
        name
      }
    }
  } `;

const UPDATE_PERIOD = gql`mutation UpdatePeriod (
  $uid: UUID!,
  $name: String!, 
  $startDate: Datetime!, 
  $endDate: Datetime!,
  $openUserStart: Datetime,
  $openUserEnd: Datetime,
  $openUserForce: Boolean,
  $openAdminStart: Datetime,
  $openAdminEnd: Datetime,
  $openAdminForce: Boolean) {
  updatePeriodByUid(
    input: {
      periodPatch: {
        endDate: $endDate,
        startDate: $startDate,
        name: $name,
        openUserEnd: $openUserEnd,
        openUserForce: $openUserForce,
        openUserStart: $openUserStart,
        openAdminEnd: $openAdminEnd,
        openAdminForce: $openAdminForce,
        openAdminStart: $openAdminStart},
        uid: $uid})
        { clientMutationId  }}`

const UPDATE_ACTIVITY = gql`mutation UpdateActivity(
    $activityUid: UUID!,
    $day: Day!,
    $name: String!,
    $capacity: Int!,
    $startTime: Time!,
    $endTime: Time!,
    $organizerUid: UUID,
    $placeUid: UUID,
    $periodUid: UUID!) {
    updateActivityByUid(input: {
      activityPatch: {
        startTime: $startTime,
        endTime: $endTime,
        name: $name,
        placeUid: $placeUid,
        periodUid: $periodUid,
        organizerUid: $organizerUid,
        capacity: $capacity,
        day: $day
      }, uid: $activityUid
    }) {
      activity {
        uid
        createdBy
        organizationUid
        name
        day
      }
    }
  } `;


const UPDATE_GROUP_ACTIVITY = gql`mutation UpdateGroupActivity(
    $groupActivityUid: UUID!,
    $day: Day!,
    $name: String!,
    $startTime: Time!,
    $endTime: Time!,
    $organizerUid: UUID,
    $placeUid: UUID,
    $groupUid: UUID!,
    $periodUid: UUID!) {
    updateGroupActivityByUid(input: {
      groupActivityPatch: {
        startTime: $startTime,
        endTime: $endTime,
        name: $name,
        placeUid: $placeUid,
        periodUid: $periodUid,
        organizerUid: $organizerUid,
        groupUid: $groupUid,
        day: $day
      }, uid: $groupActivityUid
    }) {
      groupActivity {
        uid
        createdBy
        organizationUid
        name
        day
      }
    }
  } `;

const UPDATE_CLASSROOM_ACTIVITY = gql`mutation UpdateClassroomActivity(
    $classroomActivityUid: UUID!,
    $day: Day!,
    $name: String!,
    $startTime: Time!,
    $endTime: Time!,
    $organizerUid: UUID,
    $placeUid: UUID,
    $classroomUid: UUID!,
    $periodUid: UUID!) {
    updateClassroomActivityByUid(input: {
      classroomActivityPatch: {
        startTime: $startTime,
        endTime: $endTime,
        name: $name,
        placeUid: $placeUid,
        periodUid: $periodUid,
        organizerUid: $organizerUid,
        classroomUid: $classroomUid,
        day: $day
      }, uid: $classroomActivityUid
    }) {
      classroomActivity {
        uid
        createdBy
        organizationUid
        name
        day
      }
    }
  } `;


const CREATE_GROUP = gql`mutation CreateGroup($name: String!) {
  createGroup(input: { group: { name: $name } }) {
    group {
      uid
      createdBy
      organizationUid
      name
    }
  }
} `;

const CREATE_GRADE = gql`mutation CreateGrade($name: String!) {
  createGrade(input: { grade: { name: $name } }) {
    grade {
      uid
      createdBy
      organizationUid
      name
    }
  }
} `;

const CREATE_CLASSROOM = gql`mutation CreateClassroom($name: String!, $gradeUid: UUID!) {
  createClassroom(input: { classroom: { name: $name, gradeUid: $gradeUid } }) {
    classroom {
      uid
      createdBy
      organizationUid
      name
    }
  }
} `;

const CREATE_USER = gql`mutation CreateUser($firstName: String!, $lastName: String!, $classroomUid: UUID!) {
  createUser(input: { user: { firstName: $firstName, lastName: $lastName, classroomUid: $classroomUid, userRole: HORAROJ_USER } }) {
    user {
      uid
      firstName
      lastName
    }
  }
} `;

const UPDATE_USER = gql`mutation UpdateUser($userUid: UUID!, $firstName: String!, $lastName: String!, $classroomUid: UUID)  {
  updateUserByUid(input: { userPatch: { classroomUid: $classroomUid, lastName: $lastName, firstName: $firstName }, uid: $userUid }) {
    user {
      lastName
      firstName
      uid
      classroomUid
    }
  }
} `;

const CREATE_ADMIN = gql`mutation CreateUser($firstName: String!, $lastName: String!) {
  createUser(input: { user: { firstName: $firstName, lastName: $lastName, userRole: HORAROJ_ADMIN } }) {
    user {
      uid
      firstName
      lastName
    }
  }
} `;

const CREATE_PERIOD = gql`mutation CreatePeriod(
  $name: String!, 
  $startDate: Datetime!, 
  $endDate: Datetime!,
  $openUserStart: Datetime,
  $openUserEnd: Datetime,
  $openAdminStart: Datetime,
  $openAdminEnd: Datetime){
  createPeriod(
    input: { period: { 
      name: $name, 
      startDate: $startDate, 
      endDate: $endDate,
      openUserStart: $openUserStart,
      openUserEnd: $openUserEnd,
      openAdminStart: $openAdminStart,
      openAdminEnd: $openAdminEnd } }
  ) {
    period {
      uid
      name
      startDate
      endDate
    }
  }
} `;

const CREATE_PLACE = gql`mutation CreatePlace($name: String!, $capacity: Int!){
  createPlace(
    input: { place: { name: $name, capacity: $capacity } }
  ) {
    place {
      uid
      name
      capacity
    }
  }
} `;

const LIST_PLACES = gql`query GetPlaces($first: Int = 100, $offset: Int = 0, $filter: PlaceFilter){
  allPlaces(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [NAME_ASC]) {
    nodes {
      uid
      name
      capacity
      organizationUid
    }
    totalCount
  }
} `;

const LIST_AVAILABLE_PLACES = gql`query GetAvailablePlaces($day: Day!, $startTime: Time!, $endTime: Time!, $periodUid: UUID!, $first: Int = 100, $offset: Int = 0, $filter: PlaceFilter){
  listAvailablePlaces(
    aDay: $day,
    aPeriodUid: $periodUid,
    e:  $endTime,
    s:  $startTime,
    filter: $filter,
    first: $first,
    offset: $offset) {
    nodes {
      uid
      name
      capacity
      organizationUid
    }
    totalCount
  }
} `;

const LIST_GROUPS = gql`query GetGroups($first: Int = 100, $offset: Int = 0, $filter: GroupFilter){
  allGroups(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [NAME_ASC]) {
    nodes {
      uid
      name
      organizationUid
      userGroupsByGroupUid {
        totalCount
      }
    }
    totalCount
  }
} `;

const LIST_GRADES = gql`query GetGrades($first: Int = 100, $offset: Int = 0, $filter: GradeFilter){
  allGrades(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [NAME_ASC]) {
    nodes {
      uid
      name
      organizationUid
    }
    totalCount
  }
} `;

const LIST_CLASSROOMS = gql`query GetClassrooms($first: Int = 100, $offset: Int = 0, $filter: ClassroomFilter){
  allClassrooms(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [NAME_ASC]) {
    nodes {
      uid
      name
      organizationUid
      gradeByGradeUid {
        name
        uid
      }
      usersByClassroomUid {
        nodes {
          uid
        }
        totalCount
      }
    }
    totalCount
  }
} `;

const LIST_CLASSROOMS_FOR_ADMIN = gql`query  ListClassroomsForAdmin( $first: Int = 100, $offset: Int = 0, $filter: ClassroomFilter) {
  listClassroomsForAdmin(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [NAME_ASC]) {
    nodes {
      uid
      name
      organizationUid
      gradeByGradeUid {
        name
        uid
      }
      usersByClassroomUid {
        nodes {
          uid
        }
        totalCount
      }
    }
    totalCount
  }
}
`;

const LIST_PERIODS = gql`query GetPeriods($first: Int = 100, $offset: Int = 0, $filter: PeriodFilter){
  allPeriods(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [START_DATE_ASC, END_DATE_ASC, NAME_ASC]) {
    nodes {
      uid
      name
      startDate,
      endDate,
      openUserStart,
      openUserEnd,
      openUserForce,
      openAdminStart,
      openAdminEnd,
      openAdminForce,
      organizationUid,
      periodGradesByPeriodUid {
        nodes {
          gradeByGradeUid {
            name
            uid
          }
        }
      }
    }
    totalCount
  }
} `;

const LIST_PERIODS_INFO_ADMIN = gql`mutation PeriodsInfoAdmin {
  getPeriodsAllInfo(input: {}) {
    periodInfos {
      uid
      name
      openUserStart
      startDate
      endDate
      openUser
      openAdminStart
      openAdminEnd
      openAdmin
    }
  }
}
`;

const LIST_GRADES_AND_CLASSROOMS_BY_PERIOD = gql`query ListGradesAndClassroomsByPeriod($periodUid: UUID!){
  allPeriodGrades(condition: { periodUid: $periodUid }) {
    nodes {
      gradeByGradeUid {
        classroomsByGradeUid {
          nodes {
            name
            uid
          }
        }
        name
        uid
      }
    }
  }
}`;

const LIST_GRADES_CLASSROOMS_USERS_BY_PERIOD = gql`query ListGradesClassroomsUsersByPeriod($periodUid: UUID!){
  allPeriodGrades(condition: { periodUid: $periodUid }) {
    nodes {
      gradeByGradeUid {
        classroomsByGradeUid {
          nodes {
            name
            uid
            usersByClassroomUid {
              nodes {
                uid
              }
              totalCount
            }
          }
        }
        name
        uid
      }
    }
  }
}`;

const LIST_USER_BY_ACTIVITY = gql`query GetUsersByActivity($first: Int = 100, $offset: Int = 0, $activityUid: UUID!) {
  allUserActivities(
    first: $first,
    offset: $offset,
    condition: {activityUid: $activityUid}) {
    nodes {
      userByUserUid {
        uid
        firstName
        lastName
        classroomByClassroomUid {
          name
        }
      }
    }
  }
}`;

const LIST_USER_BY_GROUP = gql`query GetUsersByGroup($first: Int = 100, $offset: Int = 0, $groupUid: UUID!){
  allUserGroups(
    first: $first,
    offset: $offset,
    condition: { groupUid: $groupUid }){
    nodes {
      userByUserUid {
        lastName
        firstName
        uid
        createdAt
        classroomUid
        classroomByClassroomUid {
          uid
          name
        }
        userGroupsByUserUid{
          nodes{
            groupByGroupUid{
              uid
              name
            }
          }
        }
      }
    }
    totalCount
  }
} `;


const LIST_USERS = gql`query ListUsers($first: Int = 100, $offset: Int = 0, $filter: UserFilter, $classroomUid: UUID){
  allUsers(
    first: $first,
    offset: $offset,
    filter: $filter,
    condition: { userRole: HORAROJ_USER, classroomUid: $classroomUid },
    orderBy: [LAST_NAME_ASC, FIRST_NAME_ASC]){
    nodes {
      uid
      firstName
      lastName
      organizationUid
      createdAt
      classroomUid
      classroomByClassroomUid {
        uid
        name
      }
      userGroupsByUserUid{
        nodes{
          groupByGroupUid{
            uid
            name
          }
        }
      }
    }
    totalCount
  }
} `;


const LIST_ADMINS = gql`query ListAdmins($first: Int, $offset: Int = 0, , $filter: UserFilter){
  allUsers(
    first: $first,
    offset: $offset,
    filter: $filter,
    condition: { userRole: HORAROJ_ADMIN },
    orderBy: [LAST_NAME_ASC, FIRST_NAME_ASC]){
    nodes {
      uid
      firstName
      lastName
      organizationUid
      createdAt
      adminClassroomsByUserUid{
        nodes{
          classroomByClassroomUid{
            uid
            name
          }
        }
      }
      userGroupsByUserUid{
        nodes{
          groupByGroupUid{
            uid
            name
          }
        }
      }
    }
    totalCount
  }
} `;

const LIST_SUPER_ADMINS = gql`query ListSuperAdmins($first: Int, $offset: Int = 0, , $filter: UserFilter){
  allUsers(
    first: $first,
    offset: $offset,
    filter: $filter,
    condition: { userRole: HORAROJ_SUPER_ADMIN },
    orderBy: [LAST_NAME_ASC, FIRST_NAME_ASC]){
    nodes {
      uid
      firstName
      lastName
      organizationUid
      createdAt
      lastLogin
      lastPasswordUpdate
      adminClassroomsByUserUid{
        nodes{
          classroomByClassroomUid{
            uid
            name
          }
        }
      }
      userGroupsByUserUid{
        nodes{
          groupByGroupUid{
            uid
            name
          }
        }
      }
    }
    totalCount
  }
} `;

const LIST_ACTIVITIES_SUPER_ADMIN = gql`
query GetActivities($first: Int, $offset: Int = 0, $filter: ActivitySuperAdminViewFilter!) {
  allActivitySuperAdminViews(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [DAY_ASC, START_TIME_ASC, NAME_ASC]) {
    nodes {
      uid
      name
      day
      startTime
      placeUid
      endTime
      capacity
      remaining
      taken
      placeByPlaceUid {
        uid
        name
      }
      activityGroupsByActivityUid {
        nodes {
          groupByGroupUid {
            uid
            name
          }
        }
      }
      activityClassroomsByActivityUid {
        nodes {
          classroomByClassroomUid {
            uid
            name
            gradeUid
          }
        }
      }
      userByOrganizerUid {
        uid
        firstName
        lastName
      }
      periodByPeriodUid {
        uid
        name
      }
    }
    totalCount
  }
}`

const LIST_ACTIVITIES_ADMIN = gql`
query GetActivities($first: Int = 100, $offset: Int = 0, $filter: ActivityAdminViewFilter!) {
  allActivityAdminViews(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [DAY_ASC, START_TIME_ASC, NAME_ASC]) {
    nodes {
      uid
      name
      day
      startTime
      placeUid
      endTime
      capacity
      remaining
      taken
      placeByPlaceUid {
        uid
        name
      }
      activityGroupsByActivityUid {
        nodes {
          groupByGroupUid {
            uid
            name
          }
        }
      }
      activityClassroomsByActivityUid {
        nodes {
          classroomByClassroomUid {
            uid
            name
            gradeUid
          }
        }
      }
      userByOrganizerUid {
        uid
        firstName
        lastName
      }
      periodByPeriodUid {
        uid
        name
      }
    }
    totalCount
  }
}`

const LIST_CLASSROOM_ACTIVITIES = gql`
query GetClassroomActivities($first: Int = 100, $offset: Int = 0, $filter: ClassroomActivityFilter!) {
  allClassroomActivities(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [DAY_ASC, START_TIME_ASC, NAME_ASC]) {
    nodes {
      uid
      name
      day
      startTime
      placeUid
      endTime
      classroomByClassroomUid {
        uid
        name
        usersByClassroomUid{
          totalCount
        }
      }
      userByOrganizerUid {
        uid
        firstName
        lastName
      }
      periodByPeriodUid {
        uid
        name
      }
    }
    totalCount
  }
} `

const LIST_GROUP_ACTIVITIES = gql`
query GetGroupActivities($first: Int = 100, $offset: Int = 0, $filter: GroupActivityFilter!) {
  allGroupActivities(
    filter: $filter,
    first: $first,
    offset: $offset,
    orderBy: [DAY_ASC, START_TIME_ASC, NAME_ASC]) {
    nodes {
      uid
      name
      day
      startTime
      placeUid
      endTime
      groupByGroupUid {
        uid
        name
        userGroupsByGroupUid{
          totalCount
        }
      }
      userByOrganizerUid {
        uid
        firstName
        lastName
      }
      periodByPeriodUid {
        uid
        name
      }
    }
    totalCount
  }
} `

const LIST_CLASSROOM_ACTIVITY_USERS = gql`
query ListClassroomActivityUsers($classroomActivityUid: UUID!) {
  allClassroomActivityUsers(condition: {classroomActivityUid: $classroomActivityUid}) {
    nodes {
      userByUserUid {
        classroomByClassroomUid {
          name
          uid
        }
        lastName
        firstName
        uid
      }
    }
  }
}
`

const CREATE_PERIOD_GRADE = gql`mutation CreatePeriodGrade($periodUid: UUID!, $gradeUid: UUID!) {
  createPeriodGrade(input: { periodGrade: { periodUid: $periodUid, gradeUid: $gradeUid } }) {
    clientMutationId
  }
}
`;

const CREATE_ACTIVITY_GROUP = gql`mutation CreateActivityGroup($groupUid: UUID!, $activityUid: UUID!) {
  createActivityGroup(input: { activityGroup: { groupUid: $groupUid, activityUid: $activityUid } }) {
    clientMutationId
  }
}
`;

const CREATE_ACTIVITY_CLASSROOM = gql`mutation CreateActivityClassroom($classroomUid: UUID!, $activityUid: UUID!) {
  createActivityClassroom(input: { activityClassroom: { classroomUid: $classroomUid, activityUid: $activityUid } }) {
    clientMutationId
  }
}
`;

const CREATE_CLASSROOM_ACTIVITY_USER = gql`mutation CreateClassroomActivityUser($classroomActivityUid: UUID!, $userUid: UUID!){
  createClassroomActivityUser(
    input: {classroomActivityUser: {classroomActivityUid: $classroomActivityUid, userUid: $userUid}}) {
    clientMutationId
  }
}
`;

const CREATE_USER_GROUP = gql`mutation CreateUserGroup($groupUid: UUID!, $userUid: UUID!) {
  createUserGroup(input: { userGroup: { groupUid: $groupUid, userUid: $userUid } }) {
    clientMutationId
  }
}
`;

const CREATE_ADMIN_CLASSROOM = gql`mutation CreateAdminClassroom($classroomUid: UUID!, $userUid: UUID!) {
  createAdminClassroom(input: { adminClassroom: { classroomUid: $classroomUid, userUid: $userUid } }) {
    clientMutationId
  }
}
`;

const DELETE_PERIOD_GRADE = gql`mutation DeletePeriodGrade($periodUid: UUID!, $gradeUid: UUID!) {
  deletePeriodGradeByPeriodUidAndGradeUid(input: {periodUid: $periodUid, gradeUid: $gradeUid}){
    clientMutationId
  }
}
`;

const DELETE_ACTIVITY = gql`mutation DeleteActivity($activityUid: UUID!) {
  deleteActivityByUid(input: { uid: $activityUid }){
    activity{
      uid
      name
    }
  }
}
`;

const DELETE_CLASSROOM_ACTIVITY = gql`mutation DeleteClassroomActivity($classroomActivityUid: UUID!) {
  deleteClassroomActivityByUid(input: {uid: $classroomActivityUid}) {
    clientMutationId
  }
}
`;

const DELETE_GROUP_ACTIVITY = gql`mutation DeleteGroupActivity($groupActivityUid: UUID!) {
  deleteGroupActivityByUid(input: {uid: $groupActivityUid}) {
    clientMutationId
  }
}
`;

const DELETE_GROUP = gql`mutation DeleteGroup($groupUid: UUID!) {
  deleteGroupByUid(input: { uid: $groupUid }){
    group{
      uid
      name
    }
  }
}
`;

const DELETE_GRADE = gql`mutation DeleteGrade($gradeUid: UUID!) {
  deleteGradeByUid(input: { uid: $gradeUid }){
    grade{
      uid
      name
    }
  }
}
`;

const DELETE_CLASSROOM = gql`mutation DeleteClassroom($classroomUid: UUID!) {
  deleteClassroomByUid(input: { uid: $classroomUid }){
    classroom{
      uid
      name
    }
  }
}
`;

const DELETE_PERIOD = gql`mutation DeletePeriod($periodUid: UUID!) {
  deletePeriodByUid(input: { uid: $periodUid }){
    period{
      uid
      name
    }
  }
}
`;

const DELETE_PLACE = gql`mutation DeletePlace($placeUid: UUID!) {
  deletePlaceByUid(input: { uid: $placeUid }){
    place{
      uid
      name
    }
  }
}
`;

const DELETE_USER = gql`mutation DeleteUser($userUid: UUID!) {
  deleteUserByUid(input: { uid: $userUid }){
    user{
      uid
      firstName,
        lastName
    }
  }
}
`;

const DELETE_USER_GROUP = gql`mutation DeleteUserGroup($groupUid: UUID!, $userUid: UUID!) {
  deleteUserGroupByUserUidAndGroupUid(input: { groupUid: $groupUid, userUid: $userUid }) {
    clientMutationId
  }
}
`;


const DELETE_ADMIN_CLASSROOM = gql`mutation DeleteAdminClassroom($classroomUid: UUID!, $userUid: UUID!) {
  deleteAdminClassroomByUserUidAndClassroomUid(input: { classroomUid: $classroomUid, userUid: $userUid }) {
    clientMutationId
  }
}
`;


const DELETE_ACTIVITY_GROUP = gql`mutation DeleteActivityGroup($groupUid: UUID!, $activityUid: UUID!) {
  deleteActivityGroupByGroupUidAndActivityUid(input: { groupUid: $groupUid, activityUid: $activityUid }) {
    clientMutationId
  }
}
`;

const DELETE_CLASSROOM_ACTIVITY_USER = gql`mutation DeleteClassroomActivityUser($classroomActivityUid: UUID!, $userUid: UUID!) {
    deleteClassroomActivityUserByClassroomActivityUidAndUserUid(
      input: {classroomActivityUid: $classroomActivityUid, userUid: $userUid}
    ) { clientMutationId }
  }
`;


const DELETE_ACTIVITY_CLASSROOM = gql`mutation DeleteActivityClassroom($activityUid: UUID!, $classroomUid: UUID!) {
  deleteActivityClassroomByClassroomUidAndActivityUid(input: { activityUid: $activityUid, classroomUid: $classroomUid }) {
    clientMutationId
  }
}
`;

const GET_USER = gql`query GetUser($userUid: UUID!){
  userByUid(uid: $userUid){
    firstName
    lastName
    uid
    classroomByClassroomUid {
      name
    }
  }
} `;

const GET_CLASSROOM = gql`query GetClassroom($classroomUid: UUID!){
  classroomByUid(uid: $classroomUid){
    name
    uid
    usersByClassroomUid {
      nodes {
        firstName
        uid
        lastName
      }
    }
  }
} `;

const GET_GROUP = gql`query GetGroup($groupUid: UUID!){
  groupByUid(uid: $groupUid){
    name
    uid
  }
} `;

const GET_PERIOD = gql`query GetPeriod($periodUid: UUID!){
  periodByUid(uid: $periodUid){
    name
    uid
  }
} `;

const GET_ACTIVITY = gql`query GetActivity($activityUid: UUID!){
  activityByUid(uid: $activityUid){
    uid
    name
    userByOrganizerUid {
      firstName
      lastName
    }
    startTime
    endTime
    placeByPlaceUid {
      name
    }
  }
} `;

const COPY_ACTIVITY = gql`mutation CopyActivity($activityUid: UUID!, $periodUid: UUID!) {
  copyActivity(
    input: { auid: $activityUid, puid: $periodUid }
  ) {
    clientMutationId
  }
}
`;

const COPY_ALL_CLASSROOM_ACTIVITIES = gql`mutation CopyAllClassroomActivities($sourcePeriodUid: UUID!, $targetPeriodUid: UUID!) {
  copyAllClassroomActivities(
    input: {sourcePeriodUid: $sourcePeriodUid, targetPeriodUid: $targetPeriodUid}
  ) {
    integer
  }
}
`;

const COPY_ALL_GROUP_ACTIVITIES = gql`mutation CopyAllGroupActivities($sourcePeriodUid: UUID!, $targetPeriodUid: UUID!) {
  copyAllGroupActivities(
    input: {sourcePeriodUid: $sourcePeriodUid, targetPeriodUid: $targetPeriodUid}
  ) {
    integer
  }
}
`;


const GET_CALENDAR = gql`
mutation GetAllAppointmentForUser($userUid: UUID!, $puid: UUID!) {
  getAllAppointmentForUser(input: { uUid: $userUid, pUid: $puid }) {
    appointments {
      day
      endTime
      startTime
      endDate
      startDate
      itemUid
      title
      type
      uidType
      appointmentUid
      place
      organizer
    }
  }
} `;

const GET_ALL_APPOINTMENT_FOR_CLASSROOM = gql`
mutation GetAllAppointementForClassroom($classroomUid: UUID!, $periodUid: UUID!) {
  getAllAppointementForClassroom(
    input: { cUid: $classroomUid, pUid: $periodUid }
  ) {
    appointments {
      endDate
      itemUid
      startDate
      title
      type
      place
      organizer
    }
  }
} `

const GET_ALL_APPOINTMENT_FOR_GROUP = gql`
mutation GetAllAppointementForGroup($groupUid: UUID!, $periodUid: UUID!) {
  getAllAppointementForGroup(
    input: { gUid: $groupUid, pUid: $periodUid }
  ) {
    appointments {
      endDate
      itemUid
      startDate
      title
      type
      place
      organizer
    }
  }
} `

const GET_ALL_APPOINTMENT_FOR_USER = gql`
mutation GetAllAppointmentForUser($userUid: UUID!, $periodUid: UUID!) {
  getAllAppointmentForUser(input: {uUid: $userUid, pUid: $periodUid}) {
    appointments {
      appointmentUid
      day
      endDate
      endTime
      itemUid
      startDate
      startTime
      type
      title
      uidType
      place
      organizer
    }
  }
}
`;

const COUNT_ACTIVITIES = gql`query CountActivities ($period_uid : UUID) {
  allActivities(condition: {periodUid: $period_uid}) {
    totalCount
  }
}`

const COUNT_USER_ACTIVITES = gql`query CountUserActivities($period_uid : UUID) {
  allActivities(condition: {periodUid: $period_uid}) {
    nodes {
      userActivitiesByActivityUid {
        totalCount
      }
    }
  }
}`;

const REGISTER_USER = gql`mutation RegisterUser ($firstName: String!, $lastName: String!, $email: String!, $password: String!, $classroomUid: UUID!) {
  registerUser(
    input: {
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password,
      classroomUid: $classroomUid}
  ) {
    user {
      firstName
      lastName
      uid
      userRole
      organizationUid
    }
  }
}
`;

const REGISTER_ADMIN = gql`mutation RegisterAdmin ($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
  registerAdmin(
    input: {
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password}
  ) {
    user {
      firstName
      lastName
      uid
      userRole
      organizationUid
    }
  }
}
`;

const REGISTER_SUPER_ADMIN = gql`mutation RegisterSuperAdmin ($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
  registerSuperAdmin(
    input: {
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      password: $password}
  ) {
    user {
      firstName
      lastName
      uid
      userRole
      organizationUid
    }
  }
}
`;

const UPDATE_PASSWORD_FOR_USER = gql`mutation UpdatePasswordForUser($userUid: UUID!, $password: String!) {
  updatePasswordForUser(input: {uUid: $userUid, password: $password}) {
    clientMutationId
  }
}`;

const UPDATE_PASSWORD = gql`mutation UpdatePassword($password: String!) {
  updatePassword(input: {password: $password}) {
    clientMutationId
  }
}`;

const GET_USER_LOGIN = gql`mutation GetLogin($userUid: UUID!) {
  getLogin(input: {uUid: $userUid}) {
    string
  }
}`;

const UNREGISTER_ACTIVITY_FOR_USER = gql`
mutation UnregisterActivityForUser ($userUid: UUID!, $activityUid: UUID!) {
    unregisterActivityForUser(input:{uUid: $userUid, aUid: $activityUid}) {
      clientMutationId
    }
  }  
`;

const REGISTER_ACTIVITY_FOR_USER = gql`
mutation RegisterActivityForUser ($userUid: UUID!, $activityUid: UUID!) {
    registerActivityForUser(input:{uUid: $userUid, aUid: $activityUid}) {
      clientMutationId
    }
  }  
`;

const FILL_ACTIVITY_FOR_CLASSROOMS = gql`mutation FillActivityForClassrooms ($activityUid: UUID!, $classroomUids: [UUID]!) {
  fillActivityForClassrooms(
    input: {auid: $activityUid, cuids: $classroomUids}
  ) {
    integer
  }
}
`;

const FILL_ACTIVITY_FOR_GROUPS = gql`mutation FillActivityForGroups ($activityUid: UUID!, $groupUids: [UUID]!) {
  fillActivityForGroups(
    input: {auid: $activityUid, guids: $groupUids}
  ) {
    integer
  }
}
`;

const MAX_TAKEN_ACTIVITY = gql`query MaxTakenActivity($filter: ActivitySuperAdminViewFilter!) {
  allActivitySuperAdminViews(orderBy: TAKEN_DESC, first: 1, filter: $filter) {
    nodes {
      taken
    }
  }
}`;

const MIN_TAKEN_ACTIVITY = gql`query MaxTakenActivity($filter: ActivitySuperAdminViewFilter!) {
  allActivitySuperAdminViews(orderBy: TAKEN_ASC, first: 1, filter: $filter) {
    nodes {
      taken
    }
  }
}`;

const MAX_REMAINING_ACTIVITY = gql`query MaxRemainingActivity($filter: ActivitySuperAdminViewFilter!) {
  allActivitySuperAdminViews(orderBy: REMAINING_DESC, first: 1, filter: $filter) {
    nodes {
      remaining
    }
  }
}`;


const MIN_REMAINING_ACTIVITY = gql`query MaxRemainingActivity($filter: ActivitySuperAdminViewFilter!) {
  allActivitySuperAdminViews(orderBy: REMAINING_ASC, first: 1, filter: $filter) {
    nodes {
      remaining
    }
  }
}`;

const MAX_TAKEN_ACTIVITY_ADMIN = gql`query MaxTakenActivityAdmin($filter: ActivityAdminViewFilter!) {
  allActivityAdminViews(orderBy: TAKEN_DESC, first: 1, filter: $filter) {
    nodes {
      taken
    }
  }
}`;

const MIN_TAKEN_ACTIVITY_ADMIN = gql`query MaxTakenActivityAdmin($filter: ActivityAdminViewFilter!) {
  allActivityAdminViews(orderBy: TAKEN_ASC, first: 1, filter: $filter) {
    nodes {
      taken
    }
  }
}`;

const MAX_REMAINING_ACTIVITY_ADMIN = gql`query MaxRemainingActivityAdmin($filter: ActivityAdminViewFilter!) {
  allActivityAdminViews(orderBy: REMAINING_DESC, first: 1, filter: $filter) {
    nodes {
      remaining
    }
  }
}`;


const MIN_REMAINING_ACTIVITY_ADMIN = gql`query MaxRemainingActivityAdmin($filter: ActivityAdminViewFilter!) {
  allActivityAdminViews(orderBy: REMAINING_ASC, first: 1, filter: $filter) {
    nodes {
      remaining
    }
  }
}`;

const GET_CONCURENT_ACTIVITY_FOR_GROUP_ACTIVITY = gql`mutation GetConcurentActivityForGroupActivity($groupActivityUid: UUID!) {
  getConcurentActivityForGroupActivity(input: {gaUid: $groupActivityUid}) {
    userActivities {
      userByUserUid {
        uid
        lastName
        firstName
      }
      activityByActivityUid {
        uid
        name
        startTime
        endTime
      }
    }
  }
}`;

const GET_CONCURENT_ACTIVITY_FOR_ACTIVITY = gql`query GetConcurentActivityForActivity($activityUid: UUID!) {
  getConcurentActivityForActivity(aUid: $activityUid) {
    nodes {
      userByUserUid {
        uid
        lastName
        firstName
      }
      activityByActivityUid {
        uid
        name
        startTime
        endTime
      }
    }
  }
}`;

const GET_NB_CONCURENT_ACTIVITY_FOR_ACTIVITY = gql`query GetConcurentActivityForActivity($activityUid: UUID!) {
  getConcurentActivityForActivity(aUid: $activityUid) {
    totalCount
  }
}`;

const LIST_ACTIVITIES_FOR_USER = gql`query listActivitiesForUser($userUid: UUID!, $first: Int, $offset: Int = 0, $filter: ActivitySuperAdminViewFilter!) {
  listActivitiesForUser(
    uUid: $userUid
    first: $first,
    offset: $offset,
    filter: $filter,
    orderBy: [DAY_ASC, START_TIME_ASC, NAME_ASC]
  ) {
    nodes {
      uid
      name
      day
      startTime
      placeUid
      endTime
      capacity
      remaining
      taken
      placeByPlaceUid {
        uid
        name
      }
      activityGroupsByActivityUid {
        nodes {
          groupByGroupUid {
            uid
            name
          }
        }
      }
      activityClassroomsByActivityUid {
        nodes {
          classroomByClassroomUid {
            uid
            name
            gradeUid
          }
        }
      }
      userByOrganizerUid {
        uid
        firstName
        lastName
      }
      periodByPeriodUid {
        uid
        name
      }
    }
    totalCount
  }
}`;


const LIST_ACTIVITIES_FOR_CLASSROOMS = gql`query ListActivitiesForClassrooms($classroomUids: [UUID]!, $first: Int, $offset: Int = 0, $filter: ActivitySuperAdminViewFilter!) {
  listActivitiesForClassrooms(
    cUids: $classroomUids
    first: $first,
    offset: $offset,
    filter: $filter,
    orderBy: [DAY_ASC, START_TIME_ASC, NAME_ASC]
    ) {
      nodes {
        uid
        name
        day
        startTime
        placeUid
        endTime
        capacity
        remaining
        taken
        placeByPlaceUid {
          uid
          name
        }
        activityGroupsByActivityUid {
          nodes {
            groupByGroupUid {
              uid
              name
            }
          }
        }
        activityClassroomsByActivityUid {
          nodes {
            classroomByClassroomUid {
              uid
              name
              gradeUid
            }
          }
        }
        userByOrganizerUid {
          uid
          firstName
          lastName
        }
        periodByPeriodUid {
          uid
          name
        }
      }
      totalCount
  }
}`;

const LIST_PROCESS_CALENDAR = gql`query ListProcessCalendar ($periodUid: UUID!, $userUids: [UUID!]!) {
  allCalendarProcesses(
    filter: { 
      periodUid: {equalTo: $periodUid}
      userUid: {in: $userUids}
    }
    orderBy: CREATED_AT_DESC
  ) {
    nodes {
      comment
      createdAt
      userUid
      userByCreatedBy {
        firstName
        lastName
      }
      userByUserUid {
        lastName
        firstName
      }
      calendarState
    }
  }
}`;

const CREATE_CALENDAR_PROCESS = gql`mutation CreateCalendarProcess($userUid: UUID!, $calendarState: CalendarState!, $periodUid: UUID!, $comment: String) {
  createCalendarProcess(
    input: {calendarProcess: {userUid: $userUid, periodUid: $periodUid, calendarState: $calendarState, comment: $comment}}
  ) {
    calendarProcess {
      comment
      calendarState
    }
  }
}`;

const STATS_PROCESS_CALENDAR = gql`query StatsProcessCalendar($periodUid: UUID!, $userUids: [UUID!]!, $classroomUids: [UUID!]!) {
  wait_super_admin: allCalendarProcesses(
    filter: {calendarState: {equalTo: WAITTING_SUPER_ADMIN}, periodUid: {equalTo: $periodUid}, userUid: {in: $userUids}}
  ) {
    totalCount
  }
  wait_admin: allCalendarProcesses(
    filter: {calendarState: {equalTo: WAITTING_ADMIN}, periodUid: {equalTo: $periodUid}, userUid: {in: $userUids}}
  ) {
    totalCount
  }
  wait_user: allCalendarProcesses(
    filter: {calendarState: {equalTo: WAITTING_USER}, periodUid: {equalTo: $periodUid}, userUid: {in: $userUids}}
  ) {
    totalCount
  }
  approved: allCalendarProcesses(
    filter: {calendarState: {equalTo: APPROVED}, periodUid: {equalTo: $periodUid}, userUid: {in: $userUids}}
  ) {
    totalCount
  }
  total: allUsers(
    filter: {userRole: {equalTo: HORAROJ_USER}, classroomUid: {in: $classroomUids}}
  ) {
    totalCount
  }
}`;

const GET_ACTIVITIES_DISTRIBUTION = gql`query GetActivitiesDistribution ($periodUid: UUID!) {
  getActivitiesDistribution(aPeriodUid: $periodUid) {
    nodes{
      nb
        startTime
        day
    }
  }
}
`;




export {
  CREATE_ACTIVITY,
  CREATE_CLASSROOM_ACTIVITY,
  CREATE_GROUP_ACTIVITY,
  CREATE_PERIOD,
  CREATE_CLASSROOM,
  CREATE_GROUP,
  CREATE_USER,
  CREATE_USER_GROUP,
  CREATE_ADMIN_CLASSROOM,
  CREATE_ADMIN,
  CREATE_PLACE,
  CREATE_GRADE,
  CREATE_PERIOD_GRADE,
  CREATE_ACTIVITY_GROUP,
  CREATE_ACTIVITY_CLASSROOM,
  CREATE_CLASSROOM_ACTIVITY_USER,

  LIST_GROUPS,
  LIST_CLASSROOMS,
  LIST_CLASSROOMS_FOR_ADMIN,
  LIST_PERIODS,
  LIST_PERIODS_INFO_ADMIN,
  LIST_ACTIVITIES_SUPER_ADMIN,
  LIST_ACTIVITIES_ADMIN,
  LIST_ACTIVITIES_FOR_USER,
  LIST_ACTIVITIES_FOR_CLASSROOMS,
  LIST_CLASSROOM_ACTIVITIES,
  LIST_GROUP_ACTIVITIES,
  LIST_USERS,
  LIST_ADMINS,
  LIST_SUPER_ADMINS,
  LIST_PLACES,
  LIST_AVAILABLE_PLACES,
  LIST_GRADES,
  LIST_CLASSROOM_ACTIVITY_USERS,

  LIST_GRADES_AND_CLASSROOMS_BY_PERIOD,
  LIST_GRADES_CLASSROOMS_USERS_BY_PERIOD,
  LIST_USER_BY_GROUP,
  LIST_USER_BY_ACTIVITY,

  DELETE_ACTIVITY,
  DELETE_CLASSROOM_ACTIVITY,
  DELETE_GROUP_ACTIVITY,
  DELETE_ACTIVITY_GROUP,
  DELETE_ACTIVITY_CLASSROOM,
  DELETE_GROUP,
  DELETE_CLASSROOM,
  DELETE_USER,
  DELETE_USER_GROUP,
  DELETE_ADMIN_CLASSROOM,
  DELETE_PERIOD,
  DELETE_PLACE,
  DELETE_GRADE,
  DELETE_PERIOD_GRADE,
  DELETE_CLASSROOM_ACTIVITY_USER,

  GET_USER,
  GET_CLASSROOM,
  GET_GROUP,
  GET_PERIOD,
  GET_ACTIVITY,
  COPY_ACTIVITY,
  COPY_ALL_CLASSROOM_ACTIVITIES,
  COPY_ALL_GROUP_ACTIVITIES,
  GET_CALENDAR,
  GET_ALL_APPOINTMENT_FOR_CLASSROOM,
  GET_ALL_APPOINTMENT_FOR_GROUP,
  GET_ALL_APPOINTMENT_FOR_USER,
  GET_ACTIVITIES_DISTRIBUTION,

  UPDATE_ACTIVITY,
  UPDATE_GROUP,
  UPDATE_GROUP_ACTIVITY,
  UPDATE_CLASSROOM_ACTIVITY,
  UPDATE_GRADE,
  UPDATE_USER,
  UPDATE_PERIOD,

  UPDATE_PASSWORD_FOR_USER,
  UPDATE_PASSWORD,
  GET_USER_LOGIN,

  COUNT_ACTIVITIES,
  COUNT_USER_ACTIVITES,

  REGISTER_USER,
  REGISTER_ADMIN,
  REGISTER_SUPER_ADMIN,

  REGISTER_ACTIVITY_FOR_USER,
  UNREGISTER_ACTIVITY_FOR_USER,

  FILL_ACTIVITY_FOR_CLASSROOMS,
  FILL_ACTIVITY_FOR_GROUPS,

  MAX_TAKEN_ACTIVITY,
  MAX_REMAINING_ACTIVITY,
  MIN_TAKEN_ACTIVITY,
  MIN_REMAINING_ACTIVITY,
  MAX_TAKEN_ACTIVITY_ADMIN,
  MAX_REMAINING_ACTIVITY_ADMIN,
  MIN_TAKEN_ACTIVITY_ADMIN,
  MIN_REMAINING_ACTIVITY_ADMIN,

  GET_CONCURENT_ACTIVITY_FOR_GROUP_ACTIVITY,
  GET_CONCURENT_ACTIVITY_FOR_ACTIVITY,
  GET_NB_CONCURENT_ACTIVITY_FOR_ACTIVITY,

  CREATE_CALENDAR_PROCESS,
  LIST_PROCESS_CALENDAR,

  STATS_PROCESS_CALENDAR
}