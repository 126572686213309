import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Toolbar, IconButton, Typography, Button, List, Select, MenuItem, Divider } from '@mui/material';

import { MenuIcon, ActivityIcon, AccountCircleIcon } from '../Icons';
import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER, LIST_USER_PERIODS } from "../../graphql/user-requests";
import jwt_decode from "jwt-decode";
import Calendar from './Calendar';
import Profile from '../AdminComponents/Profile';
import ActivityList from './ActivityList/ActivityList';
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from '../Icons';
import { Route, Routes, useLocation, createSearchParams, useNavigate } from "react-router-dom";
import { periodSort } from '../../utils/sort';

import CustomMenuItem from '../AdminComponents/subcomponents/CustomMenuItem';
import { AppBar, Drawer, DrawerHeader } from '../../utils/commons';

function LoggedApp(props) {
    const urlSeachParams = new URLSearchParams(useLocation().search)
    const navigate = useNavigate();
    const [period, setPeriod] = useState({});
    let [open, setOpen] = useState(true);
    let [user, setUser] = useState({});
    let [periods, setPeriods] = useState([]);

    const updatePeriod = newPeriod => {
        navigate({
            pathname: ".",
            search: createSearchParams({
                periodUid: newPeriod.uid
            }).toString(),
        }, {
            relative: "path"
        })
        selectPeriod(periods, newPeriod.uid);
    }

    const selectPeriod = (periods, periodUid) => {
        if (periodUid) {
            let p = periods.find(p => p.uid === periodUid);
            if (p) {
                setPeriod(p);
            }
        } else {
            const firstPeriod = periods.sort(periodSort)[0];
            if (firstPeriod) { setPeriod(firstPeriod) }
        }
    }

    function switchOpen() {
        setOpen(!open);
    }

    function handleLogout() {
        localStorage.removeItem("HorarojJWTToken");
        setUser(null);
        props.setLogged(false);
        navigate("/");
    }

    const [getUser] = useLazyQuery(GET_USER, {
        onCompleted: (data) => {
            setUser(data.userByUid);
        }
    });

    const [getPeriods] = useMutation(LIST_USER_PERIODS, {
        onCompleted: (data) => {
            const periods = data.getPeriodsAllInfoUser.periodInfos;
            if (periods.length > 0) {
                setPeriods(periods);
                selectPeriod(data.getPeriodsAllInfoUser.periodInfos, urlSeachParams.get("periodUid"));
            }
        }
    });


    const theme = createTheme(useTheme(), {});


    useEffect(() => {
        const token = localStorage.getItem("HorarojJWTToken");
        if (token) {
            const decodedToken = jwt_decode(token);
            getUser({ variables: { userUid: decodedToken.user_uid } });
            getPeriods({});
        }
    }, [getUser, getPeriods]);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open} color="user">
                    <Toolbar>
                        <IconButton
                            onClick={switchOpen}
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography component="div" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <img src="/assets/img/saas-c/logo/logo-wiiik.png" alt="Logo Wiiik" /> {user && user.firstName} {user && user.lastName} ({user && user.classroomByClassroomUid && user.classroomByClassroomUid.name})
                        </Typography>

                        <Button onClick={handleLogout} color="inherit">Se déconnecter</Button>
                    </Toolbar>
                </AppBar>
                <Drawer variant={['xs', 'sm', 'md'].includes(props.width) ? "temporary" : "permanent"} open={open}>
                    <DrawerHeader>
                        <IconButton onClick={switchOpen}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    {period && periods[0] && <Select size="small" onChange={event => updatePeriod(event.target.value)} value={period} >
                        {periods.map(p => (<MenuItem key={p.uid} value={p}>{p.name} ({p.openUser ? "Ouvert" : "Fermé"})</MenuItem>))}
                    </Select>}
                    <List>
                        <CustomMenuItem open={open} title="Activités" link={{ pathname: "/activities", search: useLocation().search }} hideTooltip={open} icon={<ActivityIcon />} />
                        <CustomMenuItem open={open} title="Emploi du temps" link={{ pathname: "/calendar", search: useLocation().search }} hideTooltip={open} icon={<CalendarIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <CustomMenuItem open={open} title="Mon profil" link={{ pathname: "/profile", search: useLocation().search }} hideTooltip={open} icon={<AccountCircleIcon />} />
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <DrawerHeader />
                    <Routes>
                        <Route path="/activities" element={<ActivityList period={period} user={user} />} />
                        <Route path="/calendar" element={<Calendar period={period} user={user} />} />
                        <Route path="/profile" element={<Profile period={period} user={user} />} />
                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default LoggedApp;