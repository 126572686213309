import { Box, Button, FormControlLabel, Slider, TextField, Typography, InputAdornment, SvgIcon, Grid } from '@mui/material';
import { SearchIcon } from "../../Icons"
import { useQuery } from "@apollo/client";

import Select from "react-select";
import AsyncSelect from "react-select/async"
import CUModal from '../modals/CUModal';
import { useState } from 'react';
import CUActivity from "../CreateUpdateComponents/Activity";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { LIST_ADMINS } from "../../../graphql/admin-requests";


import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import { Link } from 'react-router-dom';


const ActivityListToolbar = ({
    user,
    period,
    updateGradeFilter,
    gradesOptions,
    maxRemaining,
    minRemaining,
    setRemainingFilter,
    maxTaken,
    minTaken,
    setTakenFilter,
    adminView,
    loadData,
    daysOptions,
    dayFilter,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    updateDayFilter,
    updateAdminFilter,
    updateSearch,
    ...props }) => {

    const actionTitle = adminView ? "Proposer" : "Ajouter";

    const [openCUModal, setOpenCUModal] = useState(false);
    const handleClose = () => { setOpenCUModal(false); loadData(); }

    const useImperativeQuery = (query) => {
        const { refetch } = useQuery(query, { skip: true });
        return (variables) => { return refetch(variables); }
    }

    const callQuery = useImperativeQuery(LIST_ADMINS);


    const promiseOptions = async inputValue => {
        const searchF = {
            or: [{ lastName: { includesInsensitive: inputValue } },
            { firstName: { includesInsensitive: inputValue } }
            ]
        };
        const { data } = await callQuery({ filter: { ...searchF } });
        return data.allUsers.nodes.map(i => { return { value: i.uid, label: `${i.lastName} ${i.firstName}` } });
    }



    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale("fr")}>
            {openCUModal && (!adminView || period.openAdmin) && <CUModal open={openCUModal} adminView={adminView} handleClose={handleClose} component={CUActivity} period={period} actionTitle={actionTitle} user={user} />}
            <Box {...props}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} >
                    {!adminView && <Link to="/superadmins/print-activities" style={{ textDecoration: 'none' }}><Button style={{ marginLeft: '10px' }} color="primary" variant="contained"> Liste complete</Button></Link>}
                    {(!adminView || period.openAdmin) && <Button style={{ marginLeft: '10px' }} color="primary" variant="contained" onClick={() => setOpenCUModal(true)}>{actionTitle}</Button>}
                </Box >
                <Box pb={4}>
                    <Grid container spacing={1} paddingTop={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                size="small"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Rechercher une activité"
                                variant="outlined"
                                onChange={updateSearch}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TimePicker
                                renderInput={(props) => <TextField {...props} size="small" fullWidth />}
                                invalidDateMessage="Format d'heure invalide"
                                label="Démarre après"
                                inputVariant="outlined"
                                minutesStep={5}
                                size="small"
                                value={startTime}
                                ampm={false}
                                onChange={setStartTime}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['clear', 'accept']
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TimePicker
                                style={{ zIndex: 0 }}
                                renderInput={(props) => <TextField {...props} size="small" fullWidth />}
                                invalidDateMessage="Format d'heure invalide"
                                minutesStep={5}
                                label="Termine avant"
                                inputVariant="outlined"
                                size="small"
                                value={endTime}
                                ampm={false}
                                onChange={setEndTime}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['clear', 'accept']
                                    }
                                }}
                            />
                        </Grid>
                        {!adminView &&
                            <Grid item xs={12} md={12} lg={4}>
                                <Select
                                    minMenuHeight={1000}
                                    placeholder="Niveaux..."
                                    isClearable={true}
                                    isSearchable={true}
                                    name="grades"
                                    isMulti
                                    onChange={updateGradeFilter}
                                    options={gradesOptions}
                                    defaultValue={[]}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={12} lg={4}>
                            <Select
                                minMenuHeight={1000}
                                placeholder="Jours..."
                                isClearable={true}
                                isSearchable={true}
                                defaultValue={[]}
                                name="day"
                                isMulti
                                onChange={updateDayFilter}
                                options={daysOptions}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <AsyncSelect
                                placeholder="Enseignants/Intervenants"
                                noOptionsMessage={() => "Aucun enseignant/intervenant trouvé"}
                                isMulti
                                defaultOptions={true}
                                onChange={updateAdminFilter}
                                loadOptions={promiseOptions}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            {maxTaken > 0 && <div><Typography id="booked-slider" gutterBottom>Places réservées</Typography>
                                <FormControlLabel
                                    style={{ width: "100%" }}
                                    control={
                                        <Slider
                                            size="small"
                                            style={{ marginLeft: "20px", marginRight: "10px" }}
                                            orientation="horizontal"
                                            valueLabelDisplay="auto"
                                            marks={[{ value: minTaken, label: minTaken }, { value: maxTaken, label: maxTaken }]}
                                            min={minTaken}
                                            getAriaLabel={v => `${v} places`}
                                            max={maxTaken}
                                            onChangeCommitted={(e, v) => setTakenFilter(v)}
                                            defaultValue={[minTaken, maxTaken]}
                                            aria-labelledby="booked-slider"
                                        />}
                                /></div>}
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            {maxRemaining > 0 && <div>
                                <Typography id="dispo-slider" gutterBottom>Places disponibles</Typography>
                                <FormControlLabel
                                    style={{ width: "100%" }}
                                    control={
                                        <Slider
                                            size="small"
                                            style={{ marginLeft: "20px", marginRight: "10px" }}
                                            orientation="horizontal"
                                            valueLabelDisplay="auto"
                                            marks={[{ value: minRemaining, label: minRemaining }, { value: maxRemaining, label: maxRemaining }]}
                                            min={minRemaining}
                                            getAriaLabel={v => `${v} places`}
                                            max={maxRemaining}
                                            onChangeCommitted={(e, v) => setRemainingFilter(v)}
                                            defaultValue={[minRemaining, maxRemaining]}
                                            aria-labelledby="dispo-slider"
                                        />} />
                            </div>}
                        </Grid>
                    </Grid>
                </Box>
            </Box >
        </LocalizationProvider >
    )
};

export default ActivityListToolbar;