import { Modal, Paper, IconButton } from '@mui/material';
import { CancelIcon } from '../../Icons';


function CUModal({ open, handleClose, component, ...props }) {
    const closeButton = { cursor: 'pointer', float: 'right' };

    return (
        <Modal
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            open={open}
            onClose={handleClose}
            disableEscapeKeyDown={false}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <Paper>
                <IconButton style={closeButton} size="small" onClick={handleClose}><CancelIcon /></IconButton>
                {component({ onFinish: handleClose, ...props })}
            </Paper>
        </Modal>)
}

export default CUModal;