import { Chip } from '@mui/material';
import { CalendarCheckIcon, CheckIcon, HourGlassIcon, LockIcon } from '../components/Icons';


const canProcess = (lastProcess) => {
    if (!lastProcess.calendarState || lastProcess.calendarState === 'WAITTING_USER')
        return true;
    return false;
}

const stateChip = (state, mapp, onClick) => {

    const mapping = mapp[state];
    if (mapping) {
        return <Chip size="small" icon={mapping.icon} style={{ backgroundColor: mapping.backgroundColor, color: mapping.color }} label={mapping.title} onClick={onClick} />
    } else {
        return <Chip size="small" label="Information non trouvée" />
    }
}

const userProcessCalendarMapping = {
    'DEFAULT': { clickable: true, icon: <CalendarCheckIcon style={{ color: '#FFFFFF' }} />, backgroundColor: '#E55246', color: '#FFFFFF', title: "Valider mon emploi du temps" },
    'WAITTING_USER': { clickable: true, icon: <CalendarCheckIcon style={{ color: '#FFFFFF' }} />, backgroundColor: "#E55246", color: '#FFFFFF', title: "Revalider mon emploi du temps" },
    'WAITTING_ADMIN': { clickable: false, icon: <LockIcon style={{ color: '#FFFFFF' }} />, backgroundColor: "#DB7A13", color: '#FFFFFF', title: "En attente d'approbation" },
    'WAITTING_SUPER_ADMIN': { clickable: false, icon: <LockIcon style={{ color: '#FFFFFF' }} />, backgroundColor: "#138D75", color: '#FFFFFF', title: "En attente d'approbation finale" },
    'APPROVED': { clickable: false, icon: <LockIcon style={{ color: '#FFFFFF' }} />, backgroundColor: "#138D75", color: '#FFFFFF', title: "Parcours validé" }
}

const adminProcessCalendarMapping = {
    'DEFAULT': { clickable: true, icon: <HourGlassIcon style={{ color: "#FFFFFF" }} />, backgroundColor: '#E55246', color: '#FFFFFF', title: "Validation élève en attente" },
    'WAITTING_USER': { clickable: false, icon: <HourGlassIcon style={{ color: "#FFFFFF" }} />, backgroundColor: "#E55246", color: '#FFFFFF', title: "Renvoyé à l'élève" },
    'WAITTING_ADMIN': { clickable: true, icon: <CalendarCheckIcon style={{ color: "#FFFFFF" }} />, backgroundColor: "#DB7A13", color: '#FFFFFF', title: "En attente d'approbation" },
    'WAITTING_SUPER_ADMIN': { clickable: false, icon: <CalendarCheckIcon style={{ color: "#FFFFFF" }} />, backgroundColor: "#138D75", color: '#FFFFFF', title: "En attente d'approbation finale" },
    'APPROVED': { clickable: false, icon: <CheckIcon style={{ color: "#FFFFFF" }} />, backgroundColor: "#138D75", color: '#FFFFFF', title: "Parcours validé" },
}

const superAdminProcessCalendarMapping = {
    'DEFAULT': { clickable: true, icon: <HourGlassIcon style={{ color: "#FFFFFF" }} />, backgroundColor: '#E55246', color: '#FFFFFF', title: "Validation élève en attente" },
    'WAITTING_USER': { clickable: true, icon: <HourGlassIcon style={{ color: "#FFFFFF" }} />, backgroundColor: "#E55246", color: '#FFFFFF', title: "Renvoyé à l'élève" },
    'WAITTING_ADMIN': { clickable: true, icon: <CalendarCheckIcon style={{ color: "#FFFFFF" }} />, backgroundColor: "#DB7A13", color: '#FFFFFF', title: "En attente d'approbation" },
    'WAITTING_SUPER_ADMIN': { clickable: true, icon: <CalendarCheckIcon style={{ color: "#FFFFFF" }} />, backgroundColor: "#138D75", color: '#FFFFFF', title: "En attente d'approbation finale" },
    'APPROVED': { clickable: true, icon: <CheckIcon style={{ color: "#FFFFFF" }} />, backgroundColor: "#138D75", color: '#FFFFFF', title: "Parcours validé" },
}

export { superAdminProcessCalendarMapping, userProcessCalendarMapping, adminProcessCalendarMapping, canProcess, stateChip }