import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


const ClassroomActivityListConfirmDelete = ({ activity, onConfirm, onClose, open, ...rest }) => {

    const handleConfirm = async () => {
        await onConfirm();
        onClose();
    }
    return (
        <div>
            <Dialog keepMounted open={open} onClose={onClose}>
                <DialogTitle>Supprimer une activité</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vous êtes sur le point de <strong>supprimer {activity.name}</strong>.<br />
                    Etes vous sûr ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">Annuler</Button>
                    <Button onClick={handleConfirm} color="primary">Confirmer</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default ClassroomActivityListConfirmDelete;