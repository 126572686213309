import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Toolbar, IconButton, Typography, List, Select, MenuItem } from '@mui/material';
import { MenuIcon, ActivityIcon } from '../Icons';
import { useState, useEffect } from 'react';
import { useLazyQuery } from "@apollo/client";
import { GET_USER, LIST_PERIODS } from "../../graphql/admin-requests";
import Calendar from './UserApp/Calendar';
import ActivityList from './UserApp/ActivityList/ActivityList';
import { ChevronLeftIcon, ChevronRightIcon, CalendarIcon } from '../Icons';
import { Routes, Route } from "react-router-dom";

import { useParams } from "react-router-dom";
import CustomMenuItem from '../AdminComponents/subcomponents/CustomMenuItem';
import { AppBar, Drawer, DrawerHeader } from '../../utils/commons';


function SuperAdminUserApp(props) {

    const theme = createTheme(useTheme(), {});
    const { userUid, periodUid } = useParams();

    let [open, setOpen] = useState(true);
    let [user, setUser] = useState();
    let [period, setPeriod] = useState("");
    let [periods, setPeriods] = useState([]);


    function switchOpen() {
        setOpen(!open);
    }

    const [getUser] = useLazyQuery(GET_USER, {
        onCompleted: (data) => {
            setUser(data.userByUid);
        }
    });

    const [getPeriods] = useLazyQuery(LIST_PERIODS, {
        onCompleted: (data) => {
            const allPeriods = data.allPeriods.nodes;
            const selectedPeriod = allPeriods.find(i => i.uid === periodUid);
            if (selectedPeriod) { setPeriod(selectedPeriod) }
            else {
                const firstPeriod = allPeriods[0];
                if (firstPeriod) { setPeriod(firstPeriod) }

            }
            setPeriods(data.allPeriods.nodes);
        }
    });

    useEffect(() => {
        getPeriods({});
        if (userUid)
            getUser({ variables: { userUid } });

    }, [getUser, getPeriods, userUid]);


    const handleSelectedPeriod = event => {
        setPeriod(event.target.value);
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open} color="user">
                    <Toolbar>
                        <IconButton
                            onClick={switchOpen}
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography component="div" variant="h6" color="inherit" noWrap>
                            <img src="/assets/img/saas-c/logo/logo-wiiik.png" alt="Logo Wiiik" /> {user && user.firstName} {user && user.lastName} ({user && user.classroomByClassroomUid && user.classroomByClassroomUid.name})
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer variant={['xs', 'sm', 'md'].includes(props.width) ? "temporary" : "permanent"} open={open}>
                    <DrawerHeader>
                        <IconButton onClick={switchOpen}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Select size="small" onChange={handleSelectedPeriod} value={period} >
                        {periods.map(p => (<MenuItem key={p.uid} value={p}>{p.name}</MenuItem>))}
                    </Select>
                    <List>
                        <CustomMenuItem title="Activités" open={open} link={`/userApp/${userUid}/${period.uid}/activities`} hideTooltip={open} icon={<ActivityIcon />} />
                        <CustomMenuItem title="Emploi du temps" open={open} link={`/userApp/${userUid}/${period.uid}/calendar`} hideTooltip={open} icon={<CalendarIcon />} />
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
                    <DrawerHeader />
                    <Routes>
                        <Route path="/activities" element={<ActivityList period={period} user={user} />} />
                        <Route path="/calendar" element={<Calendar period={period} />} />
                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default SuperAdminUserApp;