import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function UserDialog({ user, onConfirm, onCancel, open }) {

    return (
        <Dialog open={open} onClose={onCancel} >
            <DialogTitle >{"Ete vous sûr?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Vous êtes sur le point de supprimer l'utilisateur suivant: <strong>{user.firstName} {user.lastName}</strong>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">Annuler</Button>
                <Button onClick={onConfirm} color="primary" autoFocus>Supprimer</Button>
            </DialogActions>
        </Dialog>
    );
}