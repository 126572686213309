import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { EditIcon, LoginIcon, PasswordIcon } from '../../Icons';
import CUAdmin from "../CreateUpdateComponents/Admin";
import PasswordUpdate from "../CreateUpdateComponents/PasswordUpdate";
import UserLogin from "../CreateUpdateComponents/UserLogin";
import CUModal from '../modals/CUModal';
import CustomIconButton from "../subcomponents/CustomIconButton"
import { dbToLocale } from "../../../utils/time"

const SuperAdminListResults = ({ period, handleDeleteUser, users, totalCount, setLimit, limit, setOffset, offset, loadData, ...rest }) => {

    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [page, setPage] = useState(0);

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openEditPasswordModal, setOpenEditPasswordModal] = useState(false);
    const [openGetEmailModal, setOpenGetEmailModal] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState({});

    const handleSelectAll = (event) => {
        let newSelectedUserIds;

        if (event.target.checked) {
            newSelectedUserIds = users.map((user) => user.uid);
        } else {
            newSelectedUserIds = [];
        }

        setSelectedUserIds(newSelectedUserIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedUserIds.indexOf(id);
        let newSelectedUserIds = [];

        if (selectedIndex === -1) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds, id);
        } else if (selectedIndex === 0) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(1));
        } else if (selectedIndex === selectedUserIds.length - 1) {
            newSelectedUserIds = newSelectedUserIds.concat(selectedUserIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedUserIds = newSelectedUserIds.concat(
                selectedUserIds.slice(0, selectedIndex),
                selectedUserIds.slice(selectedIndex + 1)
            );
        }

        setSelectedUserIds(newSelectedUserIds);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
    };

    const handlePageChange = (event, newPage) => {
        setOffset(newPage * limit);
        setPage(newPage);
    };

    return (
        <Card {...rest}>
            {/* <PerfectScrollbar> */}
            <CUModal open={openEditModal} component={CUAdmin} handleClose={() => { setOpenEditModal(false); loadData() }} toUpdate={userToUpdate} />
            <CUModal open={openEditPasswordModal} component={PasswordUpdate} handleClose={() => { setOpenEditPasswordModal(false); loadData() }} toUpdate={userToUpdate} />
            {openGetEmailModal && <CUModal open={openGetEmailModal} component={UserLogin} handleClose={() => { setOpenGetEmailModal(false); loadData() }} toSee={userToUpdate} />}
            <Box sx={{ minWidth: 1050 }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={selectedUserIds.length === users.length}
                                    color="primary"
                                    indeterminate={
                                        selectedUserIds.length > 0
                                        && selectedUserIds.length < users.length
                                    }
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell size="small">Nom</TableCell>
                            <TableCell size="small">Prenom</TableCell>
                            <TableCell size="small">Date de création</TableCell>
                            <TableCell size="small">Dernière connexion</TableCell>
                            <TableCell size="small">Mot de passe mis à jour</TableCell>
                            <TableCell size="small">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.slice(0, limit).map((user) => (
                            <TableRow hover key={user.uid} selected={selectedUserIds.indexOf(user.uid) !== -1}>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedUserIds.indexOf(user.uid) !== -1}
                                        onChange={(event) => handleSelectOne(event, user.uid)}
                                        value="true"
                                    />
                                </TableCell>
                                <TableCell size="small">{user.lastName}</TableCell>
                                <TableCell size="small">{user.firstName}</TableCell>
                                <TableCell size="small">{moment(user.createdAt).format('DD/MM/YYYY')}</TableCell>
                                <TableCell size="small">{user?.lastLogin && moment(dbToLocale(user.lastLogin)).fromNow()}</TableCell>
                                <TableCell size="small">{user?.lastPasswordUpdate && moment(dbToLocale(user.lastPasswordUpdate)).fromNow()}</TableCell>
                                <TableCell size="small">

                                    <CustomIconButton
                                        onClick={() => {
                                            setUserToUpdate(user);
                                            setOpenGetEmailModal(true);
                                        }}
                                        title="Voir login"
                                        icon={<LoginIcon />} />

                                    <CustomIconButton
                                        onClick={() => {
                                            setUserToUpdate(user);
                                            setOpenEditModal(true);
                                        }}
                                        title="Modifier"
                                        icon={<EditIcon />} />

                                    <CustomIconButton
                                        onClick={() => {
                                            setUserToUpdate(user);
                                            setOpenEditPasswordModal(true);
                                        }}
                                        title="Modifier mot de passe"
                                        icon={<PasswordIcon />} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {/* </PerfectScrollbar> */}
            <TablePagination
                component="div"
                count={totalCount}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
            />
        </Card >
    );
};

SuperAdminListResults.propTypes = {
    users: PropTypes.array.isRequired
};

export default SuperAdminListResults;