import { Box, CircularProgress, Container } from "@mui/material";
import {
  LIST_ACTIVITIES_USER,
  REGISTER_ACTIVITY,
  GET_CALENDAR,
  UNREGISTER_ACTIVITY,
} from "../../../graphql/user-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from "react";
import ActivityListResults from "./ActivityListResults";
import ActivityListToolbar from "./ActivityListToolbar";
import moment from "moment";
import { useSnackbar } from "notistack";

function ActivityList({ period, user }) {
  const { enqueueSnackbar } = useSnackbar();

  const [activities, setActivities] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [isSearchEnabled, setSearchEnabled] = useState(true);

  let [dayFilter, setDayFilter] = useState("");
  let [nameFilter, setNameFilter] = useState("");
  let [startTime, setStartTime] = useState(null);
  let [endTime, setEndTime] = useState(null);
  let [filter, setFilter] = useState({});
  const [calendar, setCalendar] = useState([]);

  const [getActivities, { loading: loadingActivities }] = useLazyQuery(
    LIST_ACTIVITIES_USER,
    {
      onCompleted: (data) => {
        setActivities(data.allActivityUserViews.nodes);
        setTotalCount(data.allActivityUserViews.totalCount);
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const [getCalendar] = useMutation(GET_CALENDAR, {
    onCompleted: (data) => {
      setCalendar(data.getAllAppointment.appointments);
    },
    onError: (error) => console.error("Error retrieving activities", error),
  });

  const [registerActivity] = useMutation(REGISTER_ACTIVITY, {
    onError: ({ graphQLErrors, networkError, message, extraInfo }) => {
      const msg = graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message;
      let reason = "";
      if (msg) {
        if (msg.includes("Cannot register processed calendar")) {
          reason = "Vous avez déjà validé votre emploi du temps";
        }
        if (msg.includes("Period not openned")) {
          reason = "Les inscriptions pour cette période ne sont pas ouvertes";
        }
      }

      enqueueSnackbar(`Inscription impossible. ${reason}`, {
        variant: "warning",
      });
    },
    onCompleted: (d) => {
      enqueueSnackbar(
        `Inscription à l'activité ${d.registerActivity.userActivity.activityByActivityUid.name} validée`,
        { variant: "success" }
      );
    },
  });

  const [unRegisterActivity] = useMutation(UNREGISTER_ACTIVITY, {
    onError: ({ graphQLErrors, networkError, message, extraInfo }) => {
      const msg = graphQLErrors && graphQLErrors[0] && graphQLErrors[0].message;
      let reason = "";
      if (msg) {
        if (msg.includes("Cannot register processed calendar")) {
          reason = "Vous avez déjà validé votre emploi du temps";
        }
        if (msg.includes("Period not openned")) {
          reason = "Les inscriptions pour cette période ne sont pas ouvertes";
        }
      }

      enqueueSnackbar(`Désinscription impossible. ${reason}`, {
        variant: "warning",
      });
    },
    onCompleted: (d) => {
      enqueueSnackbar(`Désinscription de l'activité validée`, {
        variant: "success",
      });
    },
  });

  const updateSearch = (event) => {
    setNameFilter(event.target.value);
  };

  const handleUnregisterActivity = (activityUid) => {
    return async () => {
      const res = await unRegisterActivity({ variables: { activityUid } });
      refreshCalendar();
      return res;
    };
  };

  const handleRegisterActivity = (activityUid) => {
    return async () => {
      const res = await registerActivity({ variables: { activityUid } });
      refreshCalendar();
      return res;
    };
  };

  const refreshActivity = () => {
    if (period && period.uid) {
      const finalFilter = { ...filter };
      if (finalFilter.variables) {
        finalFilter.variables.first = limit;
        finalFilter.variables.offset = offset;
      }
      console.log("refreshActivity", finalFilter);
      getActivities(filter);
    }
  };

  const refreshCalendar = () => {
    console.log("refreshCalendar", { variables: { pUid: period.uid } });
    getCalendar({ variables: { pUid: period.uid } });
  };

  const refreshData = () => {
    refreshActivity();
    refreshCalendar();
  };

  const updateFilters = useCallback(() => {
    if (period && period.uid) {
      console.log("Update FILTERS 1", { ...filter });
      const dayF = dayFilter !== "" ? { day: { in: dayFilter } } : {};
      const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
      const startF = startTime
        ? {
            startTime: {
              greaterThanOrEqualTo: moment(startTime).format("HH:mm:00"),
            },
          }
        : {};
      const endF = endTime
        ? { endTime: { lessThanOrEqualTo: moment(endTime).format("HH:mm:00") } }
        : {};
      const periodF = { periodUid: { equalTo: period.uid } };
      const filters = { ...dayF, ...nameF, ...startF, ...endF, ...periodF };

      setFilter({ variables: { filter: filters } });
      console.log("Update FILTERS 2", { variables: { filter: filters } });
    }
  }, [dayFilter, nameFilter, startTime, endTime, period, setFilter]);

  useEffect(() => {
    updateFilters();
  }, [dayFilter, nameFilter, startTime, endTime, period]);

  useEffect(() => {
    refreshActivity();
  }, [page, limit, offset]);

  const onSubmit = async () => {
    setSearchEnabled(false);
    setOffset(0);
    setPage(0);
    updateFilters();
    refreshData();
  };

  const onPaginate = async (newPage, limit) => {
    setOffset(newPage * limit);
    setPage(newPage);
  };

  return (
    <Container component="main">
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 3 }}
      >
        <Container maxWidth={false}>
          <ActivityListToolbar
            onSubmit={onSubmit}
            isSearchEnabled={isSearchEnabled}
            setSearchEnabled={setSearchEnabled}
            dayFilter={dayFilter}
            startTime={startTime}
            endTime={endTime}
            setOffset={setOffset}
            setPage={setPage}
            updateSearch={updateSearch}
            setDayFilter={setDayFilter}
            setStartTime={setStartTime}
            setEndTime={setEndTime}
          />
          <Box sx={{ pt: 3 }}>
            {loadingActivities ? (
              <CircularProgress size={200} thickness={0.5} />
            ) : (
              <ActivityListResults
                activities={activities}
                onPaginate={onPaginate}
                isSearchEnabled={isSearchEnabled}
                onSubmit={onSubmit}
                calendar={calendar}
                user={user}
                limit={limit}
                offset={offset}
                page={page}
                totalCount={totalCount}
                setLimit={setLimit}
                setOffset={setOffset}
                setPage={setPage}
                canRegister={!!period.openUser}
                handleRegisterActivity={handleRegisterActivity}
                handleUnregisterActivity={handleUnregisterActivity}
              />
            )}
          </Box>
        </Container>
      </Box>
    </Container>
  );
}

export default ActivityList;
