import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import GradeListConfirmDelete from './GradeListConfirmDelete';
import { DeleteIcon, EditIcon, ClassroomIcon } from '../../Icons';
import CUModal from "../modals/CUModal";
import CUSimpleItem from "../CreateUpdateComponents/SimpleItem";
import { UPDATE_GRADE, CREATE_GRADE } from "../../../graphql/admin-requests";
import CustomIconButton from "../subcomponents/CustomIconButton";

const GradeListResults = ({ grades, totalCount, setLimit, limit, setOffset, offset, handleDeleteGrade, loadData, ...rest }) => {

    const [selectedGradeIds, setSelectedGradeIds] = useState([]);
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [toDelete, setToDelete] = useState({});
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [gradeToUpdate, setGradeToUpdate] = useState({});

    const handleSelectAll = (event) => {
        let newSelectedGradeIds;

        if (event.target.checked) {
            newSelectedGradeIds = grades.map((grade) => grade.uid);
        } else {
            newSelectedGradeIds = [];
        }

        setSelectedGradeIds(newSelectedGradeIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedGradeIds.indexOf(id);
        let newSelectedGradeIds = [];

        if (selectedIndex === -1) {
            newSelectedGradeIds = newSelectedGradeIds.concat(selectedGradeIds, id);
        } else if (selectedIndex === 0) {
            newSelectedGradeIds = newSelectedGradeIds.concat(selectedGradeIds.slice(1));
        } else if (selectedIndex === selectedGradeIds.length - 1) {
            newSelectedGradeIds = newSelectedGradeIds.concat(selectedGradeIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedGradeIds = newSelectedGradeIds.concat(
                selectedGradeIds.slice(0, selectedIndex),
                selectedGradeIds.slice(selectedIndex + 1)
            );
        }

        setSelectedGradeIds(newSelectedGradeIds);
    };

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const openConfirmDelete = (grade) => {
        setToDelete(grade);
        setOpenDialog(true);
    }

    const closeDialog = () => setOpenDialog(false);

    const handlePageChange = async (event, newPage) => {
        await setOffset(newPage * limit);
        await setPage(newPage);
    };

    return (
        <>
            <CUModal
                open={openUpdateModal}
                toUpdate={gradeToUpdate}
                handleClose={() => { setOpenUpdateModal(false); loadData() }}
                component={CUSimpleItem}
                itemName="niveau"
                createRequest={CREATE_GRADE}
                updateRequest={UPDATE_GRADE} />
            <GradeListConfirmDelete
                open={openDialog}
                grade={toDelete}
                onClose={closeDialog}
                onConfirm={() => handleDeleteGrade(toDelete.uid)}
            />
            <Card {...rest}>
                {/* <PerfectScrollbar> */}
                <Box>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedGradeIds.length === grades.length}
                                        color="primary"
                                        indeterminate={
                                            selectedGradeIds.length > 0
                                            && selectedGradeIds.length < grades.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell size="small">Nom</TableCell>
                                <TableCell size="small">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {grades.slice(0, limit).map((grade) => (
                                <TableRow hover key={grade.uid} selected={selectedGradeIds.indexOf(grade.uid) !== -1}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedGradeIds.indexOf(grade.uid) !== -1}
                                            onChange={(event) => handleSelectOne(event, grade.uid)}
                                            value="true"
                                        />
                                    </TableCell>
                                    <TableCell size="small">{grade.name}</TableCell>
                                    <TableCell size="small">
                                        <CustomIconButton
                                            link={`/superadmins/classrooms?grade=${grade.uid}`}
                                            title="Voir classes"
                                            icon={<ClassroomIcon />} />
                                        <CustomIconButton
                                            onClick={() => { setGradeToUpdate(grade); setOpenUpdateModal(true) }}
                                            title="Modifier"
                                            icon={<EditIcon />} />
                                        <CustomIconButton
                                            onClick={() => openConfirmDelete(grade)}
                                            title="Supprimer"
                                            icon={<DeleteIcon />} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                {/* </PerfectScrollbar> */}
                <TablePagination
                    component="div"
                    count={totalCount}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </Card >
        </ >
    );
};

GradeListResults.propTypes = {
    grades: PropTypes.array.isRequired
};

export default GradeListResults;