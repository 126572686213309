const activityTypeColor = {
    "activity": "#ffa62b",
    "classroomActivity": '#16697a',
    "userClassroomActivity": '#489fb5',
    "groupActivity": '#82c0cc'
}

const activityColor = (data) => {
    return data.customColor ? data.customColor : activityTypeColor[data.type];
}


export { activityColor, activityTypeColor }