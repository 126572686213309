import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Button, Box, Card, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import GroupListConfirmDelete from './GroupListConfirmDelete';
import { DeleteIcon, StudentIcon, EditIcon, CalendarIcon } from '../../Icons'
import CUModal from '../modals/CUModal';
import UsersByGroupList from '../UserByGroupList/UserByGroupList';
import { UPDATE_GROUP, CREATE_GROUP } from "../../../graphql/admin-requests";
import CUSimpleItem from "../CreateUpdateComponents/SimpleItem";
import CustomIconButton from "../subcomponents/CustomIconButton";


const GroupListResults = ({
    groups,
    totalCount,
    setLimit,
    limit,
    setOffset,
    offset,
    handleDeleteGroup,
    loadData,
    viewGroupCalendar,
    ...rest }) => {

    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [page, setPage] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [toDelete, setToDelete] = useState({});
    const [groupToUpdate, setGroupToUpdate] = useState({});
    const [openUpdateModal, setOpenUpdateModal] = useState(false);

    const [openUserListModal, setOpenUserListModal] = useState(false);
    const [groupUid, setGroupUid] = useState("");

    const showUserListModal = (uid) => {
        setGroupUid(uid);
        setOpenUserListModal(true);
    }


    const handleSelectAll = (event) => {
        let newSelectedGroupIds;

        if (event.target.checked) {
            newSelectedGroupIds = groups.map((group) => group.uid);
        } else {
            newSelectedGroupIds = [];
        }

        setSelectedGroupIds(newSelectedGroupIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedGroupIds.indexOf(id);
        let newSelectedGroupIds = [];

        if (selectedIndex === -1) {
            newSelectedGroupIds = newSelectedGroupIds.concat(selectedGroupIds, id);
        } else if (selectedIndex === 0) {
            newSelectedGroupIds = newSelectedGroupIds.concat(selectedGroupIds.slice(1));
        } else if (selectedIndex === selectedGroupIds.length - 1) {
            newSelectedGroupIds = newSelectedGroupIds.concat(selectedGroupIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedGroupIds = newSelectedGroupIds.concat(
                selectedGroupIds.slice(0, selectedIndex),
                selectedGroupIds.slice(selectedIndex + 1)
            );
        }

        setSelectedGroupIds(newSelectedGroupIds);
    };

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const openConfirmDelete = (group) => {
        setToDelete(group);
        setOpenDialog(true);
    }

    const closeDialog = () => setOpenDialog(false);

    const handlePageChange = async (event, newPage) => {
        await setOffset(newPage * limit);
        await setPage(newPage);
    };

    return (
        <>
            <CUModal open={openUserListModal} groupUid={groupUid} handleClose={() => setOpenUserListModal(false)} component={UsersByGroupList} />
            <CUModal
                open={openUpdateModal}
                toUpdate={groupToUpdate}
                handleClose={() => { setOpenUpdateModal(false); loadData() }}
                component={CUSimpleItem}
                itemName="groupe"
                createRequest={CREATE_GROUP}
                updateRequest={UPDATE_GROUP} />
            <GroupListConfirmDelete
                open={openDialog}
                group={toDelete}
                onClose={closeDialog}
                onConfirm={() => handleDeleteGroup(toDelete.uid)}
            />
            <Card {...rest}>
                {/* <PerfectScrollbar> */}
                <Box>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedGroupIds.length === groups.length}
                                        color="primary"
                                        indeterminate={
                                            selectedGroupIds.length > 0
                                            && selectedGroupIds.length < groups.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell size="small">Nom</TableCell>
                                <TableCell size="small">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groups.slice(0, limit).map((group) => (
                                <TableRow hover key={group.uid} selected={selectedGroupIds.indexOf(group.uid) !== -1}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selectedGroupIds.indexOf(group.uid) !== -1}
                                            onChange={(event) => handleSelectOne(event, group.uid)}
                                            value="true"
                                        />
                                    </TableCell>
                                    <TableCell size="small">{group.name}</TableCell>
                                    <TableCell size="small">
                                        <CustomIconButton
                                            title="Elèves"
                                            button={<Button
                                                onClick={() => showUserListModal(group.uid)}
                                                size="small"
                                                variant="outlined"
                                                color="default"
                                                endIcon={<StudentIcon />}
                                            >{group.userGroupsByGroupUid.totalCount}</Button>} />
                                        <CustomIconButton
                                            title="Voir emplois du temps"
                                            link={`/superadmins/calendars/group/${group.uid}`}
                                            icon={<CalendarIcon />} />
                                        <CustomIconButton
                                            onClick={() => {
                                                setGroupToUpdate(group); setOpenUpdateModal(true);
                                            }}
                                            title="Modifier"
                                            icon={<EditIcon />} />
                                        <CustomIconButton
                                            onClick={() => openConfirmDelete(group)}
                                            title="Supprimer"
                                            icon={<DeleteIcon />} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                {/* </PerfectScrollbar> */}
                <TablePagination
                    component="div"
                    count={totalCount}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </Card >
        </ >
    );
};

GroupListResults.propTypes = {
    groups: PropTypes.array.isRequired
};

export default GroupListResults;