import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { Box, Button, Checkbox, Container, Divider, Paper, FormControl, FormGroup, Switch, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
    CREATE_ACTIVITY,
    CREATE_ACTIVITY_GROUP,
    CREATE_ACTIVITY_CLASSROOM,
    LIST_GROUPS,
    LIST_PERIODS,
    LIST_ADMINS,
    LIST_PLACES,
    LIST_AVAILABLE_PLACES,
    LIST_GRADES_AND_CLASSROOMS_BY_PERIOD,
    UPDATE_ACTIVITY,
    DELETE_ACTIVITY_GROUP,
    DELETE_ACTIVITY_CLASSROOM
} from "../../../graphql/admin-requests";
import moment from "moment";
import { castPeriodDate } from "../../../utils/time";
import { computeItemUpdate } from "../../../utils/reducers";
import GradesClassroomsSelect from '../GradesClassroomsSelect';
import { useSnackbar } from 'notistack';



function Activity({ user, period, onFinish, toUpdate = {}, adminView = false, actionTitle }) {
    const { enqueueSnackbar } = useSnackbar();


    let [name, setName] = useState("");
    let [startTime, setStartTime] = useState(moment().hour(15).minute(30));
    let [endTime, setEndTime] = useState(moment().hour(16).minute(20));
    let [day, setDay] = useState("MONDAY");
    let [capacity, setCapacity] = useState("");
    let [groupsState, setGroupsState] = useState([]);
    let [periodUid, setPeriodUid] = useState('');
    let [periods, setPeriods] = useState([]);
    let [places, setPlaces] = useState([]);
    let [showAvailableOnly, setShowAvailableOnly] = useState(true);
    let [placeUid, setPlaceUid] = useState('');
    let [users, setUsers] = useState([]);
    let [organizerUid, setOrganizerUid] = useState('');
    let [classroomOptions, setClassroomOptions] = useState([]);
    let [classroomsState, setClassroomsState] = useState([]);

    let [validationErrors, setValidationErrors] = useState({});

    let [activityTitle, setActivityTitle] = useState("Nouvelle activité");

    const handleError = async (e) => {
        enqueueSnackbar("Une erreur s'est produite !");
    }

    const handleCheckBoxes = (event) => {
        const newGroupState = [...groupsState];
        newGroupState.find(g => g.uid === event.target.value).checked = event.target.checked;
        setGroupsState(newGroupState);
    }


    const [getClassrooms] = useLazyQuery(LIST_GRADES_AND_CLASSROOMS_BY_PERIOD, {
        onCompleted: (data) => {
            const options = data.allPeriodGrades.nodes.map(i => {
                const grade = i.gradeByGradeUid;
                return {
                    uid: grade.uid,
                    name: grade.name,
                    choices: grade.classroomsByGradeUid.nodes.map(classroom => {
                        return {
                            uid: classroom.uid,
                            name: classroom.name,
                            gradeUid: grade.uid,
                        }
                    })
                }
            });


            const cState = data.allPeriodGrades.nodes
                .map(({ gradeByGradeUid: { classroomsByGradeUid: { nodes }, uid } }) => {
                    return nodes.map(n => { return { ...n, checked: false, gradeUid: uid } })
                })
                .flat();

            setClassroomOptions(options);
            setClassroomsState(cState);

            if (toUpdate.uid) {
                const initialClassrooms = toUpdate.activityClassroomsByActivityUid.nodes.map(i => i.classroomByClassroomUid.uid);
                setClassroomsState(c => c.map(i => { return { ...i, checked: initialClassrooms.includes(i.uid) } }));
            }

        },
        fetchPolicy: 'cache-and-network'
    });

    const checkGroups = useCallback(() => {
        if (toUpdate.uid) {
            const initialGroups = toUpdate.activityGroupsByActivityUid.nodes.map(i => i.groupByGroupUid.uid);
            setGroupsState(g => g.map(i => { return { ...i, checked: initialGroups.includes(i.uid) } }));
        }
    }, [toUpdate]);

    const handlePeriodSelect = useCallback((event) => {
        const pUid = event.target.value;
        getClassrooms({ variables: { periodUid: pUid } });
        setPeriodUid(pUid);
    }, [getClassrooms])


    const setCapacityInt = (value) => {
        const intValue = parseInt(value, 10);
        (Number.isInteger(intValue)) ? setCapacity(intValue) : setCapacity("");
    }

    const handlePlaceSelect = event => {
        if (capacity === "") {
            const salle = places.find(i => i.uid === event.target.value)
            if (salle && salle.capacity)
                setCapacityInt(salle.capacity);
        }
        setPlaceUid(event.target.value);
    }

    const handleOrganizerSelect = event => {
        setOrganizerUid(event.target.value);
    }

    const [updateActivity] = useMutation(UPDATE_ACTIVITY, {
        onCompleted: async (data) => {
            const activityUid = data.updateActivityByUid.activity.uid;
            const initialGroups = toUpdate.activityGroupsByActivityUid.nodes.map(i => i.groupByGroupUid.uid);
            const initialClassrooms = toUpdate.activityClassroomsByActivityUid.nodes.map(i => i.classroomByClassroomUid.uid);

            const { toDelete: groupsToDelete, toAdd: groupsToAdd } = computeItemUpdate(initialGroups, groupsState);
            const { toDelete: classRoomsToDelete, toAdd: classroomsToAdd } = computeItemUpdate(initialClassrooms, classroomsState);


            await Promise.all(groupsToAdd.map(async groupUid =>
                await linkActivityGroups({ variables: { activityUid, groupUid } })))

            await Promise.all(groupsToDelete.map(async groupUid =>
                await deleteActivityGroup({ variables: { activityUid, groupUid } })))

            await Promise.all(classroomsToAdd.map(async classroomUid =>
                await linkActivityClassroom({ variables: { activityUid, classroomUid } })));

            await Promise.all(classRoomsToDelete.map(async classroomUid =>
                await deleteActivityClassroom({ variables: { activityUid, classroomUid } })));

            enqueueSnackbar("Activité modifiée avec succès", { variant: "success" });

            onFinish();
        },
        onError: handleError
    });

    const [createActivity, { loadingActivity }] = useMutation(CREATE_ACTIVITY, {
        onCompleted: async (data) => {
            const activityUid = data.createActivity.activity.uid;
            const { toAdd: groupsToAdd } = computeItemUpdate([], groupsState);
            const { toAdd: classRoomsToAdd } = computeItemUpdate([], classroomsState);

            await Promise.all(groupsToAdd.map(async groupUid =>
                await linkActivityGroups({ variables: { activityUid, groupUid } })))

            await Promise.all(classRoomsToAdd.map(async classroomUid =>
                await linkActivityClassroom({ variables: { activityUid, classroomUid } })));

            onFinish();
        },
        onError: handleError
    });

    const [linkActivityGroups, { loadLinkActGroup }] = useMutation(CREATE_ACTIVITY_GROUP, {
        onCompleted: (data) => { return Promise.resolve("1 groupe ajouté à l'activité") },
        onError: (error) => { return Promise.reject("1 groupe non ajouté à l'activité") }
    })

    const [deleteActivityGroup, { loadDelActGroup }] = useMutation(DELETE_ACTIVITY_GROUP, {
        onCompleted: (data) => {
            return Promise.resolve("1 groupe supprimé");
        },
        onError: (error) => {
            enqueueSnackbar("Impossible de supprimer le groupe");
            return Promise.reject("Impossible de supprimer le groupe");
        },
    });


    const [linkActivityClassroom, { loadingActivityClassroom }] = useMutation(CREATE_ACTIVITY_CLASSROOM, {
        onCompleted: (data) => { console.log("Linked Activity to classroom") },
        onError: (error) => console.error("Error linking activity to classroom", error)
    })

    const [deleteActivityClassroom, { loadDelActClassroom }] = useMutation(DELETE_ACTIVITY_CLASSROOM, {
        onCompleted: (data) => {
            console.log("1 classe supprimée", data);
            return Promise.resolve("1 classe supprimée");
        },
        onError: (error) => {
            enqueueSnackbar("Impossible de supprimer la classe");
            return Promise.reject("Impossible de supprimer la classe");
        },
    });

    // Retrieve all groups
    useQuery(LIST_GROUPS, {
        onCompleted: (data) => {
            const groups = data.allGroups.nodes;
            setGroupsState(groups.map(i => { return { ...i, checked: false } }));
            checkGroups();
        },
        onError: (error) => console.error("Error requesting list of groups", error),
        fetchPolicy: 'cache-and-network'
    })


    // Retrieve all periods
    useQuery(LIST_PERIODS, {
        onCompleted: (data) => {
            const p = data.allPeriods.nodes;
            setPeriods(p);
            if (period && period.uid) {
                handlePeriodSelect({ target: { value: period.uid } });
            }
        },
        onError: (error) => console.error("Error requesting list of periods", error),
        fetchPolicy: 'cache-and-network'
    })

    const [listAvailablePlaces] = useLazyQuery(LIST_AVAILABLE_PLACES, {
        variables: {
            day,
            startTime: startTime.format("HH:mm:00"),
            endTime: endTime.format("HH:mm:00"),
            periodUid
        },
        onCompleted: (data) => {
            const p = data.listAvailablePlaces.nodes;
            setPlaces(p);
        },
        onError: (error) => console.error("Error requesting list of available places", error),
        fetchPolicy: 'cache-and-network'
    })

    // Retrieve all places
    const [listPlaces] = useLazyQuery(LIST_PLACES, {
        onCompleted: (data) => {
            const p = data.allPlaces.nodes;
            setPlaces(p);
        },
        onError: (error) => console.error("Error requesting list of places", error),
        fetchPolicy: 'cache-and-network'
    })

    useQuery(LIST_ADMINS, {
        variables: adminView ? {
            filter: { uid: { equalTo: user.uid } }
        } : '',
        onCompleted: (data) => {
            const u = data.allUsers.nodes;
            setUsers(u);
            if (adminView)
                setOrganizerUid(user.uid);
        },
        onError: (error) => console.error("Error requesting list of users", error),
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (toUpdate.uid) {
            const periodUid = toUpdate.periodByPeriodUid.uid;
            const organizerUid = toUpdate.userByOrganizerUid ? toUpdate.userByOrganizerUid.uid : '';
            handlePeriodSelect({ target: { value: periodUid } });
            // Groups are already retrieve at this point (useQuery). We just need to update their check state (done in useQuery onCompleted callback)
            // Classrooms depends on period, and will be queried each time we change activity to update. 
            //Their check state will be updated on the onCompleted callback of the LazyQuery
            setOrganizerUid(organizerUid);
            setPlaceUid(toUpdate.placeUid);
            setCapacity(toUpdate.capacity);
            setDay(toUpdate.day);
            setStartTime(moment(toUpdate.startTime, 'HH:mm:00'));
            setEndTime(moment(toUpdate.endTime, 'HH:mm:00'));
            setActivityTitle(toUpdate.name);
            setName(toUpdate.name);
        }
    }, [toUpdate, handlePeriodSelect, checkGroups]);

    useEffect(() => {
        if (showAvailableOnly && periodUid !== '')
            listAvailablePlaces();
        else
            listPlaces();
    }, [showAvailableOnly, listAvailablePlaces, listPlaces, periodUid])

    function validate() {
        const errs = {};

        if (!name || name === '') errs.name = "Nom de l'activité obligatoire";
        if (!capacity || capacity === '') errs.capacity = "Nombre de places obligatoire";

        const nbClassroomsChecked = classroomsState.filter(c => c.checked).length;
        const nbGroupsChecked = groupsState.filter(c => c.checked).length;

        if (nbClassroomsChecked === 0 && nbGroupsChecked === 0) errs.classrooms = "Au moins 1 classe ou 1 groupe doit être sélectionné";

        if (adminView) {
            const validStartTime = [1335, 1425, 1530, 1620, 1710]
            const validEndTime = [1425, 1515, 1620, 1710, 1800]

            const startInt = 100 * moment(startTime).hours() + moment(startTime).minutes();
            const endInt = 100 * moment(endTime).hours() + moment(endTime).minutes();

            if (!validStartTime.find(i => i === startInt)) {
                const validStr = validStartTime.map(i => {
                    const str = i.toString();
                    return `${str.substring(0, 2)}h${str.substring(2, 4)}`;
                })
                errs.startTime = "Heure de début incorrecte. Choix valides :" + validStr.join(', ');
            }

            if (!validEndTime.find(i => i === endInt)) {
                const validStr = validEndTime.map(i => {
                    const str = i.toString();
                    return `${str.substring(0, 2)}h${str.substring(2, 4)}`;
                })
                errs.endTime = "Heure de fin incorrecte. Choix valides : " + validStr.join(', ');
            }
        }

        setValidationErrors(errs);
        return Object.keys(errs).length === 0;
    }

    async function handleActivity(event) {
        event.preventDefault();
        let variables = {
            name,
            day,
            capacity,
            organizerUid: (organizerUid !== '') ? organizerUid : null,
            periodUid,
            placeUid: (placeUid !== '') ? placeUid : null,
            startTime: startTime.format("HH:mm:00"),
            endTime: endTime.format("HH:mm:00")
        };

        if (validate()) {
            if (toUpdate.uid) {
                variables.activityUid = toUpdate.uid;
                await updateActivity({ variables })
            } else {
                await createActivity({ variables });
            }
        }


    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale("fr")}>
            <Container component="main" style={{ maxHeight: "90vh", overflowY: "auto" }}>
                <Typography style={{ margin: '10px' }} component="h1" variant="h5">{activityTitle}</Typography>
                <Divider style={{ marginBottom: '20px' }} />
                <Box component="form" noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel required id="period">Période</InputLabel>
                                <Select
                                    size="small"
                                    fullWidth={true}
                                    label="Période"
                                    labelid="period"
                                    id="period"
                                    value={periodUid}
                                    onChange={handlePeriodSelect}>
                                    {periods.map(p => <MenuItem key={p.uid} value={p.uid}>{p.name} ({castPeriodDate(p.startDate)} - {castPeriodDate(p.endDate)})</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="organizer">Reponsable</InputLabel>
                                <Select
                                    disabled={adminView}
                                    size="small"
                                    fullWidth={true}
                                    labelid="organizer"
                                    label="Responsable"
                                    id="organizer"
                                    value={organizerUid}
                                    onChange={handleOrganizerSelect}>
                                    {!adminView && <MenuItem key="empty.organizer" value=''>Aucun</MenuItem>}
                                    {users.map(p => <MenuItem key={p.uid} value={p.uid}>{p.lastName} {p.firstName}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} sm={8}>
                            {!adminView && <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="place">Salle</InputLabel>
                                <Select
                                    size="small"
                                    fullWidth={true}
                                    labelid="place"
                                    label="Salle"
                                    id="place"
                                    value={placeUid}
                                    onChange={handlePlaceSelect}>
                                    <MenuItem key="empty.place" value=''>Aucune</MenuItem>
                                    {toUpdate && toUpdate.placeByPlaceUid && <MenuItem key={toUpdate.placeByPlaceUid.uid} value={toUpdate.placeByPlaceUid.uid}>{toUpdate.placeByPlaceUid.name}</MenuItem>}
                                    {places.map(p => <MenuItem key={p.uid} value={p.uid}>{`${p.name} (${p.capacity} places)`}</MenuItem>)}
                                </Select>
                            </FormControl>}
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Switch
                                        checked={showAvailableOnly}
                                        onChange={() => setShowAvailableOnly(!showAvailableOnly)}
                                    />} label="Masquer salles assignées" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                size="small"
                                name="nom"
                                error={validationErrors.name != null}
                                helperText={validationErrors.name}
                                required
                                onChange={(event) => setName(event.target.value)}
                                fullWidth
                                value={name}
                                id="nom"
                                label="Nom de l'activité"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                size="small"
                                name="capacity"
                                error={validationErrors.capacity != null}
                                helperText={validationErrors.capacity}
                                required
                                onChange={event => setCapacityInt(event.target.value)}
                                fullWidth
                                value={capacity}
                                id="capacity"
                                label="Nombre de places"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TimePicker
                                required
                                renderInput={(props) => <TextField {...props} size="small"  fullWidth required error={validationErrors.startTime != null} helperText={validationErrors.startTime}  />}
                                invalidDateMessage="Format d'heure invalide"
                                inputVariant="outlined"
                                label="Heure de début"
                                value={startTime}
                                cancelLabel="Annuler"
                                ampm={false}
                                onChange={(newValue) => { setStartTime(newValue) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TimePicker
                                required
                                renderInput={(props) => <TextField {...props} size="small" fullWidth required error={validationErrors.endTime != null} helperText={validationErrors.endTime}  />}
                                inputVariant="outlined"
                                label="Heure de fin"
                                minutesStep={5}
                                cancelLabel="Annuler"
                                value={endTime}
                                ampm={false}
                                onChange={(newValue) => { setEndTime(newValue) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl size="small" variant="outlined" fullWidth>
                                <InputLabel required id="jour">Jour</InputLabel>
                                <Select
                                    labelid="jour"
                                    label="Jour"
                                    id="jour"
                                    value={day}
                                    onChange={(event) => setDay(event.target.value)}
                                >
                                    <MenuItem value={"MONDAY"}>Lundi</MenuItem>
                                    <MenuItem value={"TUESDAY"}>Mardi</MenuItem>
                                    <MenuItem value={"WEDNESDAY"}>Mercredi</MenuItem>
                                    <MenuItem value={"THURSDAY"}>Jeudi</MenuItem>
                                    <MenuItem value={"FRIDAY"}>Vendredi</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <GradesClassroomsSelect
                                classroomOptions={classroomOptions}
                                classroomsState={classroomsState}
                                setClassroomsState={setClassroomsState}
                            />
                            {validationErrors.classrooms && <FormHelperText error>{validationErrors.classrooms}</FormHelperText>}
                        </Grid>
                    </Grid>
                    {!adminView && <Grid item xs={12}>
                        <Paper variant="outlined">
                            <Typography variant="subtitle2">Groupes</Typography>
                            <Grid container spacing={0}>
                                {
                                    groupsState.map(g => (

                                        <Grid item xs={1} key={g.uid} >
                                            <FormControlLabel
                                                control={
                                                    < Checkbox
                                                        size="small"
                                                        checked={g.checked}
                                                        onChange={handleCheckBoxes}
                                                        name={g.name}
                                                        value={g.uid}
                                                        color="primary"
                                                    />
                                                }
                                                label={<Typography style={{ fontSize: "10px", padding: "Opx", margin: "0px" }}>{g.name}</Typography>}
                                            />
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Paper>
                    </Grid>}
                </Box>
                <Button
                    style={{ float: "right", margin: "10px" }}
                    variant="outlined"
                    color="primary"
                    disabled={
                        loadingActivity ||
                        loadLinkActGroup ||
                        loadingActivityClassroom ||
                        loadDelActClassroom ||
                        loadDelActGroup}
                    onClick={handleActivity}>{actionTitle}</Button>


            </Container >
        </LocalizationProvider >
    );

}
export default Activity;