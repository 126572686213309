import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_ALL_APPOINTMENT_FOR_CLASSROOM, LIST_USERS, GET_CLASSROOM } from "../../../graphql/admin-requests";
import CalendarList from './PrintableCalendarList';
import { useParams } from "react-router-dom";



function ClassroomCalendar({ period }) {

    const { classroomUid } = useParams();

    const [appointments, setAppointments] = useState([]);
    const [users, setUsers] = useState([]);
    const [classroom, setClassroom] = useState({});

    const [getClassroom, { loading: loadingClassroom }] = useLazyQuery(GET_CLASSROOM, {
        onCompleted: data => setClassroom(data.classroomByUid),
        onError: (error) => console.error("Error retrieving classroom", error)
    })

    const [getAllApointments, { loading: loadingAppointments }] = useMutation(GET_ALL_APPOINTMENT_FOR_CLASSROOM, {
        onCompleted: data => setAppointments(data.getAllAppointementForClassroom.appointments),
        onError: (error) => console.error("Error retrieving activities", error)
    });

    const [getAllUsers] = useLazyQuery(LIST_USERS, {
        onCompleted: (data) => setUsers([...data.allUsers.nodes]),
        onError: (error) => console.error("Error retrieving users", error)
    });

    useEffect(() => { getClassroom({ variables: { classroomUid } }); }, [getClassroom, classroomUid]);

    useEffect(() => {
        async function getData() {
            if (classroom.uid && period.uid) {
                await getAllApointments({ variables: { periodUid: period.uid, classroomUid: classroom.uid } });
                await getAllUsers({ variables: { classroomUid: classroom.uid } });
            }
        };
        getData();
    }, [classroom, getAllApointments, getAllUsers, period]);

    return (
        <CalendarList
            users={users}
            appointments={appointments}
            item={classroom}
            loadingItem={loadingClassroom}
            loadingAppointments={loadingAppointments}
        />)
};

export default ClassroomCalendar;