import { Box, Button, TextField, InputAdornment, SvgIcon, Grid } from '@mui/material';
import { SearchIcon } from '../../Icons';
import CUModal from '../modals/CUModal';
import { useState } from 'react';
import CUPeriod from "../CreateUpdateComponents/Period";

const PeriodListToolbar = ({ updateSearch, loadData, ...props }) => {

    const [openCUModal, setOpenCUModal] = useState(false);
    const handleClose = () => {
        setOpenCUModal(false);
        loadData();
    }

    return (
        <>
            <CUModal
                open={openCUModal}
                handleClose={handleClose}
                component={CUPeriod} />
            <Box {...props}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color="primary" variant="contained" onClick={() => setOpenCUModal(true)}>Ajouter</Button>
                </Box>
                <Box pb={4}>
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                                        </InputAdornment>
                                    )
                                }}
                                placeholder="Rechercher une période"
                                variant="outlined"
                                onChange={updateSearch}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
};

export default PeriodListToolbar;