import { Box, Container } from '@mui/material';
import { DELETE_CLASSROOM, LIST_CLASSROOMS, LIST_CLASSROOMS_FOR_ADMIN, LIST_GRADES } from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import ClassroomListResults from './ClassroomListResults';
import ClassroomListToolbar from './ClassroomListToolbar';
import { useSnackbar } from 'notistack';
import ClassroomCalendar from '../CalendarList/ClassroomCalendar';
import { useLocation } from "react-router-dom";



function ClassroomList({ period, readOnly }) {

    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();


    const [classrooms, setClassrooms] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);

    let [nameFilter, setNameFilter] = useState("");

    const [classroom, setClassroom] = useState("");
    const [gradesOptions, setGradesOptions] = useState([]);
    const [gradeFilter, setGradeFilter] = useState([]);


    const [getClassrooms] = useLazyQuery(readOnly ? LIST_CLASSROOMS_FOR_ADMIN : LIST_CLASSROOMS, {
        onCompleted: (data) => {
            if (readOnly) {
                setClassrooms(data.listClassroomsForAdmin.nodes);
                setTotalCount(data.listClassroomsForAdmin.totalCount);
            } else {
                setClassrooms(data.allClassrooms.nodes);
                setTotalCount(data.allClassrooms.totalCount);
            }

        },
        fetchPolicy: 'cache-and-network'
    });

    const [getGrades] = useLazyQuery(LIST_GRADES, {
        onCompleted: (data) => {
            setGradesOptions(data.allGrades.nodes.map(i => { return { value: i.uid, label: i.name } }));
        },
        fetchPolicy: 'cache-and-network'
    });

    const updateGradeFilter = (grades) => {
        setGradeFilter(grades.map(i => i.value));
    }

    const [deleteClassroom] = useMutation(DELETE_CLASSROOM, {
        onError: e => enqueueSnackbar("Impossible de supprimer la classe"),
        onCompleted: d => enqueueSnackbar("Classe supprimée")
    })

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const loadData = useCallback(async () => {
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const gradeF = gradeFilter.length > 0 ? { gradeUid: { in: gradeFilter } } : {};

        const filters = { ...nameF, ...gradeF };
        const filter = {
            variables: {
                filter: filters, first: limit,
                offset
            }
        }
        await getClassrooms(filter);
        await getGrades();
    }, [nameFilter, getGrades, gradeFilter, getClassrooms, limit, offset])

    const handleDeleteClassroom = useCallback(async (classroomUid) => {
        await deleteClassroom({
            variables: { classroomUid }
        });
        loadData();
    }, [deleteClassroom, loadData]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (location.search) {
            const gradeUid = new URLSearchParams(location.search).get("grade");
            if (gradeUid) {
                updateGradeFilter([{ value: gradeUid }]);
            };
        }
    }, [location.search])


    return (classroom.uid) ?
        <ClassroomCalendar classroom={classroom} handleClose={() => setClassroom({})} period={period} /> :
        (
            <Container component="main">
                <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                    <Container maxWidth={false}>
                        <ClassroomListToolbar
                            readOnly={readOnly}
                            loadData={loadData}
                            updateSearch={updateSearch}
                            updateGradeFilter={updateGradeFilter}
                            gradesOptions={gradesOptions}
                        />
                        <Box sx={{ pt: 3 }}>
                        <ClassroomListResults
                                readOnly={readOnly}
                                viewClassroomCalendar={setClassroom}
                                classrooms={classrooms}
                                limit={limit}
                                offset={offset}
                                totalCount={totalCount}
                                setLimit={setLimit}
                                setOffset={setOffset}
                                handleDeleteClassroom={handleDeleteClassroom} />
                        </Box>
                    </Container>
                </Box>
            </Container >
        )
};

export default ClassroomList;