import { Tooltip, IconButton } from '@mui/material';
import { Zoom } from '@mui/material';
import { Link } from "react-router-dom";


function CustomIconButton({ title, icon, button, color, link, blank = false, onClick, hide = false, disabled = false }) {

    const options = {};
    if (link) {
        options['component'] = Link;
        options['to'] = link;
        if (blank) {
            options['target'] = '_blank';
        }
    }

    return hide ? "" :

        (
            <Tooltip arrow placement="bottom"
                TransitionComponent={Zoom}
                TransitionProps={{
                    timeout: {
                        enter: 100,
                        exit: 0,
                    }
                }} title={title} >
                {button ?
                    button :
                    <IconButton
                        {...options}
                        size="small"
                        //color="default"
                        style={{ backgroundColor: color }}
                        onClick={onClick}>
                        {icon}
                    </IconButton>
                }
            </Tooltip>)
}


export default CustomIconButton;