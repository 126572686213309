import { Container, Grid, Box } from '@mui/material';
import React from 'react';
import Activities from "./dashboarditems/Activities";
import Filling from "./dashboarditems/Filling";
import UserActivities from "./dashboarditems/UserActivities";
import HeatMap from "./dashboarditems/HeatMap";

function AdminDashboard({ period }) {

    return (
        <Box>
            <Container component="main">
                <Grid container spacing={1} >
                    <Grid item lg={6} xl={6} md={12} sm={12} xs={12}><Filling period={period} role="horaroj_admin" /></Grid>
                    <Grid item xl={6} lg={6} md={12} sm={12} xs={12}><HeatMap period={period} /> </Grid>
                    <Grid item lg={3} xl={3} md={6} sm={12} xs={12}><Activities period={period} /></Grid>
                    {/* <Grid item lg={3} xl={3} md={6} sm={12} xs={12}><UserActivities period={period} /></Grid> */}
                </Grid>
            </Container>
        </Box >)

}

export default AdminDashboard;