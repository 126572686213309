import React, { useEffect, useState, useRef } from 'react';
import { useMutation, useLazyQuery } from "@apollo/client";
import { useReactToPrint } from 'react-to-print';
import { ViewState } from '@devexpress/dx-react-scheduler';
import { Toolbar, DateNavigator, ViewSwitcher } from '@devexpress/dx-react-scheduler-material-ui';
import { GET_ALL_APPOINTMENT_FOR_USER, GET_USER, UNREGISTER_ACTIVITY_FOR_USER } from "../../../graphql/admin-requests";
import {
    Appointments,
    DayView,
    MonthView,
    Scheduler,
    WeekView,
} from '@devexpress/dx-react-scheduler-material-ui';
import CustomAppointmentContent from '../../CommonComponents/CustomAppointmentContent';

import { currentDate } from "../../../utils/time";
import { Typography, IconButton, Box } from '@mui/material';
import { DeleteIcon, PrintIcon } from '../../Icons'
import { useParams } from "react-router-dom";

import { activityTypeColor } from '../../../utils/colors';
import CustomIconButton from '../subcomponents/CustomIconButton';

import useMediaQuery from '@mui/material/useMediaQuery';


function Calendar({ period }) {
    const { userUid } = useParams();

    const smallSreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [calendarDate, setCalendarDate] = useState(currentDate);
    const [viewName, setViewName] = useState(smallSreen ? "Day" : "Week");

    const updateCalendarDate = (e) => {
        if (e <= new Date("2018-01-05 00:00:00") && e >= new Date("2018-01-01 00:00:00")) setCalendarDate(e);
    };

    const [calendar, setCalendar] = useState([]);
    const [user, setUser] = useState({});

    const [unregisterActivity] = useMutation(UNREGISTER_ACTIVITY_FOR_USER, {
        onCompleted: (data) => {
            console.log("unregistered", data);
        },
        onError: (error) => console.error("Error unregistering", error)
    })

    const [getUser] = useLazyQuery(GET_USER, {
        onCompleted: (data) => {
            setUser(data.userByUid);
        },
        onError: (error) => console.error("Error retrieving user", error)
    })

    const [getCalendar] = useMutation(GET_ALL_APPOINTMENT_FOR_USER, {
        onCompleted: (data) => {
            setCalendar(data.getAllAppointmentForUser.appointments);
        },
        onError: (error) => console.error("Error retrieving activities", error)
    });

    const CustomAppointment = ({
        children, style, data, ...restProps
    }) => (
        <Appointments.Appointment data={data} {...restProps} style={{ ...style, backgroundColor: activityTypeColor[data.type], }}>
            {data.type === "activity" ? <IconButton style={{ position: "flex", float: "right" }} onClick={handleDelete(data)}><DeleteIcon fontSize="small" /></IconButton> : ""}
            {children}
        </Appointments.Appointment >);

    const handleDelete = appointmentData => {
        return async event => {
            await unregisterActivity({ variables: { userUid, activityUid: appointmentData.appointmentUid } });
            await getCalendar({ variables: { userUid, periodUid: period.uid } });
        }
    }

    useEffect(() => {
        if (period.uid && userUid) {
            async function getData() {
                await getCalendar({ variables: { userUid, periodUid: period.uid } });
            };
            getData();
            getUser({ variables: { userUid } })
        }
    }, [getCalendar, getUser, period, userUid]);


    const componentRef = useRef();


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
        @media all {
            .page-break {
              display: none;
            }
          }

          @media print {
            html, body {
              height: initial !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
            }
          }

          @media print {
            .page-break {
              display: block;
              page-break-before: always;
            }
          }

          @page {
            size: auto;
            margin: 20mm;
          }
        `
    });

    const formatUserClassroom = () => {
        const c = user.classroomByClassroomUid;
        return c ? `(${c.name})` : '';
    }

    const formatTitle = () => {
        return `${period.name} - ${user.firstName} ${user.lastName} ${formatUserClassroom()}`;
    }

    const CustomOpenButton = (props) => {
        const newProps = {
            ...props,
            availableViews: [{ name: "Day", displayName: "Vue jour" }, { name: "Week", displayName: "Vue semaine" }],
            onChange: i => {
                setViewName(i);
                props.onChange(i);
            }
        }
        return <ViewSwitcher.Switcher  {...newProps} />;
    };

    const CustomHideComponent = (props) => null;

    const CustomNavigationButton = props => {
        if (viewName === 'Day') {
            return <DateNavigator.NavigationButton {...props} />
        }
    }

    const DayScaleCell = props => (
        <MonthView.DayScaleCell {...props} style={{ textAlign: 'center', fontWeight: 'bold' }} />
    );

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomIconButton title="Imprimer" onClick={handlePrint} icon={<PrintIcon />}>Imprimer</CustomIconButton>
            </Box>
            <div ref={componentRef} style={{ height: "calc(100% - 64px)" }}>
                <Typography component="h1" variant="h6" color="inherit" noWrap>{formatTitle()}</Typography>
                <Scheduler data={[...calendar]} locale="fr-FR">
                    <ViewState
                        currentDate={calendarDate}
                        defaultCurrentViewName={viewName}
                        onCurrentDateChange={updateCalendarDate}
                    />
                    <Toolbar />
                    <ViewSwitcher switcherComponent={CustomOpenButton} />
                    <DateNavigator navigationButtonComponent={CustomNavigationButton} openButtonComponent={CustomHideComponent} />
                    <DayView
                        cellDuration={45}
                        startDayHour={8}
                        endDayHour={19}
                        intervalCount={1}
                        dayScaleCellComponent={DayScaleCell}
                    />
                    <WeekView
                        excludedDays={[0, 6]}
                        cellDuration={45}
                        startDayHour={8}
                        endDayHour={19}
                        dayScaleCellComponent={DayScaleCell}
                    />
                    <Appointments
                        appointmentContentComponent={CustomAppointmentContent}
                        appointmentComponent={CustomAppointment} />
                </Scheduler>
            </div>
        </>);
};

export default Calendar;