import React, { useState, useEffect } from 'react';
import { useMutation } from "@apollo/client";
import { Box, Button, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { REGISTER_SUPER_ADMIN, UPDATE_USER } from "../../../graphql/admin-requests";
import { useSnackbar } from 'notistack';



function SuperAdmin({ onFinish, toUpdate = {} }) {
    const { enqueueSnackbar } = useSnackbar();


    // User fields
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");

    let [activityTitle, setActivityTitle] = useState("Nouvel administrateur");
    let [actionTitle, setActionTitle] = useState("Ajouter");

    const handleError = async (e) => { console.error(e); enqueueSnackbar("Impossible de créer l'administrateur", { variant: "error" }) }


    const [createUser, { loadingUser, error }] = useMutation(REGISTER_SUPER_ADMIN, {
        onCompleted: (data) => { onFinish(); },
        onError: (error) => handleError(error)
    });

    const [updateUser] = useMutation(UPDATE_USER, {
        onCompleted: (data) => { onFinish() },
        onError: (error) => handleError(error)
    });

    async function handleUser(event) {
        event.preventDefault();

        const variables = { firstName, lastName, email, password };
        if (toUpdate.uid) {
            variables.userUid = toUpdate.uid;
            await updateUser({ variables });
        }
        else {
            await createUser({ variables });

        }
    }

    useEffect(() => {
        if (toUpdate.uid) {
            setActionTitle("Modifier");
            setActivityTitle("Modifier un administrateur");
            setLastName(toUpdate.lastName)
            setFirstName(toUpdate.firstName)
        }
    }, [toUpdate]);

    return (
        <Container component="main">
            <Typography style={{ margin: '10px' }} component="h1" variant="h5">{activityTitle}</Typography>
            <Divider style={{ marginBottom: '20px' }} />
            <Box component="form" noValidate>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            name="firstName"
                            required
                            onChange={(event) => setFirstName(event.target.value)}
                            fullWidth
                            value={firstName}
                            id="firstName"
                            label="Prenom"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            name="lastName"
                            required
                            onChange={(event) => setLastName(event.target.value)}
                            fullWidth
                            value={lastName}
                            id="lastName"
                            label="Nom de famille"
                            autoFocus
                        />
                    </Grid>
                    {!toUpdate.uid && <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            name="email"
                            required
                            onChange={(event) => setEmail(event.target.value)}
                            fullWidth
                            value={email}
                            id="email"
                            label="Email"
                        />
                    </Grid>}

                    {!toUpdate.uid && <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            size="small"
                            name="password"
                            required
                            onChange={(event) => setPassword(event.target.value)}
                            fullWidth
                            value={password}
                            id="password"
                            label="Mot de passe"
                        />
                    </Grid>}
                </Grid>
            </Box>
            <Button
                style={{ float: "right", margin: "10px" }}
                variant="outlined"
                color="primary"
                disabled={loadingUser}
                onClick={handleUser}>{actionTitle}</Button>
            {error && <p>{error.message}</p>}
        </Container >
    );

}
export default SuperAdmin;