import { Avatar, Card, CardContent, Grid, Tooltip, Typography } from '@mui/material';
import { ActivityIcon } from '../../Icons';
import { LIST_ACTIVITIES_SUPER_ADMIN, MAX_TAKEN_ACTIVITY } from "../../../graphql/admin-requests";
import React, { useState } from 'react';
import { useQuery } from "@apollo/client";
import { Slider } from '@mui/material';
import { useTheme } from '@mui/material/styles';


const Activities = ({ period, ...props }) => {
    const [count, setCount] = useState(0);
    const [lessThanOrEqualTo, setLessThanOrEqualTo] = useState(3);
    const [maxTaken, setMaxTaken] = useState(0);

    useQuery(MAX_TAKEN_ACTIVITY, {
        variables: { filter: { periodUid: { equalTo: period.uid } } },
        onCompleted: (data) => {
            const res = data.allActivitySuperAdminViews.nodes;
            setMaxTaken(res.length > 0 ? parseInt(res[0].taken) : 0)
        },
        fetchPolicy: 'cache-and-network'
    });

    useQuery(LIST_ACTIVITIES_SUPER_ADMIN, {
        variables: { filter: { taken: { lessThanOrEqualTo }, periodUid: { equalTo: period.uid }, } },
        onCompleted: (data) => { setCount(data.allActivitySuperAdminViews.totalCount) },
        fetchPolicy: 'cache-and-network'
    });

    const ValueLabelComponent = (props) => {
        const { children, open, value } = props;

        return (
            <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
                {children}
            </Tooltip>
        );
    }

    const theme = useTheme();

    return (
        <Card {...props} >
            <CardContent>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item >
                        <Typography color="textSecondary" style={{ marginBottom: "-5px" }} variant="h6">ACTIVITES</Typography>
                        <Typography color="textSecondary" style={{ marginBottom: "-10px" }} variant="subtitle2">(par inscriptions)</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar sx={{ backgroundColor: theme.palette.primary.main, height: 56, width: 56 }}>
                            <ActivityIcon />
                        </Avatar>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="textPrimary" variant="h3">{count}</Typography>
                    </Grid>
                    <Grid item xs={6}>

                        <Slider
                            orientation="horizontal"
                            valueLabelDisplay="on"
                            components={{
                                ValueLabel: ValueLabelComponent
                            }}
                            marks
                            min={0}
                            getAriaLabel={v => `${v} places`}
                            max={maxTaken}
                            onChangeCommitted={(e, v) => setLessThanOrEqualTo(v)}
                            valueLabelFormat={v => `${v} inscription(s) et moins `}
                            defaultValue={3}
                            aria-labelledby="vertical-slider"
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default Activities;