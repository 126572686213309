import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { day_translate } from "../../../../utils/translate";
import { inConflict, alreadyRegister } from '../../../../utils/time'
//import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import moment from "moment";
import CustomIconButton from "../../../AdminComponents/subcomponents/CustomIconButton"
import { ConflictIcon } from "../../../Icons"


const ActivityListResults = ({ activities, calendar, totalCount, setLimit, limit, setOffset, offset, setPage, page, handleRegisterActivity, handleUnregisterActivity, user, ...rest }) => {

    const [selectedActivityIds, setSelectedActivityIds] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [progress, setProgress] = useState(50);

    const handleSelectAll = (event) => {
        let newSelectedActivityIds;

        if (event.target.checked) {
            newSelectedActivityIds = activities.map((activity) => activity.uid);
        } else {
            newSelectedActivityIds = [];
        }

        setSelectedActivityIds(newSelectedActivityIds);
    };

    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedActivityIds.indexOf(id);
        let newSelectedActivityIds = [];

        if (selectedIndex === -1) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds, id);
        } else if (selectedIndex === 0) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds.slice(1));
        } else if (selectedIndex === selectedActivityIds.length - 1) {
            newSelectedActivityIds = newSelectedActivityIds.concat(selectedActivityIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedActivityIds = newSelectedActivityIds.concat(
                selectedActivityIds.slice(0, selectedIndex),
                selectedActivityIds.slice(selectedIndex + 1)
            );
        }

        setSelectedActivityIds(newSelectedActivityIds);
    };

    const handleLimitChange = async (event) => {
        await setLimit(event.target.value);
    };

    const registerAll = () => {
        setOpenDialog(true);
    }

    const confirmRegisterAll = async () => {
        setConfirmed(true);

        let i = 1;
        const tasks = selectedActivityIds.map(uid => [i++, () =>
            new Promise((res) => {
                setTimeout(async () => {
                    await handleRegisterActivity(uid)();
                    res("ok");
                }, 1000);
            })]);

        for (let [k, task] of tasks) {
            setProgress(100 / tasks.length * k)
            await task();
        };

        closeDialog();

    }

    const closeDialog = () => setOpenDialog(false);

    const handlePageChange = async (event, newPage) => {
        await setOffset(newPage * limit);
        await setPage(newPage);
    };

    return (
        <div>
            <Dialog
                open={openDialog}
                keepMounted
                onClose={closeDialog}
            >
                <DialogTitle>S'inscrire à plusieurs activités</DialogTitle>
                <DialogContent>
                    {confirmed ? <LinearProgress variant="determinate" value={progress} /> : <DialogContentText>Vous êtes sur le point de vous <strong>inscrire à {selectedActivityIds.length} activités</strong>.<br /> Etes vous sûr ?</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">Annuler</Button>
                    <Button onClick={confirmRegisterAll} color="primary">Confirmer</Button>
                </DialogActions>
            </Dialog>
            <Card {...rest}>
                {selectedActivityIds.length >= 2 && <Button onClick={registerAll}>S'inscrire pour tous</Button>}
                {/* <PerfectScrollbar> */}
                <Box sx={{ display: { xs: 'none', lg: 'block' } }} >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedActivityIds.length === activities.length}
                                        color="primary"
                                        indeterminate={
                                            selectedActivityIds.length > 0
                                            && selectedActivityIds.length < activities.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell size="small">Nom</TableCell>
                                <TableCell size="small">Jour</TableCell>
                                <TableCell size="small">Heures</TableCell>
                                <TableCell size="small">Intervenant</TableCell>
                                <TableCell size="small">Places</TableCell>
                                <TableCell size="small">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activities.slice(0, limit).map((activity) => {
                                const registered = alreadyRegister(activity, calendar);
                                const conflicts = inConflict(activity, calendar);
                                const conflictText = <div><strong>En conflit</strong><br />{conflicts.map(a => <p>{a.title} - {a.startTime}:{a.endTime}</p>)}</div>;
                                return (
                                    <TableRow hover key={activity.uid} selected={selectedActivityIds.indexOf(activity.uid) !== -1}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedActivityIds.indexOf(activity.uid) !== -1}
                                                onChange={(event) => handleSelectOne(event, activity.uid)}
                                                value="true"
                                            />
                                        </TableCell>
                                        <TableCell size="small">{activity.name}</TableCell>
                                        <TableCell size="small">{day_translate.FR[activity.day]}</TableCell>
                                        <TableCell size="small">{moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}</TableCell>
                                        <TableCell size="small">{activity.userByOrganizerUid && activity.userByOrganizerUid.firstName} {activity.userByOrganizerUid && activity.userByOrganizerUid.lastName}</TableCell>
                                        <TableCell size="small">{activity.remaining}/{activity.capacity}</TableCell>
                                        <TableCell size="small">
                                            {registered ?
                                                <Button size="small" onClick={handleUnregisterActivity(activity.uid)}>Se désinscrire</Button>
                                                :
                                                conflicts.length === 0 ?
                                                    <Button size="small" onClick={handleRegisterActivity(activity.uid)}>S'inscrire</Button>
                                                    :
                                                    <CustomIconButton
                                                        title={conflictText}
                                                        icon={<ConflictIcon />} />
                                            }</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Box>
                <Box sx={{ display: { xs: 'block', lg: 'none' } }} >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedActivityIds.length === activities.length}
                                        color="primary"
                                        indeterminate={
                                            selectedActivityIds.length > 0
                                            && selectedActivityIds.length < activities.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                    <TableCell size="small">Activité</TableCell>
                                <TableCell size="small">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activities.slice(0, limit).map((activity) => {
                                const registered = alreadyRegister(activity, calendar);
                                const conflicts = inConflict(activity, calendar);
                                const conflictText = <div><strong>En conflit</strong><br />{conflicts.map(a => <p>{a.title} - {a.startTime}:{a.endTime}</p>)}</div>;
                                return (
                                    <TableRow hover key={activity.uid} selected={selectedActivityIds.indexOf(activity.uid) !== -1}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedActivityIds.indexOf(activity.uid) !== -1}
                                                onChange={(event) => handleSelectOne(event, activity.uid)}
                                                value="true"
                                            />
                                        </TableCell>
                                            <TableCell size="small">
                                                {activity.name}<br />
                                                <strong>Jour: </strong>{day_translate.FR[activity.day]}<br />
                                                <strong>Heure: </strong>{moment(activity.startTime, "HH:mm:00").format("HH:mm")} - {moment(activity.endTime, "HH:mm:00").format("HH:mm")}<br />
                                                <strong>Places: </strong>{activity.remaining}/{activity.capacity}
                                            </TableCell>
                                        <TableCell size="small">
                                            {registered ?
                                                <Button size="small" onClick={handleUnregisterActivity(activity.uid)}>Se désinscrire</Button>
                                                :
                                                conflicts.length === 0 ?
                                                    <Button size="small" onClick={handleRegisterActivity(activity.uid)}>S'inscrire</Button>
                                                    :
                                                    <CustomIconButton
                                                        title={conflictText}
                                                        icon={<ConflictIcon />} />
                                            }</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Box>
                {/* </PerfectScrollbar> */}
                <TablePagination
                    component="div"
                    count={totalCount}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </Card>
        </div >
    );
};

ActivityListResults.propTypes = {
    activities: PropTypes.array.isRequired
};

export default ActivityListResults;