import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';

import { IconButton } from '@mui/material';
import { DeleteIcon } from '../../Icons';

import { activityColor } from '../../../utils/colors';

const CustomAppointment = (handleDelete) => ({
    children, style, data, ...restProps
}) => {
    return (
        <Appointments.Appointment data={data} {...restProps} style={{ ...style, backgroundColor: activityColor(data), }}>
            {data.type === "activity" && handleDelete ? <IconButton style={{ position: "flex", float: "right" }} onClick={handleDelete(data)}><DeleteIcon fontSize="small" /></IconButton> : ""}
            {children}
        </Appointments.Appointment >)
};

export default CustomAppointment;