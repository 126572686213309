import React, { useState } from 'react';
import { useMutation } from "@apollo/client";
import { Box, Button, Container, Divider, Grid, IconButton, Input, InputAdornment, Typography } from '@mui/material';
import { UPDATE_PASSWORD_FOR_USER, UPDATE_PASSWORD } from "../../../graphql/admin-requests";
import { useSnackbar } from 'notistack';
import { EyeIcon, VisibilityOffIcon } from '../../Icons';



function PasswordUpdate({ onFinish, toUpdate = {} }) {
    const { enqueueSnackbar } = useSnackbar();

    let [password, setPassword] = useState("");
    let [showPassword, setShowPassword] = useState(false);
    let [activityTitle] = useState("Modifier le mot de passe");
    let [actionTitle] = useState("Modifier");

    const handleError = async (e) => { console.error(e); enqueueSnackbar("Impossible de modifier le mot de passe", { variant: "error" }) }

    const [updatePassword, { updating, error }] = useMutation(toUpdate.uid ? UPDATE_PASSWORD_FOR_USER : UPDATE_PASSWORD, {
        onCompleted: (data) => { enqueueSnackbar("Mot de passe modifié avec succes", { variant: "success" }); onFinish() },
        onError: (error) => handleError(error)
    });

    async function handleUser(event) {
        event.preventDefault();
        const variables = { userUid: toUpdate.uid, password };
        await updatePassword({ variables });
        setPassword("");
        setShowPassword(false);
    }

    return (
        <Container component="main">
            <Typography style={{ margin: '10px' }} component="h1" variant="h5">{activityTitle}</Typography>
            <Divider style={{ marginBottom: '20px' }} />
            <Box component="form" noValidate onSubmit={handleUser}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Input
                            name="password"
                            required
                            autoComplete='new-password'
                            type={showPassword ? 'text' : 'password'}
                            onChange={(event) => setPassword(event.target.value)}
                            fullWidth
                            id="password"
                            label="Mot de passe"
                            autoFocus
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <EyeIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>}

                        />
                    </Grid>
                </Grid>
            </Box>
            <Button
                style={{ float: "right", margin: "10px" }}
                variant="outlined"
                color="primary"
                disabled={updating}
                onClick={handleUser}>{actionTitle}</Button>
            {error && <p>{error.message}</p>}
        </Container >
    );

}
export default PasswordUpdate;