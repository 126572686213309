import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material';
import { LIST_USERS, CREATE_CLASSROOM_ACTIVITY_USER, DELETE_CLASSROOM_ACTIVITY_USER, LIST_CLASSROOM_ACTIVITY_USERS } from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import UserByClassroomListResults from '../UserByClassroomList/UserByClassroomListResults';
import UserByClassroomActivityListResults from './UserByClassroomActivityListResults';
import AsyncSelect from "react-select/async";
import { useSnackbar } from 'notistack';


function UserByClassroomActivityList({ readOnly, classroomUid, classroomActivityUid }) {

    const { enqueueSnackbar } = useSnackbar();

    const [classroomUsers, setClassroomUsers] = useState([]);
    const [otherUsers, setOtherUsers] = useState([]);

    const [userUidsToAdd, setUserUidsToAdd] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    const [addUser] = useMutation(CREATE_CLASSROOM_ACTIVITY_USER, {
        onCompleted: (data) => { enqueueSnackbar("Nouvel eleve ajouté", { variant: "success" }) },
        onError: (error) => { enqueueSnackbar("Impossible d'ajouter l'élève", { variant: "error" }) }
    })

    const [removeUser] = useMutation(DELETE_CLASSROOM_ACTIVITY_USER, {
        onCompleted: (data) => { enqueueSnackbar("Eleve supprimé", { variant: "success" }) },
        onError: (error) => { enqueueSnackbar("Impossible de supprimer l'élève", { variant: "error" }) }
    })

    const updateUsersToAdd = (users) => {
        setUserUidsToAdd(users.map(i => i.value));
    }

    const onSubmit = () => {
        return Promise.all(userUidsToAdd.map(userUid => {
            return addUser({ variables: { classroomActivityUid, userUid } })
        })).then(r => { loadUsers(); })
    }

    const handleRemove = (userUid) => async () => {
        await removeUser({ variables: { classroomActivityUid, userUid } });
        await loadUsers();
    }


    const promiseOptions = async inputValue => {
        const searchF = {
            or: [{ lastName: { includesInsensitive: inputValue } },
            { firstName: { includesInsensitive: inputValue } }
            ]
        };

        const filters = { ...searchF };
        const filter = {
            variables: {
                filter: filters,
                first: 10
            }
        }
        findUsers(filter)        //TODO ARE: Gerer la desynchro findUsers => setOptions => return options
        return userOptions;
    }

    const [findUsers] = useLazyQuery(LIST_USERS, {
        onCompleted: (data) => {
            setUserOptions(data.allUsers.nodes.map(i => {
                const userName = `${i.lastName} ${i.firstName}`;
                const userClassroom = (i.classroomByClassroomUid && i.classroomByClassroomUid.name);
                const classroomText = userClassroom ? `(${userClassroom})` : "";
                return { value: i.uid, label: `${userName} ${classroomText}` }
            }));
        },
        fetchPolicy: 'cache-and-network'
    })


    const [getUsers] = useLazyQuery(LIST_USERS, {
        onCompleted: (data) => {
            setClassroomUsers(data.allUsers.nodes);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [getOtherUsers] = useLazyQuery(LIST_CLASSROOM_ACTIVITY_USERS, {
        onCompleted: (data) => {
            setOtherUsers(data.allClassroomActivityUsers.nodes.map(u => u.userByUserUid));
        },
        fetchPolicy: 'cache-and-network'
    });

    const loadUsers = useCallback(() => {
        getUsers({ variables: { classroomUid } });
        getOtherUsers({ variables: { classroomActivityUid } });
    }, [getUsers, classroomUid, getOtherUsers, classroomActivityUid]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers])

    return (
        <Container component="main" style={{ maxHeight: "90vh", overflowY: "auto" }}>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Typography variant="h5">Eleves de la classe</Typography>
                <Divider style={{ marginTop: '5px' }} />
                <Container maxWidth={false}>
                    <Box sx={{ pt: 3 }}>
                        <UserByClassroomListResults users={classroomUsers} />
                    </Box>
                </Container>
            </Box>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', pt: 3 }}>
                <Typography variant="h5">Eleves supplementaires</Typography>
                <Divider style={{ marginTop: '5px' }} />
                {!readOnly &&
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <AsyncSelect
                                placeholder="Ajouter un élève"
                                noOptionsMessage={() => "Aucun élève trouvé"}
                                isMulti
                                defaultOptions={true}
                                onChange={updateUsersToAdd}
                                loadOptions={promiseOptions}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="primary" onClick={onSubmit}>Ajouter les eleves</Button>
                        </Grid>
                    </Grid>}
                <Container maxWidth={false}>
                    <Box sx={{ pt: 3 }}>
                        <UserByClassroomActivityListResults readOnly={readOnly} users={otherUsers} onDelete={handleRemove} />
                    </Box>
                </Container>
            </Box>
        </Container>
    )
};

export default UserByClassroomActivityList;