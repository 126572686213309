import { Box, Container, Divider, Typography } from '@mui/material';
import { LIST_USER_BY_GROUP, GET_GROUP } from "../../../graphql/admin-requests";
import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect, useCallback, useRef } from 'react';
import UserListResults from './UserByGroupListResults';
import { userSort } from '../../../utils/sort';
import { useReactToPrint } from 'react-to-print';
import { PrintIcon } from '../../Icons';
import CustomIconButton from '../../AdminComponents/subcomponents/CustomIconButton';
import { styled } from '@mui/material/styles';

const PREFIX = 'UserList';

const classes = {
    listContainer: `${PREFIX}-listContainer`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.listContainer}`]: {
        '@media print': {
        },
        '@media screen': {
            maxHeight: "90vh",
            overflowY: "auto",
        }
    }
}));

function UserList({ groupUid }) {
    const [users, setUsers] = useState([]);
    const [group, setGroup] = useState({});

    const [getUsers] = useLazyQuery(LIST_USER_BY_GROUP, {
        onCompleted: (data) => { setUsers(data.allUserGroups.nodes.map(u => u.userByUserUid).sort(userSort)); },
        fetchPolicy: 'cache-and-network'
    });

    const [getGroup] = useLazyQuery(GET_GROUP, {
        onCompleted: (data) => { setGroup(data.groupByUid); },
        fetchPolicy: 'cache-and-network'
    });

    const toPrintRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => toPrintRef.current,
        pageStyle: `
        @media all {
            .page-break {
              display: none;
            }
        }

        @media print {
            html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
            }

            .page-break {
                display: block;
                page-break-before: always;
            }
         }

        @page {
            size: auto;
        }
        `
    });



    const loadData = useCallback(() => {
        const vars = { variables: { groupUid } };
        getUsers(vars);
        getGroup(vars)
    }, [groupUid, getUsers, getGroup]);

    useEffect(() => {
        loadData();
    }, [loadData])

    return (
        <Root>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomIconButton color="primary" variant="contained" onClick={handlePrint} icon={<PrintIcon />} title="Imprimer" />
            </Box>
            <Container ref={toPrintRef} component="main" className={classes.listContainer}>
                <Typography component="h1" variant="h5">{group.name}</Typography>
                <Divider style={{ marginTop: '5px' }} />
                <Box>
                    <Container maxWidth={false}>
                        <Box >
                            <UserListResults users={users} />
                        </Box>
                    </Container>
                </Box>
            </Container>
        </Root>
    );
}

export default UserList;