import React, { useState, useEffect, useCallback } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Checkbox, Container, Divider, Paper, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    CREATE_GROUP_ACTIVITY,
    LIST_PERIODS,
    LIST_ADMINS,
    LIST_PLACES,
    LIST_GROUPS,
    UPDATE_GROUP_ACTIVITY
} from "../../../graphql/admin-requests";
import moment from "moment";
import { castPeriodDate } from "../../../utils/time";
import { useSnackbar } from 'notistack';
import { computeItemUpdate } from "../../../utils/reducers";


function GroupActivity({ onFinish, period, toUpdate = {} }) {
    const { enqueueSnackbar } = useSnackbar();


    let [name, setName] = useState("");
    let [startTime, setStartTime] = useState(moment().hour(14).minute(30));
    let [endTime, setEndTime] = useState(moment().hour(16).minute(30));
    let [day, setDay] = useState("MONDAY");
    let [groupsState, setGroupsState] = useState([]);
    let [groupUid, setGroupUid] = useState('');
    let [groups, setGroups] = useState([]);
    let [periodUid, setPeriodUid] = useState('');
    let [periods, setPeriods] = useState([]);
    let [places, setPlaces] = useState([]);
    let [placeUid, setPlaceUid] = useState('');
    let [organizers, setOrganizers] = useState([]);
    let [organizerUid, setOrganizerUid] = useState('');

    let [activityTitle, setGroupActivityTitle] = useState("Nouvelle option");
    let [actionTitle, setActionTitle] = useState("Ajouter");

    let [validationErrors, setValidationErrors] = useState({});

    const handleError = async (e) => {
        console.error(e);
        enqueueSnackbar("Une erreur s'est produite !");
    }

    function validate() {
        const errs = {};

        if (!name || name === '') errs.name = "Nom de l'option obligatoire";

        if (toUpdate.uid) {
            // We only update for 1 given group (that must be set)
            if(groupUid === '') errs.groups = "Au moins 1 groupe doit être sélectionné";
        } else {
            // We need to have at leat one group selected
            const nbGroupsChecked = groupsState.filter(c => c.checked).length;
            if (nbGroupsChecked === 0) errs.groups = "Au moins 1 groupe doit être sélectionné";
        }

        setValidationErrors(errs);
        return Object.keys(errs).length === 0;
    }

    const handleCheckBoxes = (event) => {
        const newGroupState = [...groupsState];
        newGroupState.find(g => g.uid === event.target.value).checked = event.target.checked;
        setGroupsState(newGroupState);
    }

    const handlePeriodSelect = useCallback((event) => {
        const pUid = event.target.value;
        setPeriodUid(pUid);
    }, [])

    const handlePlaceSelect = event => {
        setPlaceUid(event.target.value);
    }

    const handleOrganizerSelect = event => {
        setOrganizerUid(event.target.value);
    }

    const [updateGroupActivity] = useMutation(UPDATE_GROUP_ACTIVITY, {
        onCompleted: async (data) => { onFinish(); },
        onError: handleError
    });

    const [createGroupActivity, { loadingGroupActivity }] = useMutation(CREATE_GROUP_ACTIVITY, {
        onCompleted: async (data) => { onFinish(); },
        onError: handleError
    });

    // Retrieve all groups
    useQuery(LIST_GROUPS, {
        onCompleted: (data) => {
            const groups = data.allGroups.nodes;
            setGroups(groups)
            setGroupsState(groups.map(i => { return { ...i, checked: false } }));
        },
        onError: (error) => console.error("Error requesting list of groups", error),
        fetchPolicy: 'cache-and-network'
    })

    // Retrieve all periods
    useQuery(LIST_PERIODS, {
        onCompleted: (data) => {
            setPeriods(data.allPeriods.nodes);
            if (period && period.uid) {
                handlePeriodSelect({ target: { value: period.uid } });
            }
        },
        onError: (error) => console.error("Error requesting list of periods", error),
        fetchPolicy: 'cache-and-network'
    })

    // Retrieve all places
    useQuery(LIST_PLACES, {
        onCompleted: (data) => setPlaces(data.allPlaces.nodes),
        onError: (error) => console.error("Error requesting list of places", error),
        fetchPolicy: 'cache-and-network'
    })

    useQuery(LIST_ADMINS, {
        onCompleted: (data) => setOrganizers(data.allUsers.nodes),
        onError: (error) => console.error("Error requesting list of organizers", error),
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        if (toUpdate.uid) {
            const periodUid = toUpdate.periodByPeriodUid.uid;
            const organizerUid = toUpdate.userByOrganizerUid ? toUpdate.userByOrganizerUid.uid : '';
            handlePeriodSelect({ target: { value: periodUid } });
            setOrganizerUid(organizerUid);
            setPlaceUid(toUpdate.placeUid || '');
            setDay(toUpdate.day);
            setStartTime(moment(toUpdate.startTime, 'HH:mm:00'));
            setEndTime(moment(toUpdate.endTime, 'HH:mm:00'));
            setGroupActivityTitle(toUpdate.name);
            setName(toUpdate.name);
            setGroupUid(toUpdate.groupByGroupUid.uid)
            setActionTitle("Modifier");
        }
    }, [toUpdate, handlePeriodSelect]);


    async function handleGroupActivity(event) {
        event.preventDefault();
        let variables = {
            name,
            day,
            organizerUid: (organizerUid !== '') ? organizerUid : null,
            periodUid,
            placeUid: (placeUid !== '' ? placeUid : null),
            startTime: startTime.format("HH:mm:00"),
            endTime: endTime.format("HH:mm:00")
        };


        if (validate()) {
            if (toUpdate.uid) {
                variables.groupActivityUid = toUpdate.uid;
                variables.groupUid = groupUid;
                await updateGroupActivity({ variables })
            } else {
                const { toAdd: groupsToAdd } = computeItemUpdate([], groupsState);

                await Promise.all(groupsToAdd.map(async groupUid =>
                    await createGroupActivity({ variables: { ...variables, groupUid } })));
            }
        }


    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale("fr")}>
            <Container component="main" style={{ maxHeight: "90vh", overflowY: "auto" }}>
                <Typography style={{ margin: '10px' }} component="h1" variant="h4">{activityTitle}</Typography>
                <Divider style={{ marginBottom: '20px' }} />
                <Box component="form" noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <FormControl size="small" fullWidth variant="outlined">
                                <InputLabel id="period">Période</InputLabel>
                                <Select
                                    fullWidth={true}
                                    label="Période"
                                    labelid="period"
                                    id="period"
                                    value={periodUid}
                                    onChange={handlePeriodSelect}>
                                    {periods.map(p => <MenuItem key={p.uid} value={p.uid}>{p.name} ({castPeriodDate(p.startDate)} - {castPeriodDate(p.endDate)})</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="organizer">Reponsable</InputLabel>
                                <Select
                                    fullWidth={true}
                                    labelid="organizer"
                                    label="Responsable"
                                    id="organizer"
                                    value={organizerUid}
                                    onChange={handleOrganizerSelect}>
                                    <MenuItem key="empty.organizer" value=''>Aucun</MenuItem>
                                    {organizers.map(p => <MenuItem key={p.uid} value={p.uid}>{p.lastName} {p.firstName}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="place">Salle</InputLabel>
                                <Select
                                    fullWidth={true}
                                    labelid="place"
                                    label="Salle"
                                    id="place"
                                    value={placeUid}
                                    onChange={handlePlaceSelect}>
                                    <MenuItem key="empty.place" value=''>Aucune</MenuItem>
                                    {places.map(p => <MenuItem key={p.uid} value={p.uid}>{`${p.name} (${p.capacity} places)`}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                size="small"
                                name="nom"
                                required
                                onChange={(event) => setName(event.target.value)}
                                fullWidth
                                value={name}
                                id="nom"
                                label="Nom"
                                autoFocus
                                error={validationErrors.name != null}
                                helperText={validationErrors.name}
                            />
                        </Grid>
                        <Grid item xs={false} sm={6} />
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth size="small" variant="outlined">
                                <InputLabel id="jour">Jour</InputLabel>
                                <Select
                                    labelid="jour"
                                    label="Jour"
                                    id="jour"
                                    value={day}
                                    onChange={(event) => setDay(event.target.value)}
                                >
                                    <MenuItem value={"MONDAY"}>Lundi</MenuItem>
                                    <MenuItem value={"TUESDAY"}>Mardi</MenuItem>
                                    <MenuItem value={"WEDNESDAY"}>Mercredi</MenuItem>
                                    <MenuItem value={"THURSDAY"}>Jeudi</MenuItem>
                                    <MenuItem value={"FRIDAY"}>Vendredi</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TimePicker
                                inputVariant="outlined"
                                label="Heure de début"
                                value={startTime}
                                ampm={false}
                                renderInput={(props) => <TextField {...props} size="small" fullWidth required error={validationErrors.startTime != null} helperText={validationErrors.startTime} />}
                                onChange={(newValue) => { setStartTime(newValue) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TimePicker
                                inputVariant="outlined"
                                label="Heure de fin"
                                minutesStep={5}
                                value={endTime}
                                ampm={false}
                                renderInput={(props) => <TextField {...props} size="small" fullWidth required error={validationErrors.endTime != null} helperText={validationErrors.endTime} />}
                                onChange={(newValue) => { setEndTime(newValue) }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {toUpdate.uid ?
                                (<FormControl fullWidth size="small" variant="outlined">
                                    <InputLabel id="group">Groupe</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        label="Groupe"
                                        labelid="group"
                                        id="group"
                                        value={groupUid}
                                        onChange={e => { setGroupUid(e.target.value); }}>
                                        {groups.map(g => <MenuItem key={g.uid} value={g.uid}>{g.name}</MenuItem>)}
                                    </Select>
                                </FormControl>) :
                                (<FormGroup row>
                                    {
                                        groupsState.map(g => (
                                            <Paper variant="outlined" key={g.uid}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={g.checked}
                                                            onChange={handleCheckBoxes}
                                                            name={g.name}
                                                            value={g.uid}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={g.name}
                                                    key={g.uid}
                                                />
                                            </Paper>))
                                    }

                                </FormGroup>)}
                            {validationErrors.groups && <FormHelperText error>{validationErrors.groups}</FormHelperText>}
                        </Grid>
                    </Grid>
                </Box>
                <Button
                    style={{ float: "right", margin: '10px' }}
                    variant="outlined"
                    color="primary"
                    disabled={loadingGroupActivity}
                    onClick={handleGroupActivity}>{actionTitle}</Button>


            </Container >
        </LocalizationProvider >
    );

}
export default GroupActivity;