import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Box, Card, CardContent, CardHeader, Divider, Typography, colors, useTheme } from '@mui/material';
import { ActivityIcon, CheckIcon } from '../../Icons';
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from 'react';
import { fillingReducer } from '../../../utils/reducers';
import { LIST_ACTIVITIES_ADMIN, LIST_ACTIVITIES_SUPER_ADMIN } from '../../../graphql/admin-requests';


ChartJS.register(ArcElement);
const Filling = ({ period, role = "admin", ...props }) => {
    const theme = useTheme();

    const [total, setTotal] = useState(1);
    const [free, setFree] = useState(1);
    const [taken, setTaken] = useState(1);

    const listRequest = { "horaroj_admin": LIST_ACTIVITIES_ADMIN, "horaroj_super_admin": LIST_ACTIVITIES_SUPER_ADMIN }[role];
    const resName = { "horaroj_admin": "allActivityAdminViews", "horaroj_super_admin": "allActivitySuperAdminViews" }[role];

    const data = {
        datasets: [{
            data: [taken, free],
            backgroundColor: [theme.palette.primary.activityRegister, theme.palette.primary.activityFree],
            borderWidth: 3,
            borderColor: colors.common.white,
            hoverBorderColor: colors.common.white
        }],
        labels: ['Inscrits', 'Libre']
    };

    const options = {
        plugins: {
            legend: { display: false },
            tooltip: {
                backgroundColor: theme.palette.background.paper,
                bodyColor: "black",
                borderColor: theme.palette.divider,
                borderWidth: 1,
                enabled: true
            }
        },
        animation: { duration: 1000 },
        maintainAspectRatio: false,
        responsive: true,
    };

    const devices = [
        { title: 'Inscrits', value: (taken / total * 100).toFixed(2), icon: CheckIcon, color: theme.palette.primary.activityRegister },
        { title: 'Libre', value: (free / total * 100).toFixed(2), icon: ActivityIcon, color: theme.palette.primary.activityFree }];

    const [getActivities] = useLazyQuery(listRequest, {
        onCompleted: (data) => {
            const activities = data[resName].nodes;
            if (activities.length > 0) {
                const res = fillingReducer(activities);
                setTotal(res.capacity);
                setTaken(res.taken);
                setFree(res.capacity - res.taken);
            } else {
                setTotal(0);
                setTaken(0);
                setFree(0);
            }
        },
        onError: (error) => {
            console.log("DATA ERROR", error);
        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        if (period.uid) {
            const periodF = { periodUid: { equalTo: period.uid } };
            getActivities({
                variables: {
                    filter: { ...periodF },
                    first: 10000
                }
            });
        }
    }, [getActivities, period.uid])

    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardHeader title="REMPLISSAGE DES ACTIVITES" />
            <Divider />
            <CardContent hidden={total === 0}>
                <Box sx={{ height: 200, position: 'relative' }}>
                    <Doughnut data={data} options={options} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
                    {devices.map(({ color, icon: Icon, title, value }) => (
                        <Box key={title} sx={{ p: 2, textAlign: 'center' }}>
                            <Icon color="action" />
                            <Typography color="textPrimary" variant="body1">{title}</Typography>
                            <Typography color="textPrimary" variant="h4">{value}%</Typography>
                        </Box>
                    ))}
                </Box>
            </CardContent>
            <CardContent hidden={total > 0}>
                Aucune valeur à afficher
            </CardContent>
        </Card>
    );
};

export default Filling;