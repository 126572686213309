import { Box, Card, CardContent, TextField, InputAdornment, SvgIcon, FormControl, InputLabel, MenuItem, Select, Grid } from '@mui/material';
import { SearchIcon } from '../../Icons';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import moment from "moment";


const ActivityListToolbar = ({ onSubmit, dayFilter, setDayFilter, startTime, setStartTime, endTime, setEndTime, updateSearch, setOffset, isSearchEnabled, setSearchEnabled, setPage, ...props }) => {

    const shakeABit = () => {
        setSearchEnabled(true);
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onSubmit(event);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale("fr")}>
            <Box {...props}>
                <Box sx={{ mt: 3 }}>
                    <Card>
                        <CardContent>
                            <Box >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                                                    </InputAdornment>
                                                )
                                            }}
                                            placeholder="Rechercher une activité"
                                            variant="outlined"
                                            onKeyDown={handleKeyDown}
                                            onChange={i => { shakeABit(); updateSearch(i) }}
                                        />
                                    </Grid>
                                    <Grid item xs={false} md={false} lg={6} />
                                    <Grid item xs={12} md={12} lg={3}>
                                        <FormControl variant="outlined" fullWidth size="small">
                                            <InputLabel id="jour">Jour</InputLabel>
                                            <Select
                                                labelid="jour"
                                                label="jour"
                                                id="jour"
                                                fullWidth
                                                value={dayFilter}
                                                onChange={event => { shakeABit(); setDayFilter(event.target.value) }}
                                            >
                                                <MenuItem value={""}>Tous les jours</MenuItem>
                                                <MenuItem value={"MONDAY"}>Lundi</MenuItem>
                                                <MenuItem value={"TUESDAY"}>Mardi</MenuItem>
                                                <MenuItem value={"WEDNESDAY"}>Mercredi</MenuItem>
                                                <MenuItem value={"THURSDAY"}>Jeudi</MenuItem>
                                                <MenuItem value={"FRIDAY"}>Vendredi</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={false} md={false} lg={9} />
                                    <Grid item xs={12} md={12} lg={3}>
                                        <TimePicker
                                            size="small"
                                            renderInput={(props) => <TextField {...props} />}
                                            inputVariant='outlined'
                                            fullWidth
                                            label="Démarre après"
                                            minutesStep={5}
                                            value={startTime}
                                            ampm={false}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ['clear', 'accept']
                                                }
                                            }}
                                            onChange={i => { setStartTime(i); shakeABit(); }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={3}>
                                        <TimePicker
                                            size="small"
                                            renderInput={(props) => <TextField {...props} />}
                                            inputVariant='outlined'
                                            fullWidth
                                            label="Termine avant"
                                            minutesStep={5}
                                            value={endTime}
                                            ampm={false}
                                            componentsProps={{
                                                actionBar: {
                                                    actions: ['clear', 'accept']
                                                }
                                            }}
                                            onChange={i => { setEndTime(i); shakeABit(); }}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box >
        </LocalizationProvider>
    )
};

export default ActivityListToolbar;