import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { ActivityIcon } from '../../Icons';
import { COUNT_ACTIVITIES } from "../../../graphql/admin-requests";
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import { useQuery } from "@apollo/client";

const Activities = ({ period, ...props }) => {
    const [count, setCount] = useState(0);

    useQuery(COUNT_ACTIVITIES, {
        variables: { period_uid: period.uid },
        onCompleted: (data) => { setCount(data.allActivities.totalCount) },
        fetchPolicy: 'cache-and-network'
    });

    const theme = useTheme();

    return (
        <Card sx={{ height: '100%' }} {...props} >
            <CardContent>
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
                    <Grid item>
                        <Typography color="textSecondary" gutterBottom variant="h6">ACTIVITES</Typography>
                        <Typography color="textPrimary" variant="h3">{count}</Typography>
                    </Grid>
                    <Grid item>
                        <Avatar sx={{ backgroundColor: theme.palette.primary.main, height: 56, width: 56 }}>
                            <ActivityIcon />
                        </Avatar>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};

export default Activities;