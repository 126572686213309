import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress } from '@mui/material';
import { UNREGISTER_ACTIVITY_FOR_USER } from '../../../graphql/admin-requests';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';


const GroupActivityConfirmDelete = ({ conflicts, onClose, open, ...rest }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [confirmed, setConfirmed] = useState(false);
    const [progress, setProgress] = useState(0);

    const [unRegisterActivity] = useMutation(UNREGISTER_ACTIVITY_FOR_USER, {
        onError: e => { enqueueSnackbar(`Impossible de se désinscrire de l'activité`, { variant: "warning" }) },
        onCompleted: d => { enqueueSnackbar(`Désinscription de l'activité validée`, { variant: "success" }) }
    })

    const handleConfirm = async () => {
        setConfirmed(true);

        let i = 1;
        const tasks = conflicts.map(c => [i++, () =>
            new Promise((res) => {
                setTimeout(async () => {
                    await unRegisterActivity({ variables: { userUid: c.userByUserUid.uid, activityUid: c.activityByActivityUid.uid } })
                    res("ok");
                }, 100);
            })]);

        for (let [k, task] of tasks) {
            setProgress(100 / tasks.length * k)
            await task();
        };

        onClose();
        setProgress(0);
        setConfirmed(false);

    }

    return (
        <div>
            <Dialog keepMounted open={open} onClose={onClose}>
                <DialogTitle>Désinscrire élèves</DialogTitle>

                {confirmed ?
                    <DialogContent>
                        <LinearProgress variant="determinate" value={progress} />
                    </DialogContent> :
                    <div>
                        <DialogContent>
                            <DialogContentText>
                                Vous êtes sur le point de <strong>supprimer {conflicts.length} inscriptions</strong>.<br />
                                Etes vous sûr ?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">Annuler</Button>
                            <Button onClick={handleConfirm} color="primary">Confirmer</Button>
                        </DialogActions></div>}


            </Dialog>
        </div>);
};


export default GroupActivityConfirmDelete;