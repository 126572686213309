import { Box, Container, Divider, Typography } from '@mui/material';
import { LIST_USER_BY_ACTIVITY, GET_ACTIVITY } from "../../../graphql/admin-requests";
import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect, useCallback, useRef } from 'react';
import UserByActivityListResults from './UserByActivityListResults';
import { userSort } from '../../../utils/sort';
import { useReactToPrint } from 'react-to-print';
import { PrintIcon } from '../../Icons';
import CustomIconButton from '../../AdminComponents/subcomponents/CustomIconButton';
import { styled } from '@mui/material/styles';
import { formatActivityTime } from '../../../utils/time';



const PREFIX = 'UserByActivityList';

const classes = {
    listContainer: `${PREFIX}-listContainer`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.listContainer}`]: {
        '@media print': {
        },
        '@media screen': {
            maxHeight: "90vh",
            overflowY: "auto",
        }
    }
}));



function UserByActivityList({ activityUid }) {
    const [users, setUsers] = useState([]);
    const [activity, setActivity] = useState({});

    const [getUsers] = useLazyQuery(LIST_USER_BY_ACTIVITY, {
        onCompleted: (data) => setUsers([...data.allUserActivities.nodes.map(u => u.userByUserUid)].sort(userSort)),
        fetchPolicy: 'cache-and-network'
    });

    const [getActivity] = useLazyQuery(GET_ACTIVITY, {
        onCompleted: (data) => setActivity(data.activityByUid),
        fetchPolicy: 'cache-and-network'
    });

    const formatActivityName = a => {
        const o = a.userByOrganizerUid;
        if (o) return `(${o.firstName} ${o.lastName})`;
        return ``;
    }

    const formatActivity = (a) => {
        return `${a.name} | ${formatActivityTime(a)} | ${a.placeByPlaceUid && a.placeByPlaceUid.name} ${formatActivityName(a)}`;
    }

    const toPrintRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => toPrintRef.current,
        pageStyle: `
        @media all {
            .page-break {
              display: none;
            }
        }

        @media print {
            html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
            }

            .page-break {
                display: block;
                page-break-before: always;
            }
         }

        @page {
            size: auto;
        }
        `
    });



    const loadData = useCallback(() => {
        const vars = {
            variables: {
                activityUid, first: 1000
            }
        };
        getUsers(vars);
        getActivity(vars);
    }, [activityUid, getUsers, getActivity]);

    useEffect(() => {
        loadData();
    }, [loadData])

    return (
        <Root>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CustomIconButton color="primary" variant="contained" onClick={handlePrint} icon={<PrintIcon />} title="Imprimer" />
            </Box>
            <Container ref={toPrintRef} component="main" className={classes.listContainer}>
                <Typography component="h1" variant="h5">{formatActivity(activity)} </Typography>
                <Divider style={{ marginTop: '5px' }} />
                <Box>
                    <Container maxWidth={false}>
                        <Box><UserByActivityListResults users={users} /></Box>
                    </Container>
                </Box>
            </Container>
        </Root >
    )
}

export default UserByActivityList;