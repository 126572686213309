import { Card, CardContent, CardHeader, Divider } from '@mui/material';

import { GET_ACTIVITIES_DISTRIBUTION } from "../../../graphql/admin-requests";
import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from 'react';
import Chart from "react-apexcharts";
import { day_translate } from "../../../utils/translate";
import { useTheme } from '@mui/material/styles';


const HeatMap = ({ period }) => {

    const [activities, setActivities] = useState([]);
    const theme = useTheme();

    const [getActivities] = useLazyQuery(GET_ACTIVITIES_DISTRIBUTION, {
        onCompleted: (data) => {

            const allActivities = data.getActivitiesDistribution.nodes;

            const groupByStartTime = allActivities.reduce((acc, value) => {
                if (!acc[value.startTime]) {
                    acc[value.startTime] = [];
                }
                acc[value.startTime].push(value);
                return acc;
            }, {});

            const allDays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

            const initData = [];

            Object.keys(groupByStartTime).forEach(startTime => {
                initData.push({
                    name: startTime, data: allDays.map(d => {
                        const found = groupByStartTime[startTime].find(i => i.day === d);
                        if (found)
                            return { x: day_translate['FR'][d], y: parseInt(found.nb) }
                        else
                            return { x: day_translate['FR'][d], y: 0 }
                    })
                }
                )
            });

            const res = initData.sort((a, b) => {
                if (a.name > b.name)
                    return -1;
                if (a.name < b.name)
                    return 1;
                return 0;
            });

            setActivities(res);
        },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        getActivities({ variables: { periodUid: period.uid } });
    }, [getActivities, period])

    const options = {
        dataLabels: {
            enabled: false
        },
        colors: [theme.palette.primary.main]
    };

    return (
        <Card>
            <CardHeader title="REPARTITION DES ACTIVITES PAR CRENEAUX/JOURS" />
            <Divider />
            <CardContent>
                {activities.length === 0 && "Aucune valeur à afficher"}
                {activities.length > 0 && <Chart
                    options={options}
                    series={activities}
                    type="heatmap"
                    height="400"
                />}

            </CardContent>
        </Card>)
}

export default HeatMap;