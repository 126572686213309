import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Container, Divider, Grid, TextField, Typography } from '@mui/material';
import { CREATE_PERIOD, LIST_GRADES, CREATE_PERIOD_GRADE, DELETE_PERIOD_GRADE, UPDATE_PERIOD } from "../../../graphql/admin-requests";
import { LocalizationProvider, DatePicker, DateTimePicker} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import "moment/locale/fr";
import Select from "react-select";
import { computeItemUpdate } from '../../../utils/reducers';
import { dbToLocale } from '../../../utils/time';


moment.locale("fr");

function Period({ onFinish, toUpdate = {} }) {
    let [name, setName] = useState("");
    let [grades, setGrades] = useState([]);
    let [defaultGrades, setDefaultGrades] = useState([]);

    const [selectedStartDate, handleStartDateChange] = useState(new Date());
    const [selectedEndDate, handleEndDateChange] = useState(new Date());
    const [selectedOpenStartDate, handleOpenStartDateChange] = useState(null);
    const [selectedOpenEndDate, handleOpenEndDateChange] = useState(null);
    const [selectedAdminOpenStartDate, handleAdminOpenStartDateChange] = useState(null);
    const [selectedAdminOpenEndDate, handleAdminOpenEndDateChange] = useState(null);
    const [selectedGrade, handleGradeChange] = useState([]);

    let [activityTitle, setActivityTitle] = useState("Nouvelle période");
    let [actionTitle, setActionTitle] = useState("Ajouter");


    const [linkPeriodGrade] = useMutation(CREATE_PERIOD_GRADE, {
        onCompleted: (data) => {
            console.log("Periode liée au niveau", data);
            onFinish();
        },
        onError: (error) => console.error("Error linking activity to group", error)
    })


    const [unlinkPeriodGrade] = useMutation(DELETE_PERIOD_GRADE, {
        onCompleted: (data) => {
            console.log("Periode retirée du niveau", data);
            onFinish();
        },
        onError: (error) => console.error("Error unlinking period to grade", error)
    })

    const [createPeriod, { creationLoading, creationError }] = useMutation(CREATE_PERIOD, {
        onCompleted: d => {
            selectedGrade.map(async grade => await linkPeriodGrade({
                variables: {
                    periodUid: d.createPeriod.period.uid,
                    gradeUid: grade.uid
                }
            }));
            console.log("Periode ajoutée", "success");
        },
        onError: e => {
            console.error("Impossible d'ajouter la periode", "error");
        }
    });

    const [updatePeriod, { updateLoading, loadingError }] = useMutation(UPDATE_PERIOD, {
        onCompleted: d => {
            const currentGrades = toUpdate.periodGradesByPeriodUid.nodes.map(i => i.gradeByGradeUid.uid)
            const { toAdd, toDelete } = computeItemUpdate(currentGrades, selectedGrade.map(i => { return { ...i, checked: true } }));

            toAdd.map(async gradeUid => await linkPeriodGrade({
                variables: { periodUid: toUpdate.uid, gradeUid }
            }));

            toDelete.map(async gradeUid => await unlinkPeriodGrade({
                variables: { periodUid: toUpdate.uid, gradeUid }
            }));
            console.log("Periode mise à jour", "success");
        },
        onError: e => {
            console.error("Impossible de modifier la periode", "error");
        }
    });

    // Retrieve all grades
    useQuery(LIST_GRADES, {
        onCompleted: (data) => { setGrades(data.allGrades.nodes); setDefaultGrades(data.allGrades.nodes); handleGradeChange(data.allGrades.nodes) },
        onError: (error) => console.error("Erreur lors de la récuperation des niveaux", error),
        fetchPolicy: 'cache-and-network'

    })

    async function handlePeriod(event) {
        event.preventDefault();

        // Note ARE : Les dates sont envoyées avec les information de Timezone (ex: 2023-03-09T11:00:00+01:00, 2023-03-27T10:00:00+02:00) mais seront stockées en UTC

        console.debug("[Debug][HandlePeriod] UI ======> Date ", selectedOpenStartDate);
        console.debug("[Debug][HandlePeriod] Date ====> DB", dbToLocale(toUpdate.openUserStart).format());

        const variables = {
            name,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            openUserStart: selectedOpenStartDate,
            openUserEnd: selectedOpenEndDate,
            openAdminStart: selectedAdminOpenStartDate,
            openAdminEnd: selectedAdminOpenEndDate
        };

        if (toUpdate.uid) {
            variables.uid = toUpdate.uid;
            await updatePeriod({ variables });
        } else {
            await createPeriod({ variables });
        }


        onFinish();
    }

    useEffect(() => {
        if (toUpdate.uid) {
            setActionTitle("Modifier");
            setActivityTitle("Modifier la période");
            setName(toUpdate.name);
            handleStartDateChange(toUpdate.startDate);
            handleEndDateChange(toUpdate.endDate);

            console.debug("[Debug][UseEffect   ] DB =====> Date        ", toUpdate.openUserStart);
            console.debug("[Debug][UseEffect   ] Date ===> UI          ", dbToLocale(toUpdate.openUserStart));
            console.debug("[Debug][UseEffect   ] Date ===> UI (format) ", dbToLocale(toUpdate.openUserStart).format());

            handleOpenStartDateChange(toUpdate.openUserStart && dbToLocale(toUpdate.openUserStart));
            handleOpenEndDateChange(toUpdate.openUserEnd && dbToLocale(toUpdate.openUserEnd));
            handleAdminOpenStartDateChange(toUpdate.openAdminStart && dbToLocale(toUpdate.openAdminStart));
            handleAdminOpenEndDateChange(toUpdate.openAdminEnd && dbToLocale(toUpdate.openAdminEnd));
            setDefaultGrades(toUpdate.periodGradesByPeriodUid.nodes.map(i => i.gradeByGradeUid));
        }
    }, [toUpdate]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={moment.locale("fr")}>
            <Container component="main">
                <Typography style={{ margin: '10px' }} component="h1" variant="h5">{activityTitle}</Typography>
                <Divider style={{ marginBottom: '20px' }} />

                <Box component="form" noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                size="small"
                                variant="outlined"
                                name="period"
                                required
                                onChange={(event) => setName(event.target.value)}
                                fullWidth
                                value={name}
                                id="period"
                                label="Nom de la période"
                                autoFocus
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                fullWidth
                                size="medium"
                                openTo="month"
                                disableMaskedInput
                                inputFormat="dddd DD MMMM yyyy"
                                label="Date de début"
                                views={["year", "month", "day"]}
                                value={selectedStartDate}
                                renderInput={(props) => <TextField {...props} />}
                                onChange={handleStartDateChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                fullWidth={true}
                                size="small"
                                openTo="month"
                                disableMaskedInput={true}
                                inputFormat="dddd DD MMMM yyyy"
                                label="Date de fin"
                                views={["year", "month", "day"]}
                                value={selectedEndDate}
                                renderInput={(props) => <TextField {...props} />}
                                onChange={handleEndDateChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateTimePicker
                                fullWidth
                                size="small"
                                inputFormat="DD/MM/yyyy HH:mm:00"
                                disableMaskedInput={true}
                                label="Ouverture inscriptions"
                                views={["year", "month", "day", "hours", "minutes"]}
                                value={selectedOpenStartDate}
                                ampm={false}
                                minutesStep={5}
                                renderInput={(props) => <TextField {...props} />}
                                onChange={handleOpenStartDateChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateTimePicker
                                fullWidth
                                size="small"
                                inputFormat="DD/MM/yyyy HH:mm:00"
                                disableMaskedInput={true}
                                label="Fermeture inscriptions"
                                views={["year", "month", "day", "hours", "minutes"]}
                                ampm={false}
                                value={selectedOpenEndDate}
                                minutesStep={5}
                                renderInput={(props) => <TextField {...props} />}
                                onChange={handleOpenEndDateChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateTimePicker
                                fullWidth
                                size="small"
                                inputFormat="DD/MM/yyyy HH:mm:00"
                                disableMaskedInput={true}
                                label="Ouverture saisie enseignants"
                                views={["year", "month", "day", "hours", "minutes"]}
                                value={selectedAdminOpenStartDate}
                                ampm={false}
                                minutesStep={5}
                                renderInput={(props) => <TextField {...props} />}
                                onChange={handleAdminOpenStartDateChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                size="small"
                                inputFormat="DD/MM/yyyy HH:mm:00"
                                label="Fermeture saisie enseignants"
                                views={["year", "month", "day", "hours", "minutes"]}
                                ampm={false}
                                value={selectedAdminOpenEndDate}
                                minutesStep={5}
                                renderInput={(props) => <TextField {...props} />}
                                onChange={handleAdminOpenEndDateChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                fullWidth
                                size="small"
                                minMenuHeight={1000}
                                placeholder="Niveaux"
                                isClearable={true}
                                isSearchable={true}
                                getOptionValue={i => i.uid}
                                getOptionLabel={i => i.name}
                                defaultValue={defaultGrades}
                                name="day"
                                isMulti
                                onChange={handleGradeChange}
                                options={grades}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Button
                    style={{ float: "right", margin: "10px" }}
                    variant="outlined"
                    color="primary"
                    disabled={creationLoading || updateLoading}
                    onClick={handlePeriod}>{actionTitle}</Button>
                {creationError && <p>{creationError.message}</p>}
                {loadingError && <p>{loadingError.message}</p>}
            </Container>
        </LocalizationProvider >
    );

}
export default Period;