import { Box, Container } from '@mui/material';
import { DELETE_PERIOD, LIST_PERIODS } from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import PeriodListResults from './PeriodListResults';
import PeriodListToolbar from './PeriodListToolbar';
import { useSnackbar } from 'notistack';


function PeriodList() {
    const { enqueueSnackbar } = useSnackbar();


    const [periods, setPeriods] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(5);
    const [offset, setOffset] = useState(0);

    let [nameFilter, setNameFilter] = useState("");


    const [getPeriods] = useLazyQuery(LIST_PERIODS, {
        onCompleted: (data) => {
            setPeriods(data.allPeriods.nodes);
            setTotalCount(data.allPeriods.totalCount);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [deletePeriod] = useMutation(DELETE_PERIOD, {
        onError: e => enqueueSnackbar("Impossible de supprimer la periode"),
        onCompleted: d => enqueueSnackbar("Periode supprimée")
    })

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const loadData = useCallback(() => {
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const filters = { ...nameF };
        const filter = {
            variables: {
                filter: filters, first: limit,
                offset
            }
        }
        getPeriods(filter);
    }, [nameFilter, getPeriods, limit, offset])

    const handleDeletePeriod = useCallback(async (periodUid) => {
        await deletePeriod({
            variables: { periodUid }
        });
        loadData();
    }, [deletePeriod, loadData]);

    useEffect(() => {
        loadData();
    }, [loadData])



    return (
        <Container component="main" maxWidth={false}>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <PeriodListToolbar
                        loadData={loadData}
                        updateSearch={updateSearch}
                    />
                    <Box sx={{ pt: 3 }}>
                        <PeriodListResults
                            loadData={loadData}
                            periods={periods}
                            limit={limit}
                            offset={offset}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            handleDeletePeriod={handleDeletePeriod} />
                    </Box>
                </Container>
            </Box>
        </Container >
    )
};

export default PeriodList;