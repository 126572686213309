
import { Box, Toolbar, MenuItem, Select, IconButton, Button, Divider, List, CssBaseline, Typography } from '@mui/material';

import { ClassroomActivityIcon, ChevronLeftIcon, ChevronRightIcon, ActivityIcon, MenuIcon, DashboardIcon, GroupActivityIcon, AccountCircleIcon, ClassroomIcon } from "../Icons"

import { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER } from "../../graphql/admin-requests";
import jwt_decode from "jwt-decode";
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import AdminDashboard from './AdminDashboard';
import ActivityList from './ActivityList/ActivityList';
import ClassroomActivityList from './ClassroomActivityList/ClassroomActivityList';
import GroupActivityList from './GroupActivityList/GroupActivityList';
import GroupList from './GroupList/GroupList';
import GradeList from './GradeList/GradeList';
import PlaceList from './PlaceList/PlaceList';
import PeriodList from './PeriodList/PeriodList';
import ClassroomList from './ClassroomList/ClassroomList';
import { LIST_PERIODS_INFO_ADMIN } from "../../graphql/admin-requests";
import { Route, Routes, useNavigate, createSearchParams, useLocation } from "react-router-dom";

import CustomMenuItem from './subcomponents/CustomMenuItem';
import Profile from './Profile';
import ClassroomCalendar from './CalendarList/ClassroomCalendar';
import ProcessCalendarList from './ProcessCalendarList/ProcessCalendarList';
import { adminProcessCalendarMapping } from '../../utils/process';
import { periodAdminSort } from '../../utils/sort';
import { AppBar, Drawer, DrawerHeader } from '../../utils/commons';



function LoggedApp(props) {
    const urlSeachParams = new URLSearchParams(useLocation().search)
    const navigate = useNavigate();
    const [period, setPeriod] = useState("");
    let [open, setOpen] = useState(true);
    let [user, setUser] = useState();
    let [periods, setPeriods] = useState([]);

    const updatePeriod = newPeriod => {
        navigate({
            pathname: ".",
            search: createSearchParams({
                periodUid: newPeriod.uid
            }).toString(),
        }, {
            relative: "path"
        })
        selectPeriod(periods, newPeriod.uid);
    }

    const selectPeriod = (periods, periodUid) => {
        if (periodUid) {
            let p = periods.find(p => p.uid === periodUid);
            if (p) {
                setPeriod(p);
            }
        } else {
            const firstPeriod = periods.sort(periodAdminSort)[0];
            if (firstPeriod) { setPeriod(firstPeriod) }
        }
    }

    function handleLogout() {
        localStorage.removeItem("HorarojJWTToken");
        setUser(null);
        props.setLogged(false);
        navigate("/");
    }

    function switchOpen() {
        setOpen(!open);
    }

    const [getUser] = useLazyQuery(GET_USER, {
        onCompleted: (data) => {
            setUser(data.userByUid);
        }
    });

    const [getPeriods] = useMutation(LIST_PERIODS_INFO_ADMIN, {
        onCompleted: (data) => {
            setPeriods(data.getPeriodsAllInfo.periodInfos);
            selectPeriod(data.getPeriodsAllInfo.periodInfos, urlSeachParams.get("periodUid"));
        }
    });

    const theme = createTheme(useTheme(), {
        palette: {
            primary: {  main: "#A44EBC", contrastText: "#FFFFFF", activityFree: "#DE6E4B", activityRegister: "#66A182"}
        },
    });


    useEffect(() => {
        const token = localStorage.getItem("HorarojJWTToken");
        if (token) {
            const decodedToken = jwt_decode(token);
            // Retrieve user
            getUser({ variables: { userUid: decodedToken.user_uid } });
            getPeriods({});
        }
    }, [getUser, getPeriods]);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open} color="primary">
                    <Toolbar>
                        <IconButton
                            onClick={switchOpen}
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="div" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <img src="/assets/img/saas-c/logo/logo-wiiik.png" alt="Logo Wiiik" /> {user && user.firstName} {user && user.lastName}
                        </Typography>

                        <Button onClick={handleLogout} edge="end" color="inherit">Se déconnecter</Button>
                    </Toolbar>
                </AppBar>
                <Drawer variant={['xs', 'sm', 'md'].includes(props.width) ? "temporary" : "permanent"} open={open}>
                    <DrawerHeader>
                        <IconButton onClick={switchOpen}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Select size="small" onChange={event => updatePeriod(event.target.value)} value={period} >
                        {periods.map(p => (<MenuItem key={p.uid} value={p}>{p.name}</MenuItem>))}
                    </Select>
                    <List>
                        <CustomMenuItem title="Tableau de bord" open={open} link={{ pathname: "/admins/dashboard", search: useLocation().search }} icon={<DashboardIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <CustomMenuItem title="Activités" open={open} link={{ pathname: "/admins/activities", search: useLocation().search }} icon={<ActivityIcon />} />
                        <CustomMenuItem title="Cours" open={open} link={{ pathname: "/admins/classroomActivities", search: useLocation().search }} icon={<ClassroomActivityIcon />} />
                        <CustomMenuItem title="Options" open={open} link={{ pathname: "/admins/groupActivities", search: useLocation().search }} icon={<GroupActivityIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <CustomMenuItem title="Classes" open={open} link={{ pathname: "/admins/classrooms", search: useLocation().search }} icon={<ClassroomIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <CustomMenuItem title="Mon profil" open={open} link={{ pathname: "/admins/profile", search: useLocation().search }} hideTooltip={open} icon={<AccountCircleIcon />} />
                    </List>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <DrawerHeader />
                    <Routes>
                        <Route path="/" exact element={<ActivityList adminView={true} user={user} listItemName="allActivityAdminViews" period={period} />} />
                        <Route path="/admins/dashboard" element={<AdminDashboard period={period} />} />
                        <Route path="/admins/activities" element={<ActivityList adminView={true} user={user} listItemName="allActivityAdminViews" period={period} />} />
                        <Route path="/admins/classrooms" exact element={<ClassroomList period={period} readOnly={true} />} />
                        <Route path="/admins/profile" element={<Profile user={user} />} />
                        <Route path="/admins/classrooms/:classroomUid/process" element={<ProcessCalendarList period={period} mapping={adminProcessCalendarMapping} role="horaroj_admin" />} />
                        {/* Really used ?? 👇 */}
                        <Route path="/admins/classroomActivities" element={<ClassroomActivityList period={period} readOnly={true} />} />
                        <Route path="/admins/groupActivities" element={<GroupActivityList period={period} readOnly={true} />} />
                        <Route path="/admins/calendars/classroom/:classroomUid" element={<ClassroomCalendar period={period} readOnly={true} />} />
                        <Route path="/admins/groups" element={<GroupList />} />
                        <Route path="/admins/grades" element={<GradeList />} />
                        <Route path="/admins/periods" element={<PeriodList />} />
                        <Route path="/admins/places" element={<PlaceList />} />
                    </Routes>
                </Box>
            </Box>
        </ThemeProvider>

    );
}

export default LoggedApp;