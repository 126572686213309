import React, { useState } from 'react';
import { useQuery, useMutation } from "@apollo/client";

import { Box, Button, Checkbox, Container, Divider, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { LIST_GROUPS, FILL_ACTIVITY_FOR_GROUPS } from "../../../graphql/admin-requests";
import { useSnackbar } from 'notistack';


function FillActivityForGroups({ onFinish, activity }) {

    const { enqueueSnackbar } = useSnackbar();


    let [groupsState, setGroupsState] = useState([]);


    // Retrieve all groups
    useQuery(LIST_GROUPS, {
        onCompleted: (data) => {
            const groups = data.allGroups.nodes;
            setGroupsState(groups.map(i => { return { ...i, checked: false } }));
        },
        onError: (error) => console.error("Error requesting list of groups", error),
        fetchPolicy: 'cache-and-network'
    })

    const [fillActivity] = useMutation(FILL_ACTIVITY_FOR_GROUPS, {
        onCompleted: async (data) => {
            const nb = data.fillActivityForGroups.integer;
            enqueueSnackbar(`Activité ${activity.name} ajoutée à ${nb} élèves`, { variant: "success" });
            onFinish();
        },
        onError: async (e) => {
            enqueueSnackbar("Une erreur s'est produite !", { variant: "error" });
        }
    });

    const handleCheckBoxes = (event) => {
        const newGroupState = [...groupsState];
        newGroupState.find(g => g.uid === event.target.value).checked = event.target.checked;
        setGroupsState(newGroupState);
    }

    const handleValidate = async () => {
        const groupUids = groupsState.filter(i => i.checked).map(i => i.uid);
        await fillActivity({ variables: { groupUids, activityUid: activity.uid } })
    }


    return (
        <Container component="main" >
            <Typography variant="h6">Ajouter l'activité {activity.name}</Typography>
            <Divider />
            <Box component="form" noValidate>
                <Grid item xs={12} sm={6}>
                    <FormGroup row>{
                        groupsState.map(g => {
                            return (<FormControlLabel
                                control={<Checkbox checked={g.checked} onChange={handleCheckBoxes} name={g.name} value={g.uid} color="primary" />}
                                label={g.name}
                                key={g.uid} />);
                        })
                    }</FormGroup>
                </Grid>
            </Box>
            <Button style={{ float: "right", margin: '10px' }} variant="outlined" color="primary" disabled={false} onClick={handleValidate}>Valider les groupes</Button>
        </Container>
    );
}

export default FillActivityForGroups;