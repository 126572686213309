import { Box, Container } from '@mui/material';
import { LIST_GROUP_ACTIVITIES, DELETE_GROUP_ACTIVITY, LIST_GROUPS } from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import GroupActivityListResults from './GroupActivityListResults';
import GroupActivityListToolbar from './GroupActivityListToolbar';
import moment from "moment";
import { useSnackbar } from 'notistack';



function GroupActivityList({ readOnly, period }) {

    const { enqueueSnackbar } = useSnackbar();


    const daysOptions = [
        { value: "MONDAY", label: "Lundi" },
        { value: "TUESDAY", label: "Mardi" },
        { value: "WEDNESDAY", label: "Mercredi" },
        { value: "THURSDAY", label: "Jeudi" },
        { value: "FRIDAY", label: "Vendredi" }
    ]

    const [groupActivities, setGroupActivities] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);

    let [dayFilter, setDayFilter] = useState(daysOptions.map(i => i.value));
    let [groupsOptions, setGroupsOptions] = useState([]);
    let [groupFilter, setGroupFilter] = useState([]);
    let [nameFilter, setNameFilter] = useState("");
    let [startTime, setStartTime] = useState(null);
    let [endTime, setEndTime] = useState(null);



    const [getActivities] = useLazyQuery(LIST_GROUP_ACTIVITIES, {
        onCompleted: (data) => {
            setGroupActivities(data.allGroupActivities.nodes);
            setTotalCount(data.allGroupActivities.totalCount);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [deleteActivity] = useMutation(DELETE_GROUP_ACTIVITY, {
        onError: e => enqueueSnackbar("Impossible de supprimer le créneau d'option", { variant: "error" }),
        onCompleted: d => enqueueSnackbar("Créneau d'option supprimé", { variant: "success" })
    });

    const [getGroups] = useLazyQuery(LIST_GROUPS, {
        onCompleted: (data) => {
            const groupOptions = data.allGroups.nodes.map(g => { return { value: g.uid, label: g.name } });
            setGroupsOptions(groupOptions);
        },
        fetchPolicy: 'cache-and-network'
    });

    const updateDayFilter = (days) => {
        setDayFilter(days.map(i => i.value));
    }

    const updateGroupFilter = (options) => {
        setGroupFilter(options.map(i => i.value));
    }

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const loadData = useCallback(() => {
        const dayF = dayFilter.length > 0 ? { day: { in: dayFilter } } : {};
        const groupF = groupFilter.length > 0 ? { groupUid: { in: groupFilter } } : {};
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const startF = startTime ? { startTime: { greaterThanOrEqualTo: moment(startTime).format("HH:mm:00") } } : {};
        const endF = endTime ? { endTime: { lessThanOrEqualTo: moment(endTime).format("HH:mm:00") } } : {};
        const periodF = { periodUid: { equalTo: period.uid } };

        const filters = { ...dayF, ...nameF, ...startF, ...endF, ...periodF, ...groupF };
        const filter = {
            variables: {
                filter: filters, first: limit,
                offset
            }
        }
        getActivities(filter);
    }, [dayFilter, groupFilter, nameFilter, startTime, endTime, getActivities, limit, offset, period])

    const handleDeleteActivity = useCallback(async (groupActivityUid) => {
        await deleteActivity({
            variables: { groupActivityUid }
        });
        loadData();
    }, [deleteActivity, loadData]);

    useEffect(() => { getGroups(); }, [getGroups])
    useEffect(() => { loadData(); }, [loadData])



    return (
        <Container component="main" maxWidth={false}>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <GroupActivityListToolbar
                        readOnly={readOnly}
                        period={period}
                        loadData={loadData}
                        startTime={startTime}
                        endTime={endTime}
                        daysOptions={daysOptions}
                        groupsOptions={groupsOptions}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                        updateSearch={updateSearch}
                        updateDayFilter={updateDayFilter}
                        updateGroupFilter={updateGroupFilter}
                    />

                    <Box sx={{ pt: 3 }}>
                        <GroupActivityListResults
                            period={period}
                            readOnly={readOnly}
                            loadData={loadData}
                            groupActivities={groupActivities}
                            limit={limit}
                            offset={offset}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            handleDeleteActivity={handleDeleteActivity} />
                    </Box>
                </Container>
            </Box>
        </Container>
    )
};

export default GroupActivityList;