import { Box, Container } from '@mui/material';
import { LIST_CLASSROOM_ACTIVITIES, DELETE_CLASSROOM_ACTIVITY, LIST_CLASSROOMS } from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import ClassroomActivityListResults from './ClassroomActivityListResults';
import ClassroomActivityListToolbar from './ClassroomActivityListToolbar';
import moment from "moment";
import { useSnackbar } from 'notistack';



function ClassroomActivityList({ readOnly, period }) {

    const { enqueueSnackbar } = useSnackbar();


    const daysOptions = [
        { value: "MONDAY", label: "Lundi" },
        { value: "TUESDAY", label: "Mardi" },
        { value: "WEDNESDAY", label: "Mercredi" },
        { value: "THURSDAY", label: "Jeudi" },
        { value: "FRIDAY", label: "Vendredi" }
    ]

    const [activities, setActivities] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);

    let [dayFilter, setDayFilter] = useState(daysOptions.map(i => i.value));
    let [classroomsOptions, setClassroomsOptions] = useState([]);
    let [classroomFilter, setClassroomFilter] = useState([]);
    let [nameFilter, setNameFilter] = useState("");
    let [availableFilter, setAvailableFilter] = useState(false);
    let [startTime, setStartTime] = useState(null);
    let [endTime, setEndTime] = useState(null);



    const [getActivities] = useLazyQuery(LIST_CLASSROOM_ACTIVITIES, {
        onCompleted: (data) => {
            setActivities(data.allClassroomActivities.nodes);
            setTotalCount(data.allClassroomActivities.totalCount);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [deleteActivity] = useMutation(DELETE_CLASSROOM_ACTIVITY, {
        onError: e => enqueueSnackbar("Impossible de supprimer le cours", { variant: "error" }),
        onCompleted: d => enqueueSnackbar("Cours supprimé", { variant: "success" })
    })

    const [getClassrooms] = useLazyQuery(LIST_CLASSROOMS, {
        onCompleted: (data) => {
            setClassroomsOptions(data.allClassrooms.nodes.map(g => { return { value: g.uid, label: g.name } }));
        },
        fetchPolicy: 'cache-and-network'
    });

    const updateDayFilter = (days) => {
        setDayFilter(days.map(i => i.value));
    }

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const loadData = useCallback(() => {
        const dayF = dayFilter.length > 0 ? { day: { in: dayFilter } } : {};
        const classroomF = classroomFilter.length > 0 ? { classroomUid: { in: classroomFilter } } : {};
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const startF = startTime ? { startTime: { greaterThanOrEqualTo: moment(startTime).format("HH:mm:00") } } : {};
        const endF = endTime ? { endTime: { lessThanOrEqualTo: moment(endTime).format("HH:mm:00") } } : {};
        const remainingF = availableFilter ? { remaining: { greaterThan: 0 } } : {};
        const periodF = { periodUid: { equalTo: period.uid } };

        const filters = { ...dayF, ...nameF, ...startF, ...endF, ...remainingF, ...periodF, ...classroomF };
        const filter = {
            variables: {
                filter: filters, first: limit,
                offset
            }
        }
        getActivities(filter);
    }, [dayFilter, nameFilter, availableFilter, startTime, endTime, getActivities, limit, offset, period, classroomFilter]);

    const updateClassroomFilter = (options) => {
        setClassroomFilter(options.map(i => i.value));
    }

    const handleDeleteActivity = useCallback(async (classroomActivityUid) => {
        await deleteActivity({
            variables: { classroomActivityUid }
        });
        loadData();
    }, [deleteActivity, loadData]);

    useEffect(() => { loadData(); }, [loadData]);
    useEffect(() => { getClassrooms(); }, [getClassrooms])



    return (
        <Container component="main" maxWidth={false}>
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <ClassroomActivityListToolbar
                        period={period}
                        readOnly={readOnly}
                        loadData={loadData}
                        startTime={startTime}
                        endTime={endTime}
                        daysOptions={daysOptions}
                        classroomsOptions={classroomsOptions}
                        dayFilter={dayFilter}
                        availableFilter={availableFilter}
                        setStartTime={setStartTime}
                        setEndTime={setEndTime}
                        updateSearch={updateSearch}
                        updateDayFilter={updateDayFilter}
                        updateClassroomFilter={updateClassroomFilter}
                        setAvailableFilter={setAvailableFilter} />
                    <Box sx={{ pt: 3 }}>
                        <ClassroomActivityListResults
                            period={period}
                            readOnly={readOnly}
                            loadData={loadData}
                            activities={activities}
                            limit={limit}
                            offset={offset}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            handleDeleteActivity={handleDeleteActivity} />
                    </Box>
                </Container>
            </Box>
        </Container>
    )
};

export default ClassroomActivityList;