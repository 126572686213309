import { Chip } from '@mui/material';
import { useEffect, useState } from 'react';
import { groupBy } from "../../../utils/reducers"


function ClassroomGradeList({ classrooms, grades, chips = true }) {

    const [classroomByGrade, setClassroomByGrade] = useState({});

    useEffect(() => {
        if (grades && grades.length > 0) {
            setClassroomByGrade(groupBy(classrooms, "gradeUid"));
        }

    }, [grades, classrooms]);



    return Object.keys(classroomByGrade)
        .map(c => {
            const grade = grades.find(i => i.uid === c);
            if (!grade) {
                return "Introuvable...";
            }
            const nbClassrooms = classroomByGrade[c].length;
            const nbClassroomsInGrade = grade.classroomsByGradeUid.nodes.length;
            if (nbClassrooms === nbClassroomsInGrade) {
                return chips ? <Chip key={c} size="small" variant="default" label={grade.name} /> : `${grade.name} `;
            } else {
                return classroomByGrade[c].map(classroom => chips ? <Chip key={classroom.uid} size="small" variant="outlined" label={classroom.name} /> : `${classroom.name} `)
            }
        })
}

export default ClassroomGradeList;