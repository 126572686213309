import React, { Fragment } from 'react';
import { Divider, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import moment from "moment";
import { stateChip } from '../../utils/process'


function ProcessCalendarDetails({ processes, mapping }) {
    return (
        <Fragment>
            <Typography variant="h6">Historique des validations</Typography>
            <Divider style={{ marginBottom: '20px' }} />

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell size="small">Date</TableCell>
                        <TableCell size="small">Etat</TableCell>
                        <TableCell size="small">Commentaire</TableCell>
                        <TableCell size="small">Validateur</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {processes.map(p => {
                        return (
                            <TableRow hover key={p.uid}>
                                <TableCell size="small">{moment(p.createdAt).format("YYYY-MM-DD HH:mm")}</TableCell>
                                <TableCell size="small">{stateChip(p.calendarState, mapping)}</TableCell>
                                <TableCell size="small">{p.comment}</TableCell>
                                <TableCell size="small">{p.userByCreatedBy && p.userByCreatedBy.firstName} {p.userByCreatedBy && p.userByCreatedBy.lastName}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>

            </Table>
        </Fragment >
    );

}
export default ProcessCalendarDetails;