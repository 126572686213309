import { Box, Button, Container, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { GET_CONCURENT_ACTIVITY_FOR_GROUP_ACTIVITY } from "../../graphql/admin-requests";
import { useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import { formatActivityTime } from '../../utils/time';
import GroupActivityConfirmUnregister from './GroupActivityConflicts/GroupActivityConfirmUnregister';

function UserList({ groupActivityUid }) {

    const [conflicts, setConflicts] = useState([]);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [getConflicts] = useMutation(GET_CONCURENT_ACTIVITY_FOR_GROUP_ACTIVITY, {
        onCompleted: (data) => {
            const res = data.getConcurentActivityForGroupActivity.userActivities;
            setConflicts(res);
        }
    });

    const loadData = useCallback(() => {
        const vars = { variables: { groupActivityUid } };
        getConflicts(vars);
    }, [groupActivityUid, getConflicts]);

    const formatActivity = conflict => {
        const act = conflict.activityByActivityUid;
        if (act) {
            return `${act.name} : ${formatActivityTime(act)}`
        } else {
            return `Activité introuvable...`;
        }
    }

    const handleMultipleUnregister = () => {
        setOpenConfirmDialog(true);
    }

    useEffect(() => {
        if (groupActivityUid) {
            loadData();
        }
    }, [loadData, groupActivityUid])

    return (
        <Container component="main" style={{ maxHeight: "90vh", overflowY: "auto" }}>
            {conflicts.length > 0 && <GroupActivityConfirmUnregister
                open={openConfirmDialog}
                conflicts={conflicts}
                onClose={async () => { await setOpenConfirmDialog(false); loadData(); }}
            />}
            <Typography component="h1" variant="h5">Activités en conflit</Typography>
            <Divider style={{ marginTop: '5px' }} />
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
                <Container maxWidth={false}>
                    <Box >
                        {conflicts.length === 0 ?
                            "Pas d'activités en conflit pour ce créneau d'option" :
                            (<div>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size="small">Eleve</TableCell>
                                            <TableCell size="small">Activité en conflit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {conflicts.map(c => (
                                            <TableRow hover key={c.userByUserUid.uid} >
                                                <TableCell>{c.userByUserUid && c.userByUserUid.firstName} {c.userByUserUid && c.userByUserUid.lastName}</TableCell>
                                                <TableCell>{formatActivity(c)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <Button
                                    style={{ float: "right", margin: "10px" }}
                                    variant="outlined"
                                    color="primary"
                                    disabled={false}
                                    onClick={handleMultipleUnregister}>Supprimer les {conflicts.length} inscriptions</Button>
                            </div>)}

                    </Box>
                </Container>
            </Box >
        </Container >
    )
};

export default UserList;