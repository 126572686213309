import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from "@apollo/client";
import { GET_ALL_APPOINTMENT_FOR_GROUP, GET_GROUP, LIST_USER_BY_GROUP } from "../../../graphql/admin-requests";
import CalendarList from './PrintableCalendarList';
import { useParams } from "react-router-dom";
import { userSort } from '../../../utils/sort';


function GroupCalendar({ period }) {

    const { groupUid } = useParams();

    const [appointments, setAppointments] = useState([]);
    const [users, setUsers] = useState([]);
    const [group, setGroup] = useState({});

    const [getGroup, { loading: loadingGroup }] = useLazyQuery(GET_GROUP, {
        onCompleted: data => setGroup(data.groupByUid),
        onError: (error) => console.error("Error retrieving group", error)
    })

    const [getAllApointments, { loading: loadingAppointments }] = useMutation(GET_ALL_APPOINTMENT_FOR_GROUP, {
        onCompleted: data => setAppointments(data.getAllAppointementForGroup.appointments),
        onError: (error) => console.error("Error retrieving activities", error)
    });

    const [getAllUsers] = useLazyQuery(LIST_USER_BY_GROUP, {
        onCompleted: (data) => setUsers(data.allUserGroups.nodes.map(g => g.userByUserUid).sort(userSort)),
        onError: (error) => console.error("Error retrieving users", error)
    });

    useEffect(() => {
        getGroup({ variables: { groupUid } })
    }, [getGroup, groupUid]);

    useEffect(() => {
        async function getData() {
            if (group.uid && period && period.uid) {
                await getAllApointments({ variables: { periodUid: period.uid, groupUid: group.uid } });
                getAllUsers({ variables: { groupUid: group.uid } });
            }
        };
        getData();
    }, [group, getAllApointments, getAllUsers, period]);

    return (
        <CalendarList
            users={users}
            appointments={appointments}
            item={group}
            loadingItem={loadingGroup}
            loadingAppointments={loadingAppointments}
        />
    )
};

export default GroupCalendar;