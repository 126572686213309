import { Box, Container } from '@mui/material';
import { DELETE_GRADE, LIST_GRADES } from "../../../graphql/admin-requests";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState, useEffect, useCallback } from 'react';
import GradeListResults from './GradeListResults';
import GradeListToolbar from './GradeListToolbar';
import { useSnackbar } from 'notistack';


function GradeList() {
    const { enqueueSnackbar } = useSnackbar();



    const [grades, setGrades] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);

    let [nameFilter, setNameFilter] = useState("");


    const [getGrades] = useLazyQuery(LIST_GRADES, {
        onCompleted: (data) => {
            setGrades(data.allGrades.nodes);
            setTotalCount(data.allGrades.totalCount);
        },
        fetchPolicy: 'cache-and-network'
    });

    const [deleteGrade] = useMutation(DELETE_GRADE, {
        onError: e => enqueueSnackbar("Impossible de supprimer le niveau"),
        onCompleted: d => enqueueSnackbar("Niveau supprimé")
    })

    const updateSearch = (event) => {
        setNameFilter(event.target.value)
    }

    const loadData = useCallback(() => {
        const nameF = { name: { likeInsensitive: `%${nameFilter}%` } };
        const filters = { ...nameF };
        const filter = {
            variables: {
                filter: filters, first: limit,
                offset
            }
        }
        getGrades(filter);
    }, [nameFilter, getGrades, limit, offset])

    const handleDeleteGrade = useCallback(async (gradeUid) => {
        await deleteGrade({
            variables: { gradeUid }
        });
        loadData();
    }, [deleteGrade, loadData]);

    useEffect(() => {
        loadData();
    }, [loadData])



    return (
        <Container component="main">
            <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py: 3 }}>
                <Container maxWidth={false}>
                    <GradeListToolbar
                        loadData={loadData}
                        updateSearch={updateSearch}
                    />
                    <Box sx={{ pt: 3 }}>
                        <GradeListResults
                            grades={grades}
                            limit={limit}
                            offset={offset}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                            loadData={loadData}
                            handleDeleteGrade={handleDeleteGrade} />
                    </Box>
                </Container>
            </Box>
        </Container >
    )
};

export default GradeList;